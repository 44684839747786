@font-face {
    font-family: "Monospaced Number";
    src: local("Tahoma");
    unicode-range: U+30-39;
}

@font-face {
    font-family: "Chinese Quote";
    src: local("PingFang SC"), local("SimSun");
    unicode-range: U+2018, U+2019, U+201c, U+201d;
}

@font-face {
    font-family: 'anticon';
    src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot");

    /* IE9*/
    src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff") format("woff"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf") format("truetype"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont") format("svg");
}

.ori-ant-design-container {
    input::-ms-clear,
    input::-ms-reveal {
        display: none;
    }

    *,
    *::before,
    *::after {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    article,
    aside,
    dialog,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
        display: block;
    }

    [tabindex="-1"]:focus {
        outline: none !important;
    }

    hr {
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0;
        margin-bottom: .5em;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
    }

    abbr[title],
    abbr[data-original-title] {
        text-decoration: underline;
        -webkit-text-decoration: underline dotted;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0;
    }

    address {
        margin-bottom: 1em;
        font-style: normal;
        line-height: inherit;
    }

    input[type="text"],
    input[type="password"],
    input[type="number"],
    textarea {
        -webkit-appearance: none;
    }

    ol,
    ul,
    dl {
        margin-top: 0;
        margin-bottom: 1em;
    }

    ol ol,
    ul ul,
    ol ul,
    ul ol {
        margin-bottom: 0;
    }

    dt {
        font-weight: 500;
    }

    dd {
        margin-bottom: .5em;
        margin-left: 0;
    }

    blockquote {
        margin: 0 0 1em;
    }

    dfn {
        font-style: italic;
    }

    b,
    strong {
        font-weight: bolder;
    }

    small {
        font-size: 80%;
    }

    sub,
    sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
    }

    sub {
        bottom: -0.25em;
    }

    sup {
        top: -0.5em;
    }

    a {
        color: #1890ff;
        background-color: transparent;
        text-decoration: none;
        outline: none;
        cursor: pointer;
        -webkit-transition: color .3s;
        transition: color .3s;
        -webkit-text-decoration-skip: objects;
    }

    a:focus {
        text-decoration: underline;
        -webkit-text-decoration-skip: ink;
        text-decoration-skip: ink;
    }

    a:hover {
        color: #40a9ff;
    }

    a:active {
        color: #096dd9;
    }

    a:active,
    a:hover {
        outline: 0;
        text-decoration: none;
    }

    a[disabled] {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;
        pointer-events: none;
    }

    pre,
    code,
    kbd,
    samp {
        font-family: Consolas, Menlo, Courier, monospace;
        font-size: 1em;
    }

    pre {
        margin-top: 0;
        margin-bottom: 1em;
        overflow: auto;
    }

    figure {
        margin: 0 0 1em;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    svg:not(:root) {
        overflow: hidden;
    }

    a,
    area,
    button,
    [role="button"],
    input:not([type=range]),
    label,
    select,
    summary,
    textarea {
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }

    table {
        border-collapse: collapse;
    }

    caption {
        padding-top: .75em;
        padding-bottom: .3em;
        color: rgba(0, 0, 0, 0.45);
        text-align: left;
        caption-side: bottom;
    }

    th {
        text-align: inherit;
    }

    input,
    button,
    select,
    optgroup,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }

    button,
    input {
        overflow: visible;
    }

    button,
    select {
        text-transform: none;
    }

    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
        -webkit-appearance: button;
    }

    button::-moz-focus-inner,
    [type="button"]::-moz-focus-inner,
    [type="reset"]::-moz-focus-inner,
    [type="submit"]::-moz-focus-inner {
        padding: 0;
        border-style: none;
    }

    input[type="radio"],
    input[type="checkbox"] {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
    }

    input[type="date"],
    input[type="time"],
    input[type="datetime-local"],
    input[type="month"] {
        -webkit-appearance: listbox;
    }

    textarea {
        overflow: auto;
        resize: vertical;
    }

    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0;
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-bottom: .5em;
        font-size: 1.5em;
        line-height: inherit;
        color: inherit;
        white-space: normal;
    }

    progress {
        vertical-align: baseline;
    }

    [type="number"]::-webkit-inner-spin-button,
    [type="number"]::-webkit-outer-spin-button {
        height: auto;
    }

    [type="search"] {
        outline-offset: -2px;
        -webkit-appearance: none;
    }

    [type="search"]::-webkit-search-cancel-button,
    [type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }

    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button;
    }

    output {
        display: inline-block;
    }

    summary {
        display: list-item;
    }

    template {
        display: none;
    }

    [hidden] {
        display: none !important;
    }

    mark {
        padding: .2em;
        background-color: #feffe6;
    }

    ::-moz-selection {
        background: #1890ff;
        color: #fff;
    }

    ::selection {
        background: #1890ff;
        color: #fff;
    }

    .clearfix {
        zoom: 1;
    }

    .clearfix:before,
    .clearfix:after {
        content: " ";
        display: table;
    }

    .clearfix:after {
        clear: both;
        visibility: hidden;
        font-size: 0;
        height: 0;
    }

    .anticon {
        display: inline-block;
        font-style: normal;
        // vertical-align: baseline;  // To align the loading icon at the center
        text-align: center;
        text-transform: none;
        line-height: 1;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .anticon:before {
        display: block;
        font-family: "anticon" !important;
    }

    .anticon-step-forward:before {
        content: "\e600";
    }

    .anticon-step-backward:before {
        content: "\e601";
    }

    .anticon-forward:before {
        content: "\e602";
    }

    .anticon-backward:before {
        content: "\e603";
    }

    .anticon-caret-right:before {
        content: "\e604";
    }

    .anticon-caret-left:before {
        content: "\e605";
    }

    .anticon-caret-down:before {
        content: "\e606";
    }

    .anticon-caret-up:before {
        content: "\e607";
    }

    .anticon-right-circle:before {
        content: "\e608";
    }

    .anticon-circle-right:before {
        content: "\e608";
    }

    .anticon-caret-circle-right:before {
        content: "\e608";
    }

    .anticon-left-circle:before {
        content: "\e609";
    }

    .anticon-circle-left:before {
        content: "\e609";
    }

    .anticon-caret-circle-left:before {
        content: "\e609";
    }

    .anticon-up-circle:before {
        content: "\e60a";
    }

    .anticon-circle-up:before {
        content: "\e60a";
    }

    .anticon-caret-circle-up:before {
        content: "\e60a";
    }

    .anticon-down-circle:before {
        content: "\e60b";
    }

    .anticon-circle-down:before {
        content: "\e60b";
    }

    .anticon-caret-circle-down:before {
        content: "\e60b";
    }

    .anticon-right-circle-o:before {
        content: "\e60c";
    }

    .anticon-circle-o-right:before {
        content: "\e60c";
    }

    .anticon-caret-circle-o-right:before {
        content: "\e60c";
    }

    .anticon-left-circle-o:before {
        content: "\e60d";
    }

    .anticon-circle-o-left:before {
        content: "\e60d";
    }

    .anticon-caret-circle-o-left:before {
        content: "\e60d";
    }

    .anticon-up-circle-o:before {
        content: "\e60e";
    }

    .anticon-circle-o-up:before {
        content: "\e60e";
    }

    .anticon-caret-circle-o-up:before {
        content: "\e60e";
    }

    .anticon-down-circle-o:before {
        content: "\e60f";
    }

    .anticon-circle-o-down:before {
        content: "\e60f";
    }

    .anticon-caret-circle-o-down:before {
        content: "\e60f";
    }

    .anticon-verticle-left:before {
        content: "\e610";
    }

    .anticon-verticle-right:before {
        content: "\e611";
    }

    .anticon-rollback:before {
        content: "\e612";
    }

    .anticon-retweet:before {
        content: "\e613";
    }

    .anticon-shrink:before {
        content: "\e614";
    }

    .anticon-arrows-alt:before {
        content: "\e615";
    }

    .anticon-arrow-salt:before {
        content: "\e615";
    }

    .anticon-reload:before {
        content: "\e616";
    }

    .anticon-double-right:before {
        content: "\e617";
    }

    .anticon-double-left:before {
        content: "\e618";
    }

    .anticon-arrow-down:before {
        content: "\e619";
    }

    .anticon-arrow-up:before {
        content: "\e61a";
    }

    .anticon-arrow-right:before {
        content: "\e61b";
    }

    .anticon-arrow-left:before {
        content: "\e61c";
    }

    .anticon-down:before {
        content: "\e61d";
    }

    .anticon-up:before {
        content: "\e61e";
    }

    .anticon-right:before {
        content: "\e61f";
    }

    .anticon-left:before {
        content: "\e620";
    }

    .anticon-minus-square-o:before {
        content: "\e621";
    }

    .anticon-minus-circle:before {
        content: "\e622";
    }

    .anticon-minus-circle-o:before {
        content: "\e623";
    }

    .anticon-minus:before {
        content: "\e624";
    }

    .anticon-plus-circle-o:before {
        content: "\e625";
    }

    .anticon-plus-circle:before {
        content: "\e626";
    }

    .anticon-plus:before {
        content: "\e627";
    }

    .anticon-info-circle:before {
        content: "\e628";
    }

    .anticon-info-circle-o:before {
        content: "\e629";
    }

    .anticon-info:before {
        content: "\e62a";
    }

    .anticon-exclamation:before {
        content: "\e62b";
    }

    .anticon-exclamation-circle:before {
        content: "\e62c";
    }

    .anticon-exclamation-circle-o:before {
        content: "\e62d";
    }

    .anticon-close-circle:before {
        content: "\e62e";
    }

    .anticon-cross-circle:before {
        content: "\e62e";
    }

    .anticon-close-circle-o:before {
        content: "\e62f";
    }

    .anticon-cross-circle-o:before {
        content: "\e62f";
    }

    .anticon-check-circle:before {
        content: "\e630";
    }

    .anticon-check-circle-o:before {
        content: "\e631";
    }

    .anticon-check:before {
        content: "\e632";
    }

    .anticon-close:before {
        content: "\e633";
    }

    .anticon-cross:before {
        content: "\e633";
    }

    .anticon-customer-service:before {
        content: "\e634";
    }

    .anticon-customerservice:before {
        content: "\e634";
    }

    .anticon-credit-card:before {
        content: "\e635";
    }

    .anticon-code-o:before {
        content: "\e636";
    }

    .anticon-book:before {
        content: "\e637";
    }

    .anticon-bars:before {
        content: "\e639";
    }

    .anticon-question:before {
        content: "\e63a";
    }

    .anticon-question-circle:before {
        content: "\e63b";
    }

    .anticon-question-circle-o:before {
        content: "\e63c";
    }

    .anticon-pause:before {
        content: "\e63d";
    }

    .anticon-pause-circle:before {
        content: "\e63e";
    }

    .anticon-pause-circle-o:before {
        content: "\e63f";
    }

    .anticon-clock-circle:before {
        content: "\e640";
    }

    .anticon-clock-circle-o:before {
        content: "\e641";
    }

    .anticon-swap:before {
        content: "\e642";
    }

    .anticon-swap-left:before {
        content: "\e643";
    }

    .anticon-swap-right:before {
        content: "\e644";
    }

    .anticon-plus-square-o:before {
        content: "\e645";
    }

    .anticon-frown:before {
        content: "\e646";
    }

    .anticon-frown-circle:before {
        content: "\e646";
    }

    .anticon-ellipsis:before {
        content: "\e647";
    }

    .anticon-copy:before {
        content: "\e648";
    }

    .anticon-menu-fold:before {
        content: "\e9ac";
    }

    .anticon-mail:before {
        content: "\e659";
    }

    .anticon-logout:before {
        content: "\e65a";
    }

    .anticon-link:before {
        content: "\e65b";
    }

    .anticon-area-chart:before {
        content: "\e65c";
    }

    .anticon-line-chart:before {
        content: "\e65d";
    }

    .anticon-home:before {
        content: "\e65e";
    }

    .anticon-laptop:before {
        content: "\e65f";
    }

    .anticon-star:before {
        content: "\e660";
    }

    .anticon-star-o:before {
        content: "\e661";
    }

    .anticon-folder:before {
        content: "\e662";
    }

    .anticon-filter:before {
        content: "\e663";
    }

    .anticon-file:before {
        content: "\e664";
    }

    .anticon-exception:before {
        content: "\e665";
    }

    .anticon-meh:before {
        content: "\e666";
    }

    .anticon-meh-circle:before {
        content: "\e666";
    }

    .anticon-meh-o:before {
        content: "\e667";
    }

    .anticon-shopping-cart:before {
        content: "\e668";
    }

    .anticon-save:before {
        content: "\e669";
    }

    .anticon-user:before {
        content: "\e66a";
    }

    .anticon-video-camera:before {
        content: "\e66b";
    }

    .anticon-to-top:before {
        content: "\e66c";
    }

    .anticon-team:before {
        content: "\e66d";
    }

    .anticon-tablet:before {
        content: "\e66e";
    }

    .anticon-solution:before {
        content: "\e66f";
    }

    .anticon-search:before {
        content: "\e670";
    }

    .anticon-share-alt:before {
        content: "\e671";
    }

    .anticon-setting:before {
        content: "\e672";
    }

    .anticon-poweroff:before {
        content: "\e6d5";
    }

    .anticon-picture:before {
        content: "\e674";
    }

    .anticon-phone:before {
        content: "\e675";
    }

    .anticon-paper-clip:before {
        content: "\e676";
    }

    .anticon-notification:before {
        content: "\e677";
    }

    .anticon-mobile:before {
        content: "\e678";
    }

    .anticon-menu-unfold:before {
        content: "\e9ad";
    }

    .anticon-inbox:before {
        content: "\e67a";
    }

    .anticon-lock:before {
        content: "\e67b";
    }

    .anticon-qrcode:before {
        content: "\e67c";
    }

    .anticon-play-circle:before {
        content: "\e6d0";
    }

    .anticon-play-circle-o:before {
        content: "\e6d1";
    }

    .anticon-tag:before {
        content: "\e6d2";
    }

    .anticon-tag-o:before {
        content: "\e6d3";
    }

    .anticon-tags:before {
        content: "\e67d";
    }

    .anticon-tags-o:before {
        content: "\e67e";
    }

    .anticon-cloud-o:before {
        content: "\e67f";
    }

    .anticon-cloud:before {
        content: "\e680";
    }

    .anticon-cloud-upload:before {
        content: "\e681";
    }

    .anticon-cloud-download:before {
        content: "\e682";
    }

    .anticon-cloud-download-o:before {
        content: "\e683";
    }

    .anticon-cloud-upload-o:before {
        content: "\e684";
    }

    .anticon-environment:before {
        content: "\e685";
    }

    .anticon-environment-o:before {
        content: "\e686";
    }

    .anticon-eye:before {
        content: "\e687";
    }

    .anticon-eye-o:before {
        content: "\e688";
    }

    .anticon-camera:before {
        content: "\e689";
    }

    .anticon-camera-o:before {
        content: "\e68a";
    }

    .anticon-windows:before {
        content: "\e68b";
    }

    .anticon-apple:before {
        content: "\e68c";
    }

    .anticon-apple-o:before {
        content: "\e6d4";
    }

    .anticon-android:before {
        content: "\e938";
    }

    .anticon-android-o:before {
        content: "\e68d";
    }

    .anticon-aliwangwang:before {
        content: "\e68e";
    }

    .anticon-aliwangwang-o:before {
        content: "\e68f";
    }

    .anticon-export:before {
        content: "\e691";
    }

    .anticon-edit:before {
        content: "\e692";
    }

    .anticon-circle-down-o:before {
        content: "\e693";
    }

    .anticon-circle-down-:before {
        content: "\e694";
    }

    .anticon-appstore-o:before {
        content: "\e695";
    }

    .anticon-appstore:before {
        content: "\e696";
    }

    .anticon-scan:before {
        content: "\e697";
    }

    .anticon-file-text:before {
        content: "\e698";
    }

    .anticon-folder-open:before {
        content: "\e699";
    }

    .anticon-hdd:before {
        content: "\e69a";
    }

    .anticon-ie:before {
        content: "\e69b";
    }

    .anticon-file-jpg:before {
        content: "\e69c";
    }

    .anticon-like:before {
        content: "\e64c";
    }

    .anticon-like-o:before {
        content: "\e69d";
    }

    .anticon-dislike:before {
        content: "\e64b";
    }

    .anticon-dislike-o:before {
        content: "\e69e";
    }

    .anticon-delete:before {
        content: "\e69f";
    }

    .anticon-enter:before {
        content: "\e6a0";
    }

    .anticon-pushpin-o:before {
        content: "\e6a1";
    }

    .anticon-pushpin:before {
        content: "\e6a2";
    }

    .anticon-heart:before {
        content: "\e6a3";
    }

    .anticon-heart-o:before {
        content: "\e6a4";
    }

    .anticon-pay-circle:before {
        content: "\e6a5";
    }

    .anticon-pay-circle-o:before {
        content: "\e6a6";
    }

    .anticon-smile:before {
        content: "\e6a7";
    }

    .anticon-smile-circle:before {
        content: "\e6a7";
    }

    .anticon-smile-o:before {
        content: "\e6a8";
    }

    .anticon-frown-o:before {
        content: "\e6a9";
    }

    .anticon-calculator:before {
        content: "\e6aa";
    }

    .anticon-message:before {
        content: "\e6ab";
    }

    .anticon-chrome:before {
        content: "\e6ac";
    }

    .anticon-github:before {
        content: "\e6ad";
    }

    .anticon-file-unknown:before {
        content: "\e6af";
    }

    .anticon-file-excel:before {
        content: "\e6b0";
    }

    .anticon-file-ppt:before {
        content: "\e6b1";
    }

    .anticon-file-word:before {
        content: "\e6b2";
    }

    .anticon-file-pdf:before {
        content: "\e6b3";
    }

    .anticon-desktop:before {
        content: "\e6b4";
    }

    .anticon-upload:before {
        content: "\e6b6";
    }

    .anticon-download:before {
        content: "\e6b7";
    }

    .anticon-pie-chart:before {
        content: "\e6b8";
    }

    .anticon-unlock:before {
        content: "\e6ba";
    }

    .anticon-calendar:before {
        content: "\e6bb";
    }

    .anticon-windows-o:before {
        content: "\e6bc";
    }

    .anticon-dot-chart:before {
        content: "\e6bd";
    }

    .anticon-bar-chart:before {
        content: "\e6be";
    }

    .anticon-code:before {
        content: "\e6bf";
    }

    .anticon-api:before {
        content: "\e951";
    }

    .anticon-plus-square:before {
        content: "\e6c0";
    }

    .anticon-minus-square:before {
        content: "\e6c1";
    }

    .anticon-close-square:before {
        content: "\e6c2";
    }

    .anticon-close-square-o:before {
        content: "\e6c3";
    }

    .anticon-check-square:before {
        content: "\e6c4";
    }

    .anticon-check-square-o:before {
        content: "\e6c5";
    }

    .anticon-fast-backward:before {
        content: "\e6c6";
    }

    .anticon-fast-forward:before {
        content: "\e6c7";
    }

    .anticon-up-square:before {
        content: "\e6c8";
    }

    .anticon-down-square:before {
        content: "\e6c9";
    }

    .anticon-left-square:before {
        content: "\e6ca";
    }

    .anticon-right-square:before {
        content: "\e6cb";
    }

    .anticon-right-square-o:before {
        content: "\e6cc";
    }

    .anticon-left-square-o:before {
        content: "\e6cd";
    }

    .anticon-down-square-o:before {
        content: "\e6ce";
    }

    .anticon-up-square-o:before {
        content: "\e6cf";
    }

    .anticon-loading:before {
        content: "\e64d";
    }

    .anticon-loading-3-quarters:before {
        content: "\e6ae";
    }

    .anticon-bulb:before {
        content: "\e649";
    }

    .anticon-select:before {
        content: "\e64a";
    }

    .anticon-addfile:before,
    .anticon-file-add:before {
        content: "\e910";
    }

    .anticon-addfolder:before,
    .anticon-folder-add:before {
        content: "\e914";
    }

    .anticon-switcher:before {
        content: "\e913";
    }

    .anticon-rocket:before {
        content: "\e90f";
    }

    .anticon-dingding:before {
        content: "\e923";
    }

    .anticon-dingding-o:before {
        content: "\e925";
    }

    .anticon-bell:before {
        content: "\e64e";
    }

    .anticon-disconnect:before {
        content: "\e64f";
    }

    .anticon-database:before {
        content: "\e650";
    }

    .anticon-compass:before {
        content: "\e6db";
    }

    .anticon-barcode:before {
        content: "\e652";
    }

    .anticon-hourglass:before {
        content: "\e653";
    }

    .anticon-key:before {
        content: "\e654";
    }

    .anticon-flag:before {
        content: "\e655";
    }

    .anticon-layout:before {
        content: "\e656";
    }

    .anticon-login:before {
        content: "\e657";
    }

    .anticon-printer:before {
        content: "\e673";
    }

    .anticon-sound:before {
        content: "\e6e9";
    }

    .anticon-usb:before {
        content: "\e6d7";
    }

    .anticon-skin:before {
        content: "\e6d8";
    }

    .anticon-tool:before {
        content: "\e6d9";
    }

    .anticon-sync:before {
        content: "\e6da";
    }

    .anticon-wifi:before {
        content: "\e6d6";
    }

    .anticon-car:before {
        content: "\e6dc";
    }

    .anticon-copyright:before {
        content: "\e6de";
    }

    .anticon-schedule:before {
        content: "\e6df";
    }

    .anticon-user-add:before {
        content: "\e6ed";
    }

    .anticon-user-delete:before {
        content: "\e6e0";
    }

    .anticon-usergroup-add:before {
        content: "\e6dd";
    }

    .anticon-usergroup-delete:before {
        content: "\e6e1";
    }

    .anticon-man:before {
        content: "\e6e2";
    }

    .anticon-woman:before {
        content: "\e6ec";
    }

    .anticon-shop:before {
        content: "\e6e3";
    }

    .anticon-gift:before {
        content: "\e6e4";
    }

    .anticon-idcard:before {
        content: "\e6e5";
    }

    .anticon-medicine-box:before {
        content: "\e6e6";
    }

    .anticon-red-envelope:before {
        content: "\e6e7";
    }

    .anticon-coffee:before {
        content: "\e6e8";
    }

    .anticon-trademark:before {
        content: "\e651";
    }

    .anticon-safety:before {
        content: "\e6ea";
    }

    .anticon-wallet:before {
        content: "\e6eb";
    }

    .anticon-bank:before {
        content: "\e6ee";
    }

    .anticon-trophy:before {
        content: "\e6ef";
    }

    .anticon-contacts:before {
        content: "\e6f0";
    }

    .anticon-global:before {
        content: "\e6f1";
    }

    .anticon-shake:before {
        content: "\e94f";
    }

    .anticon-fork:before {
        content: "\e6f2";
    }

    .anticon-dashboard:before {
        content: "\e99a";
    }

    .anticon-profile:before {
        content: "\e999";
    }

    .anticon-table:before {
        content: "\e998";
    }

    .anticon-warning:before {
        content: "\e997";
    }

    .anticon-form:before {
        content: "\e996";
    }

    .anticon-spin:before {
        display: inline-block;
        -webkit-animation: loadingCircle 1s infinite linear;
        animation: loadingCircle 1s infinite linear;
    }

    .anticon-weibo-square:before {
        content: "\e6f5";
    }

    .anticon-weibo-circle:before {
        content: "\e6f4";
    }

    .anticon-taobao-circle:before {
        content: "\e6f3";
    }

    .anticon-html5:before {
        content: "\e9c7";
    }

    .anticon-weibo:before {
        content: "\e9c6";
    }

    .anticon-twitter:before {
        content: "\e9c5";
    }

    .anticon-wechat:before {
        content: "\e9c4";
    }

    .anticon-youtube:before {
        content: "\e9c3";
    }

    .anticon-alipay-circle:before {
        content: "\e9c2";
    }

    .anticon-taobao:before {
        content: "\e9c1";
    }

    .anticon-skype:before {
        content: "\e9c0";
    }

    .anticon-qq:before {
        content: "\e9bf";
    }

    .anticon-medium-workmark:before {
        content: "\e9be";
    }

    .anticon-gitlab:before {
        content: "\e9bd";
    }

    .anticon-medium:before {
        content: "\e9bc";
    }

    .anticon-linkedin:before {
        content: "\e9bb";
    }

    .anticon-google-plus:before {
        content: "\e9ba";
    }

    .anticon-dropbox:before {
        content: "\e9b9";
    }

    .anticon-facebook:before {
        content: "\e9b8";
    }

    .anticon-codepen:before {
        content: "\e9b7";
    }

    .anticon-amazon:before {
        content: "\e9b6";
    }

    .anticon-google:before {
        content: "\e9b5";
    }

    .anticon-codepen-circle:before {
        content: "\e9b4";
    }

    .anticon-alipay:before {
        content: "\e9b3";
    }

    .anticon-ant-design:before {
        content: "\e9b2";
    }

    .anticon-aliyun:before {
        content: "\e9f4";
    }

    .anticon-zhihu:before {
        content: "\e703";
    }

    .anticon-file-markdown:before {
        content: "\e704";
    }

    .anticon-slack:before {
        content: "\e705";
    }

    .anticon-slack-square:before {
        content: "\e706";
    }

    .anticon-behance:before {
        content: "\e707";
    }

    .anticon-behance-square:before {
        content: "\e708";
    }

    .anticon-dribbble:before {
        content: "\e709";
    }

    .anticon-dribbble-square:before {
        content: "\e70a";
    }

    .anticon-instagram:before {
        content: "\e70b";
    }

    .anticon-yuque:before {
        content: "\e70c";
    }

    .fade-enter,
    .fade-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .fade-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .fade-enter.fade-enter-active,
    .fade-appear.fade-appear-active {
        -webkit-animation-name: antFadeIn;
        animation-name: antFadeIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .fade-leave.fade-leave-active {
        -webkit-animation-name: antFadeOut;
        animation-name: antFadeOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .fade-enter,
    .fade-appear {
        opacity: 0;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }

    .fade-leave {
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    }

    @-webkit-keyframes antFadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes antFadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes antFadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes antFadeOut {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    .move-up-enter,
    .move-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-up-enter.move-up-enter-active,
    .move-up-appear.move-up-appear-active {
        -webkit-animation-name: antMoveUpIn;
        animation-name: antMoveUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .move-up-leave.move-up-leave-active {
        -webkit-animation-name: antMoveUpOut;
        animation-name: antMoveUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .move-up-enter,
    .move-up-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .move-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }

    .move-down-enter,
    .move-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-down-enter.move-down-enter-active,
    .move-down-appear.move-down-appear-active {
        -webkit-animation-name: antMoveDownIn;
        animation-name: antMoveDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .move-down-leave.move-down-leave-active {
        -webkit-animation-name: antMoveDownOut;
        animation-name: antMoveDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .move-down-enter,
    .move-down-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .move-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }

    .move-left-enter,
    .move-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-left-enter.move-left-enter-active,
    .move-left-appear.move-left-appear-active {
        -webkit-animation-name: antMoveLeftIn;
        animation-name: antMoveLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .move-left-leave.move-left-leave-active {
        -webkit-animation-name: antMoveLeftOut;
        animation-name: antMoveLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .move-left-enter,
    .move-left-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .move-left-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }

    .move-right-enter,
    .move-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .move-right-enter.move-right-enter-active,
    .move-right-appear.move-right-appear-active {
        -webkit-animation-name: antMoveRightIn;
        animation-name: antMoveRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .move-right-leave.move-right-leave-active {
        -webkit-animation-name: antMoveRightOut;
        animation-name: antMoveRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .move-right-enter,
    .move-right-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .move-right-leave {
        -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
        animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    }

    @-webkit-keyframes antMoveDownIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @keyframes antMoveDownIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @-webkit-keyframes antMoveDownOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            opacity: 0;
        }
    }

    @keyframes antMoveDownOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(100%);
            transform: translateY(100%);
            opacity: 0;
        }
    }

    @-webkit-keyframes antMoveLeftIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }
    }

    @keyframes antMoveLeftIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }
    }

    @-webkit-keyframes antMoveLeftOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @keyframes antMoveLeftOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
            opacity: 0;
        }
    }

    @-webkit-keyframes antMoveRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @keyframes antMoveRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    @-webkit-keyframes antMoveRightOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
        }
    }

    @keyframes antMoveRightOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            opacity: 0;
        }
    }

    @-webkit-keyframes antMoveUpIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @keyframes antMoveUpIn {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            opacity: 0;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }
    }

    @-webkit-keyframes antMoveUpOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    @keyframes antMoveUpOut {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            opacity: 1;
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
            opacity: 0;
        }
    }

    @-webkit-keyframes loadingCircle {
        0% {
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes loadingCircle {
        0% {
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .slide-up-enter,
    .slide-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-up-enter.slide-up-enter-active,
    .slide-up-appear.slide-up-appear-active {
        -webkit-animation-name: antSlideUpIn;
        animation-name: antSlideUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .slide-up-leave.slide-up-leave-active {
        -webkit-animation-name: antSlideUpOut;
        animation-name: antSlideUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .slide-up-enter,
    .slide-up-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .slide-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    .slide-down-enter,
    .slide-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-down-enter.slide-down-enter-active,
    .slide-down-appear.slide-down-appear-active {
        -webkit-animation-name: antSlideDownIn;
        animation-name: antSlideDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .slide-down-leave.slide-down-leave-active {
        -webkit-animation-name: antSlideDownOut;
        animation-name: antSlideDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .slide-down-enter,
    .slide-down-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .slide-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    .slide-left-enter,
    .slide-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-left-enter.slide-left-enter-active,
    .slide-left-appear.slide-left-appear-active {
        -webkit-animation-name: antSlideLeftIn;
        animation-name: antSlideLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .slide-left-leave.slide-left-leave-active {
        -webkit-animation-name: antSlideLeftOut;
        animation-name: antSlideLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .slide-left-enter,
    .slide-left-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .slide-left-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    .slide-right-enter,
    .slide-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .slide-right-enter.slide-right-enter-active,
    .slide-right-appear.slide-right-appear-active {
        -webkit-animation-name: antSlideRightIn;
        animation-name: antSlideRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .slide-right-leave.slide-right-leave-active {
        -webkit-animation-name: antSlideRightOut;
        animation-name: antSlideRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .slide-right-enter,
    .slide-right-appear {
        opacity: 0;
        -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
        animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .slide-right-leave {
        -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }

    @-webkit-keyframes antSlideUpIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }

    @keyframes antSlideUpIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }

    @-webkit-keyframes antSlideUpOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }
    }

    @keyframes antSlideUpOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }
    }

    @-webkit-keyframes antSlideDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }

    @keyframes antSlideDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }

    @-webkit-keyframes antSlideDownOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }
    }

    @keyframes antSlideDownOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
            -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
        }
    }

    @-webkit-keyframes antSlideLeftIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @keyframes antSlideLeftIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @-webkit-keyframes antSlideLeftOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }
    }

    @keyframes antSlideLeftOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }
    }

    @-webkit-keyframes antSlideRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @keyframes antSlideRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }

        100% {
            opacity: 1;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }

    @-webkit-keyframes antSlideRightOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }
    }

    @keyframes antSlideRightOut {
        0% {
            opacity: 1;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
            -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
        }
    }

    .swing-enter,
    .swing-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .swing-enter.swing-enter-active,
    .swing-appear.swing-appear-active {
        -webkit-animation-name: antSwingIn;
        animation-name: antSwingIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    @-webkit-keyframes antSwingIn {
        0%,
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        20% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
        }

        40% {
            -webkit-transform: translateX(10px);
            transform: translateX(10px);
        }

        60% {
            -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
        }

        80% {
            -webkit-transform: translateX(5px);
            transform: translateX(5px);
        }
    }

    @keyframes antSwingIn {
        0%,
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        20% {
            -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
        }

        40% {
            -webkit-transform: translateX(10px);
            transform: translateX(10px);
        }

        60% {
            -webkit-transform: translateX(-5px);
            transform: translateX(-5px);
        }

        80% {
            -webkit-transform: translateX(5px);
            transform: translateX(5px);
        }
    }

    .zoom-enter,
    .zoom-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-enter.zoom-enter-active,
    .zoom-appear.zoom-appear-active {
        -webkit-animation-name: antZoomIn;
        animation-name: antZoomIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-leave.zoom-leave-active {
        -webkit-animation-name: antZoomOut;
        animation-name: antZoomOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-enter,
    .zoom-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-big-enter,
    .zoom-big-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-big-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-big-enter.zoom-big-enter-active,
    .zoom-big-appear.zoom-big-appear-active {
        -webkit-animation-name: antZoomBigIn;
        animation-name: antZoomBigIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-big-leave.zoom-big-leave-active {
        -webkit-animation-name: antZoomBigOut;
        animation-name: antZoomBigOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-big-enter,
    .zoom-big-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-big-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-big-fast-enter,
    .zoom-big-fast-appear {
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-big-fast-leave {
        -webkit-animation-duration: 0.1s;
        animation-duration: 0.1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-big-fast-enter.zoom-big-fast-enter-active,
    .zoom-big-fast-appear.zoom-big-fast-appear-active {
        -webkit-animation-name: antZoomBigIn;
        animation-name: antZoomBigIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-big-fast-leave.zoom-big-fast-leave-active {
        -webkit-animation-name: antZoomBigOut;
        animation-name: antZoomBigOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-big-fast-enter,
    .zoom-big-fast-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-big-fast-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-up-enter,
    .zoom-up-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-up-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-up-enter.zoom-up-enter-active,
    .zoom-up-appear.zoom-up-appear-active {
        -webkit-animation-name: antZoomUpIn;
        animation-name: antZoomUpIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-up-leave.zoom-up-leave-active {
        -webkit-animation-name: antZoomUpOut;
        animation-name: antZoomUpOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-up-enter,
    .zoom-up-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-up-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-down-enter,
    .zoom-down-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-down-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-down-enter.zoom-down-enter-active,
    .zoom-down-appear.zoom-down-appear-active {
        -webkit-animation-name: antZoomDownIn;
        animation-name: antZoomDownIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-down-leave.zoom-down-leave-active {
        -webkit-animation-name: antZoomDownOut;
        animation-name: antZoomDownOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-down-enter,
    .zoom-down-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-down-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-left-enter,
    .zoom-left-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-left-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-left-enter.zoom-left-enter-active,
    .zoom-left-appear.zoom-left-appear-active {
        -webkit-animation-name: antZoomLeftIn;
        animation-name: antZoomLeftIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-left-leave.zoom-left-leave-active {
        -webkit-animation-name: antZoomLeftOut;
        animation-name: antZoomLeftOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-left-enter,
    .zoom-left-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-left-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    .zoom-right-enter,
    .zoom-right-appear {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-right-leave {
        -webkit-animation-duration: 0.2s;
        animation-duration: 0.2s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-play-state: paused;
        animation-play-state: paused;
    }

    .zoom-right-enter.zoom-right-enter-active,
    .zoom-right-appear.zoom-right-appear-active {
        -webkit-animation-name: antZoomRightIn;
        animation-name: antZoomRightIn;
        -webkit-animation-play-state: running;
        animation-play-state: running;
    }

    .zoom-right-leave.zoom-right-leave-active {
        -webkit-animation-name: antZoomRightOut;
        animation-name: antZoomRightOut;
        -webkit-animation-play-state: running;
        animation-play-state: running;
        pointer-events: none;
    }

    .zoom-right-enter,
    .zoom-right-appear {
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
        animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    }

    .zoom-right-leave {
        -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
        animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }

    @-webkit-keyframes antZoomIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0.2);
            transform: scale(0.2);
        }

        100% {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0.2);
            transform: scale(0.2);
        }

        100% {
            opacity: 1;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomOut {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.2);
            transform: scale(0.2);
        }
    }

    @keyframes antZoomOut {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.2);
            transform: scale(0.2);
        }
    }

    @-webkit-keyframes antZoomBigIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomBigIn {
        0% {
            opacity: 0;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomBigOut {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @keyframes antZoomBigOut {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @-webkit-keyframes antZoomUpIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomUpIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomUpOut {
        0% {
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @keyframes antZoomUpOut {
        0% {
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @-webkit-keyframes antZoomLeftIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomLeftIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomLeftOut {
        0% {
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @keyframes antZoomLeftOut {
        0% {
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @-webkit-keyframes antZoomRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomRightIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomRightOut {
        0% {
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @keyframes antZoomRightOut {
        0% {
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @-webkit-keyframes antZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @keyframes antZoomDownIn {
        0% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }

        100% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }

    @-webkit-keyframes antZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    @keyframes antZoomDownOut {
        0% {
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(1);
            transform: scale(1);
        }

        100% {
            opacity: 0;
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
        }
    }

    .ant-motion-collapse {
        overflow: hidden;
    }

    .ant-motion-collapse-active {
        -webkit-transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }
}
