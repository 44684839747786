@import "../data/styles/colors.scss";

.oriAppContainer {
  z-index: 99990;
  position: fixed;
  right: 0;
  bottom: 0;

  .chatbotContainer {
    color: $chatbot-font-color !important;
  }

  .notificationBadge {
    top: -18px;
    right: 24px;

    .ant-badge-count {
      z-index: 99993;
      background-color: $green;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11);
    }
  }
}

.scroll-visibility {
  --scroll-display: none;
  ::-webkit-scrollbar {
    opacity: 0;
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    opacity: 0;
  }

  ::-webkit-scrollbar-thumb {
    display: var(--scroll-display);
    background-color: $grey;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }
}

.prevent-inheritance > * {
  --scroll-display: none;
}
