@import "./antdesign_index.scss";
@import "~antd/lib/select/style/index";
@import "~antd/lib/date-picker/style/index"; // specific for FormMessage @oriserve-chatbot/@oriserve-chatbot/message-types (require for datePicker)
// @import "~antd/lib/tooltip/style/index"; // specifically used in SeatMap messagae-types now (require for tooltip)

.ori-ant-design-container {
  @import "~antd/lib/button/style/index";
  @import "~antd/lib/badge/style/index";
  // @import "~antd/lib/form/style/index";
  @import "~antd/lib/input/style/index";
  @import "~antd/lib/carousel/style/index";
  @import "~antd/lib/upload/style/index";
  @import "~antd/lib/avatar/style/index";

  @import "~antd/lib/menu/style/index";
  @import "~antd/lib/message/style/index";

  @import "~antd/lib/radio/style/index";
  @import "~antd/lib/rate/style/index"; // specific for FormMessage @oriserve-chatbot/message-types
  @import "~antd/lib/tag/style/index";
  @import "~antd/lib/spin/style/index";
  
  /*
    * import ant-design component css here, when ever you use any ant design component in chatbot or chatbot-@oriserve-chatbot/message-types library.
    * also check antd folder in node_modules. if required component is dependent on other component then add other component css
    * eq: i have used table in chatbot-@oriserve-chatbot/message-types so i have used table component css. but table component is using pagination component and tooltip component so i have also added css for both pagination and tooltip even i am not using pagination any where seperately.
    */

  // ---------------------------- START -----------------------------
  /*
      below class is added to fix double icons in showMessage function. (when we are using antd@3.13.1 and message is using getContainer config. we are getting double icon in message popup)

      message.config({
        duration: 2,
        maxCount: 1,
        top: 75,
        getContainer: () => node
      });
      message[type](msg);

      To fix this issue we need to either update antd or override css.
      now i am overriding css because if i will update antd, i need to update @oriserve-chatbot/message-types antd peer dependency. also i need to test pagination in Checkbox type message (checkbox was not working properly for lower version of antd)

      TODO: remove the below css whenever you will update the antd version
    */

  .anticon-info-circle:before,
  .anticon-check-circle:before,
  .anticon-exclamation-circle:before,
  .anticon-close-circle:before,
  .anticon-right:before,
  .anticon-left:before,
  .anticon-calendar:before,
  .anticon-down:before,
  .anticon-loading:before,
  .anticon-close:before,
  .anticon-star:before,
  .anticon-search:before,
  .anticon-check:before,
  .anticon-swap-right:before {
    content: "" !important;
  }

  // ---------------------------- END -----------------------------
}
