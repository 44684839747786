@import "./colors.scss";
@import "./variables.scss";
@import "./mixins.scss";

.ori-ant-design-container ::selection {
  background: $primary !important;
}

.ant-message {
  position: absolute !important;
  z-index: 999999 !important;
}

.ant-select-item {
  min-height: auto !important;
}

// alert antd classes

.ant-alert {
  padding: 8px 12px;
  line-height: 1.5;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.ant-alert-info {
  background-color: #e6f4ff;
}

.ant-alert-error {
  background-color: #fff2f0;
}

.ant-alert-warning {
  background-color: #fffbe6;
}

.ant-alert-success {
  background-color: #d9e3d5;
}

.ant-alert-icon {
  margin-inline-end: 8px;
  line-height: 0;
  color: $warning;
}

.ant-alert-success .ant-alert-icon {
  color: $success;
}

.ant-alert-error .ant-alert-icon {
  color: $danger;
}

.ant-alert-info .ant-alert-icon {
  color: $info;
}

.ant-alert-content {
  width: 100%;
}

//=================================== FORM MESSAGE TYPES CSS ====================
/*
-- if you are not using FormMessage component then you should below css
-- we are using radio group and datePicker in FormMessage Component
*/

// ant-picker-dropdown is required if you are using date-picker

.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 99994 !important;
  font-family: $font-family !important;
}

// .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
//   visibility: visible !important;
// }

// .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
//   visibility: visible !important;
// }

// .ant-picker-panels > *:not(:first-child) {
//   display: none !important;
// }

// .ant-picker-panel-container {
//   width: 300px !important;
//   overflow-x: auto !important;
// }

// .ant-picker-time-panel {
//   width: 100px !important;
// }

// .ori-ant-design-container ul {
//   margin-bottom: 0px;
// }

// below css are required if you are using radio group

// .ant-radio:hover .ant-radio-inner {
// border-color: $primary !important;
// }

// .ant-radio-checked .ant-radio-inner {
//   border-color: $primary !important;

//   &::after {
//       background-color: $primary !important;
//   }

//   &:hover {
//       border-color: $primary !important;
//   }
// }

// .ant-radio-button-wrapper {
//   &:hover {
//       color: $primary !important;
//   }
// }

// .ant-radio-button-wrapper-checked {
//   background-color: $primary !important;
//   border-color: $primary !important;

//   &::before {
//       background-color: $primary !important;
//   }

//   &:hover {
//       color: $white !important;
//   }
// }

//=================================== FORM MESSAGE TYPES END ====================

//------------------------ TOOLTIP CLASSES -------------------------

.ant-tooltip {
  z-index: 99999;
  font-family: $font-family;
}

//------------------------ TAG CLASSES ----------------------------

.ori-tag-primary {
  color: $primary !important;
  background-color: $primary-bg-light !important;
  border: 1px solid transparent !important;
  cursor: default !important;
  height: auto !important;
  line-height: unset !important;
}

//------------------------ BUTTON CLASSES -------------------------
// use below class to add common margin. do not add margin directly into group class bz margin will be added in other classes also
.ori-btn-fill-primary,
.ori-btn-bubble-inner,
.ori-btn-ghost-primary,
.ori-btn-edit,
.ori-btn-submit {
  margin: 2px !important;
}

.ori-btn-bubble-outer,
.ori-btn-submit,
.ori-btn-clear {
  color: $white !important;
  background-color: $primary !important;
  border-color: $primary !important;

  &:hover,
  &:focus,
  &:active {
    background-color: tint($primary, 30%) !important;
    border-color: tint($primary, 30%) !important;
  }
}

.ori-btn-bubble-inner {
  text-align: left !important;
}

.ori-btn-fill-primary,
.ori-btn-bubble-inner,
.ori-btn-quick-reply-icon,
.ori-image-preview-btn {
  color: $btn-fill-font !important;
  background-color: $btn-fill !important;
  border-color: $btn-fill !important;
  border-radius: 15px !important;
  padding: 3px 12px 3px 12px !important;
  height: auto !important;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: $btn-fill-hover !important;
    border-color: $btn-fill-hover !important;
  }
}

.ori-image-preview-icon {
  color: $black !important;
  background-color: $default !important;
  border-color: $default !important;

  &:hover, &:focus, &:active, &:disabled {
      background-color: $font-color-light !important;
      border-color: $font-color-light !important;
  }
}

.ori-btn-ghost-primary,
.ori-btn-quick-reply {
  color: $btn-ghost !important;
  border: 1px solid $btn-ghost !important;
  height: auto !important;
  line-height: unset !important;
  border-radius: 15px !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 0 6px 0 $btn-ghost;
  }
  &:disabled{
    border-color: $btn-fill-hover !important;
    color: $primary-light !important;
  }
}

.ori-btn-carousel-item {
  &:hover,
  &:focus,
  &:active {
    color: $btn-ghost !important;
    border: 1px solid $btn-ghost !important;
  }
}

.ori-btn-carousel-item,
.ori-btn-carousel-item-selected,
.ori-carousel-btn-submit {
  width: 100%;
  margin: 5px 0px !important;
}

.ori-btn-paragraph {
  margin-bottom: 5px !important;
  font-size: 13px !important;
  text-align: left !important;

  &:hover,
  &:focus,
  &:active {
    color: $font-chat !important;
    border-color: $border !important;
    background-color: $default !important;
  }
}

.ori-btn-secondary,
.ori-btn-carousel-item-selected,
.ori-carousel-btn-submit {
  color: $white !important;
  background-color: $green !important;
  border-color: $green !important;
  border-radius: 15px !important;
  padding: 3px 12px 3px 12px !important;
  height: auto !important;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    background-color: $green-light !important;
    border-color: $green-light !important;
  }
}

//------------------------------- MESSAGE-TYPES -----------------------------

// .ar .ori-mt-CarouselContainer {
//   .slick-next {
//     width: 30px;
//     &::before {
//       font-size: 12px;
//       color: $font-color !important;
//       content: "التالي" !important;
//     }
//   }

//   .slick-prev {
//     width: 30px;
//     &::before {
//       font-size: 12px;
//       color: $font-color !important;
//       content: "السابق" !important;
//     }
//   }
// }

// .en
.ori-mt-CarouselContainer {
  .slick-next {
    // width: 30px;
    &::before {
      color: $font-color !important;
      font-size: 14px;
      content: ">>" !important;
    }
  }

  .slick-prev {
    // width: 30px;
    &::before {
      color: $font-color !important;
      font-size: 14px;
      content: "<<" !important;
    }
  }
}

.ori-mt-CarouselContainer {
  .slick-slide {
    margin: 0 !important; // voda specific
  }

  .slick-arrow {
    background: none !important;
    font-size: 12px !important;
    color: $font-color !important;
    // width: auto !important;
    top: inherit !important;
    bottom: -28px !important;
    z-index: 1 !important;
  }

  .slick-next {
    right: 10px !important;
    color: transparent !important;
    background-color: transparent !important;
    outline: none;

    // &:hover,
    // &:focus {
    //   color: $primary !important;
    // }

    &::before {
      content: ">" !important;
      color: $font-color !important;
      font-size: 16px;
      // font-weight: $font-bold;
      background: none !important; // voda specific
      // height: auto !important; // voda specific
    }
  }

  .slick-prev {
    left: 10px !important;
    color: transparent !important;
    background-color: transparent !important;
    outline: none;

    // &:hover,
    // &:focus {
    //   color: $primary !important;
    // }

    &::before {
      content: "<" !important;
      color: $font-color !important;
      font-size: 16px;
      // font-weight: $font-bold;
      background: none !important; // voda specific
      // height: auto !important; // voda specific
    }
  }

  .slick-dots {
    bottom: -20px !important;
    overflow-x: hidden;

    li,
    .slick-active {
      button {
        background-color: $font-color !important;
      }

      &::before {
        content: none !important; // voda specific
      }
    }
  }

  // .carouselItemSelected {
  //   background-color: $primary-bg-light;
  // }

  .ori-carousel-img-container {
    max-height: 250px;
    overflow-y: auto;
  }
}

.ori-mt-fileUploaderContainer {
  .ant-upload {
    width: 100%;
  }
}

.ori-seat-selected {
  background-color: $green;
}

.ori-seat-default {
  background-color: $font-color-light;
}


.textAreaField {
  &::placeholder {
   text-transform: none !important;
   font-size: $font-size-md !important;
 }
}
