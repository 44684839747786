@import "../styles/colors.scss";

//============================== ORI RTL DIRECTION CLASSES ==============================

.oriAppContainerRTL {
  .headerContainer {
    padding: 15px 15px 15px 45px !important;
  }

  .oriHeaderTagContainer {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
  }

  .oriHeaderActionContainer {
    top: 22px;
    left: 10px;
    right: unset !important;
  }

  .senderBubble,
  .notificationSenderBubble {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 10px !important;
  }

  .receiverBubble,
  .notificationReceiverBubble {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 10px !important;
  }

  .receiverAvatar {
    top: 5px !important;
    right: -40px !important;
    left: unset;
  }

  .senderAvatar {
    top: 5px !important;
    left: -40px !important;
    right: unset;
  }

  .footerContainer {
    padding-left: 65px !important;
  }

  .menuVisible {
    padding-right: 30px;
  }

  .menuHidden {
    padding-right: 10px;
  }

  .oriMenuContainer {
    right: 52px;
    left: unset;
  }

  .chatbotInputComposer {
    .alignRightIcons {
      left: -60px;
      right: unset;

      .sendIcon {
        transform: rotate(180deg);
      }
    }

    .alignMenuIcon {
      right: -24px !important;
      left: unset;
    }
  }

  .notificationBotInputComposer {
    padding: 12px 25px 11px 35px !important;

    .alignRightIcons {
      right: unset;
      left: 5px;
    }
  }

  .oriInfoContentCloseIcon {
    left: 7px;
    right: unset;
  }
}

//========================================== END OF RTL CLASSES ==============================================
//========================================== START OF REQUIRED CLASSES ==========================================
.oriFullScreenAppContainer {
  top: 0;
  left: 0;
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .containerMobile {
    width: 100%;
    height: 100%;
  }

  .containerWeb {
    height: calc(100% - 100px);
    width: 600px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin: auto;
  }
}

.oriAppContainer {
  /*
  * comment containerMobile and containerWeb in case FULLSCREEN chatbot
  */

  .containerMobile {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .containerWeb {
    right: 20px;
    bottom: 110px;
    height: calc(100% - 130px);
    max-height: 600px;
    width: 375px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
    border-radius: 25px;
  }

  .stackViewCloseIcon {
    display: flex;
    cursor: pointer;
    color: $white;
    background-color: #8a8989;
    top: 22px;
    right: 10px;
    border-radius: 50%;
    padding: 2px;

    &:hover {
      background-color: $black-light;
    }
  }

  .closeTrigger {
    color: $white;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.24);
    right: 20px;
    bottom: 20px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: $primary;
  }

  .defaultTrigger {
    right: 20px;
    bottom: 20px;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    height: 70px;
  }

  .lottieTrigger {
    right: 20px;
    bottom: 20px;
    height: 55px;
    width: 91px;
  }

  .floatingTrigger {
    right: 20px;
    bottom: 20px;
    height: 70px;
  }

  .floatingTriggerMenuClose {
    color: $white;
    right: 20px;
    bottom: 20px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: $primary;
  }

  .floatingList {
    bottom: 100px;
    right: 30px;

    .floatingItem {
      padding: 3px;
      margin-top: 5px;
      border: 1px solid transparent;

      &:hover {
        border-radius: 30px;
        border: 1px solid $primary;
        .floatingItemTitle {
          display: block;
        }
      }
      .floatingItemTitle {
        display: none;
        font-size: 16px;
        padding: 10px;
        align-self: center;
      }
      .floatingItemIcon {
        height: 50px;
        width: 50px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary;
        border-radius: 50%;
        color: $white;
      }
    }
  }

  .floatingCircularItem {
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 3px;
    &:hover {
      border-radius: 30px;
      border: 1px solid $primary;
      z-index: 1;
      background-color: #fff;
      .floatingCircularItemTitle {
        display: block;
        font-size: 16px;
        align-self: center;
        color: $primary;
        white-space: nowrap;
        padding: 5px;
      }
    }

    .floatingCircularItemTitle {
      display: none;
    }

    .floatingCircularItemIcon {
      height: 50px;
      width: 50px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      color: $white;
    }
  }

  .floatingCircularItem:nth-child(1) {
    right: 5px;
    bottom: 80px;
  }

  .floatingCircularItem:nth-child(2) {
    right: 64px;
    bottom: 52px;
  }

  .floatingCircularItem:nth-child(3) {
    right: 79px;
    bottom: -12px;
  }

  .floatingCircularItem:nth-child(4) {
    right: 38px;
    bottom: -62px;
  }

  .agentPseudoName {
    margin-right: 5px;
    bottom: 100%;
    left: -40px;
    width: 40px;
  }

  //====================================================================================

  // .senderBubble,
  // .stackViewNotificationBubble {
    // border-bottom-left-radius: 0;

    // ---------to add tip bubble corner uncomment this
    // &::before {
    //     content: '';
    //     width: 0px;
    //     height: 0px;
    //     position: absolute;
    //     border-left: 4px solid $chatbot-sender-bubble-bg;
    //     border-right: 4px solid transparent;
    //     border-top: 4px solid transparent;
    //     border-bottom: 4px solid $chatbot-sender-bubble-bg;
    //     right: -8px;
    //     bottom: 5px;
    // }
  // }

  .stackViewNotificationBubble {
    display: block;
    margin-left: auto;
    width: fit-content;
    padding: 10px;
  }

  .senderBubble{
     border-bottom-left-radius: 0;
  }

  .receiverBubble {
    border-bottom-left-radius: 0;

    // ---------to add tip bubble corner uncomment this
    // &::before {
    //     content: '';
    //     width: 0px;
    //     height: 0px;
    //     position: absolute;
    //     border-left: 4px solid transparent;
    //     border-right: 4px solid $chatbot-server-bubble-bg;
    //     border-top: 4px solid transparent;
    //     border-bottom: 4px solid $chatbot-server-bubble-bg;
    //     left: -8px;
    //     bottom: 5px;
    // }
  }

  .notificationSenderBubble {
    border-top-right-radius: 0;
  }

  .notificationReceiverBubble {
    border-bottom-left-radius: 0;
  }

  .receiverAvatar {
    top: 5px;
    left: -40px;
  }

  .senderAvatar {
    top: 5px;
    right: -40px;
  }

  .quickReplyWrapper {
    left: 0;
    right: 0;
  }

  .headerIcon {
    color: $chatbot-header-font-light;

    &:hover {
      color: $chatbot-header-font;
    }
  }

  .clearChatIcon {
    color: $chatbot-header-font-light;

    &:hover {
      color: $chatbot-header-font;
    }
  }

  .endChatIcon {
    color: $white;
    background-color: $secondary;
    border-radius: 15px;
    padding: 5px;

    &:hover {
      border-color: $chatbot-header-font;
    }
  }

  .headerContainer {
    height: 70px;
    padding: 15px 45px 15px 20px;
    border-radius: 25px;  
  }

  .footerContainer {
    padding-right: 65px;
    padding-top: 10px;
    padding-bottom: 13px;
    background-color: $chatbot-footer-bg;
  }

  .disclaimerText {
    padding-top: 6px;
    padding-bottom: 20px;
    background-color: $chatbot-footer-bg;
    padding-left: 14px;
    padding-right: 10px;
    border-top: 1px solid $border-light;
    line-height: 12px;
    font-size: 9px;
  }

  .oriAlignEndChatBtn {
    top: 22px;
    right: 10px;
  }

  .oriInfoCloseIconAlign {
    top: 7px;
    right: 7px;
  }

  .oriHeaderTagContainer {
    border-bottom-right-radius: 10px;
  }

  .oriHeaderActionContainer {
    top: 22px;
    right: 10px;
  }

  .oriInfoContentCloseIcon {
    top: 7px;
    right: 7px;
  }

  .ori-selected-seat {
    background-color: $green !important;
  }
  .ori-upload-preview {
    width: 65% !important;
  }
  @media only screen and (max-width: 481px) {
    .ori-upload-preview {
      width: 100% !important;
    }
  }

  .ori-sender-reply-container {
    background-color: rgba(11, 20, 26, 0.2);
    border-bottom: 2px solid tint($primary, 30%);
  }

  .ori-server-reply-container {
    background-color: rgba(11, 20, 26, 0.04);
    border-bottom: 2px solid tint($primary, 90%);
  }

  .ori-selected-emoji-rating {
    opacity: 1 !important;
  }

  .ori-feedback-lowest-font {
    color: $danger;
  }

  .ori-feedback-middle-font {
    color: $font-color-light;
  }

  .ori-feedback-highest-font {
    color: $green;
  }

  .ori-end-chat-feedback-rating {
    color: $warning;
  }

  .endChatheader {
    align-items: center;

    //horizontal UI
    flex-direction: row;
    padding-top: 20px;

    //vertial UI
    // flex-direction: column;
    // padding-top: 30px;
  }

  .ori-image-brand-name {
    height: 30px;
    display: block;
    margin: auto;
  }

  .ori-end-chat-brand-name {
    // font-size: 20px; // vertical UI
     font-size: 16px;  // horizontal UI
  }

  .ori-end-chat-feedback-form {
    // margin: 190px 15px 30px 15px; // vertical UI
    margin: 80px 15px 30px 15px;  // horizontal UI
  }

  .ori-form-title {
    font-weight: 700;
    // font-size: 16px; // vertical UI
     font-size: 14px; // horizontal UI
  }

  .ori-form-subtitle {
    // font-size: 12px; // vertical UI
    font-size: 10px; // horizontal UI
  }

  .ori-end-chat-display {
    // vertical UI
    // align-items: center;
    // margin-top: 10px;

    // horizontal UI
    justify-content: space-around;
    margin-left: 10px;
  }
}
