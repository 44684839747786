@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,400i,500,700,800);
@charset "UTF-8";
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.ori-app-container {
  /*---------------------  BASE RULE FOR PROJECT  ---------------------*/
  font-family: "Roboto", sans-serif !important;
  max-width: 100vw;
  color: #666 !important;
  font-size: 14px !important;
  line-height: 1.5;
  /*---------------------  BASIC COMMON CLASSES -------------------- */
  /*---------------------- FONT CLASSES -----------------------*/
  /*---------------------- COMMON FLEX CLASSES --------------------- */
  /*---------------------- COMMON PADDING CLASSES----------------------*/
  /*---------------------------- COMMON MARGIN CLASSES-----------------------------*/ }
  .ori-app-container p {
    margin-bottom: 0;
    margin-top: 0; }
  .ori-app-container p,
  .ori-app-container span,
  .ori-app-container div,
  .ori-app-container span,
  .ori-app-container applet,
  .ori-app-container object,
  .ori-app-container iframe,
  .ori-app-container p,
  .ori-app-container blockquote,
  .ori-app-container pre,
  .ori-app-container a,
  .ori-app-container abbr,
  .ori-app-container acronym,
  .ori-app-container address,
  .ori-app-container big,
  .ori-app-container cite,
  .ori-app-container code,
  .ori-app-container del,
  .ori-app-container dfn,
  .ori-app-container em,
  .ori-app-container img,
  .ori-app-container ins,
  .ori-app-container kbd,
  .ori-app-container q,
  .ori-app-container s,
  .ori-app-container samp,
  .ori-app-container small,
  .ori-app-container strike,
  .ori-app-container strong,
  .ori-app-container sub,
  .ori-app-container sup,
  .ori-app-container tt,
  .ori-app-container var,
  .ori-app-container b,
  .ori-app-container u,
  .ori-app-container i,
  .ori-app-container center,
  .ori-app-container dl,
  .ori-app-container dt,
  .ori-app-container dd,
  .ori-app-container ol,
  .ori-app-container ul,
  .ori-app-container li,
  .ori-app-container fieldset,
  .ori-app-container form,
  .ori-app-container label,
  .ori-app-container legend,
  .ori-app-container table,
  .ori-app-container caption,
  .ori-app-container tbody,
  .ori-app-container tfoot,
  .ori-app-container thead,
  .ori-app-container tr,
  .ori-app-container th,
  .ori-app-container td,
  .ori-app-container article,
  .ori-app-container aside,
  .ori-app-container canvas,
  .ori-app-container details,
  .ori-app-container embed,
  .ori-app-container figure,
  .ori-app-container figcaption,
  .ori-app-container footer,
  .ori-app-container header,
  .ori-app-container hgroup,
  .ori-app-container menu,
  .ori-app-container nav,
  .ori-app-container output,
  .ori-app-container ruby,
  .ori-app-container section,
  .ori-app-container summary,
  .ori-app-container time,
  .ori-app-container mark,
  .ori-app-container audio,
  .ori-app-container video {
    font-family: "Roboto", sans-serif !important; }
  .ori-app-container .ori-transition-ease {
    transition: 0.5s ease; }
  .ori-app-container .ori-rotate-180 {
    transform: rotate(180deg); }
  .ori-app-container .ori-z-index-1 {
    z-index: 1; }
  .ori-app-container .ori-z-index-99990 {
    z-index: 99990; }
  .ori-app-container .ori-z-index-99991 {
    z-index: 99991; }
  .ori-app-container .ori-z-index-99992 {
    z-index: 99992; }
  .ori-app-container .ori-z-index-99993 {
    z-index: 99993; }
  .ori-app-container .ori-z-index-99994 {
    z-index: 99994; }
  .ori-app-container .ori-z-index-99995 {
    z-index: 99995; }
  .ori-app-container .ori-z-index-99996 {
    z-index: 99996; }
  .ori-app-container .ori-text-overflow-dotted {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .ori-app-container .ori-block-text-overflow-dotted {
    display: block;
    /* Fallback for non-webkit */
    display: -webkit-box;
    margin: 0 auto;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ori-app-container .ori-dotted-after-xs-1 {
    font-size: 12px;
    line-height: 1.3;
    -webkit-line-clamp: 2;
    max-height: 31.2px; }
  .ori-app-container .ori-dotted-after-xs-3 {
    font-size: 12px;
    line-height: 1.3;
    -webkit-line-clamp: 4;
    height: 62.4px; }
  .ori-app-container .ori-placeholder-primary ::placeholder {
    color: #FCC600 !important; }
  .ori-app-container .ori-overflow-y-auto {
    overflow-y: auto; }
  .ori-app-container .ori-overflow-x-hidden {
    overflow-x: hidden; }
  .ori-app-container .ori-line-height-1 {
    line-height: 1 !important; }
  .ori-app-container .ori-bg-gradient {
    background: linear-gradient(73deg, #FCC600 17%, #f9c4cb 97%); }
  .ori-app-container .ori-bg-border-light {
    background-color: #ebebeb; }
  .ori-app-container .ori-bg-white {
    background-color: #ffffff !important; }
  .ori-app-container .ori-bg-default {
    background-color: #f7f7f9; }
  .ori-app-container .ori-bg-green {
    background-color: #22a233; }
  .ori-app-container .ori-bg-warning {
    background-color: #f5a623; }
  .ori-app-container .ori-bg-yellow {
    background-color: #ffcb00; }
  .ori-app-container .ori-bg-danger {
    background-color: #ff6d4a; }
  .ori-app-container .ori-bg-card {
    background-color: rgba(239, 239, 244, 0.3); }
  .ori-app-container .ori-bg-header {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-footer {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-notification-popup {
    background-color: #88898c; }
  .ori-app-container .ori-bg-popup {
    background-color: #ffffff; }
  .ori-app-container .ori-bg-primary {
    background-color: #FCC600; }
  .ori-app-container .ori-bg-black-light {
    background-color: rgba(0, 0, 0, 0.3); }
  .ori-app-container .ori-bg-primary-light {
    background-color: #fff9e6 !important; }
  .ori-app-container .ori-bg-size-cover {
    background-size: cover; }
  .ori-app-container .ori-bg-no-repeat {
    background-repeat: no-repeat; }
  .ori-app-container .ori-bg-position-center {
    background-position: center; }
  .ori-app-container .ori-font-light-hover-default {
    cursor: pointer;
    color: #acacb4; }
    .ori-app-container .ori-font-light-hover-default:hover {
      color: #666; }
  .ori-app-container .ori-bg-light-hover-default {
    border-bottom: 1px solid #ebebeb; }
    .ori-app-container .ori-bg-light-hover-default:hover {
      cursor: pointer;
      color: #FCC600;
      background-color: #f7f7f9; }
    .ori-app-container .ori-bg-light-hover-default:last-child {
      border-bottom: none; }
  .ori-app-container .ori-popup-light-hover-default {
    cursor: pointer;
    color: #acacb4; }
    .ori-app-container .ori-popup-light-hover-default:hover {
      color: #2f3043; }
  .ori-app-container .ori-font-popup {
    color: #2f3043; }
  .ori-app-container .ori-font-white {
    color: #ffffff; }
  .ori-app-container .ori-font-notification-popup {
    color: #ffffff; }
  .ori-app-container .ori-font-header {
    color: #2f3043; }
  .ori-app-container .ori-font-header-light {
    color: #acacb4; }
  .ori-app-container .ori-font-primary {
    color: #FCC600 !important; }
  .ori-app-container .ori-font-voting {
    color: #ed3837; }
  .ori-app-container .ori-font-green {
    color: #22a233 !important; }
  .ori-app-container .ori-font-warning {
    color: #f5a623 !important; }
  .ori-app-container .ori-font-danger {
    color: #ff6d4a !important; }
  .ori-app-container .ori-font-info {
    color: #4da1ff !important; }
  .ori-app-container .ori-font-yellow {
    color: #ffcb00 !important; }
  .ori-app-container .ori-font-default {
    color: #666 !important; }
  .ori-app-container .ori-font-light {
    color: #acacb4; }
  .ori-app-container .ori-font-default-hover-primary {
    color: #666; }
    .ori-app-container .ori-font-default-hover-primary:hover {
      color: #FCC600; }
  .ori-app-container .ori-text-center {
    text-align: center; }
  .ori-app-container .ori-text-left {
    text-align: left; }
  .ori-app-container .ori-cursor-ptr {
    cursor: pointer !important; }
  .ori-app-container .ori-cursor-not-allowed {
    cursor: not-allowed !important; }
  .ori-app-container .ori-uppercase {
    text-transform: uppercase; }
  .ori-app-container .ori-capitalize {
    text-transform: capitalize; }
  .ori-app-container .ori-capitalize-first::first-letter {
    text-transform: capitalize; }
  .ori-app-container .ori-full-width {
    width: 100% !important; }
  .ori-app-container .ori-max-width-350 {
    max-width: 350px; }
  .ori-app-container .ori-width-10 {
    width: 10px; }
  .ori-app-container .ori-box-70 {
    height: 70px;
    width: 70px; }
  .ori-app-container .ori-full-height {
    height: 100vh !important; }
  .ori-app-container .ori-full-parent-height {
    height: 100% !important; }
  .ori-app-container .ori-height-10 {
    height: 10px; }
  .ori-app-container .ori-overflow-hidden {
    overflow: hidden; }
  .ori-app-container .ori-display-none {
    display: none !important; }
  .ori-app-container .ori-display-inline-block {
    display: inline-block; }
  .ori-app-container .ori-display-grid {
    display: grid; }
  .ori-app-container .ori-relative {
    position: relative !important; }
  .ori-app-container .ori-absolute {
    position: absolute !important; }
  .ori-app-container .ori-fixed {
    position: fixed !important; }
  .ori-app-container .ori-align-bottom-full {
    bottom: 100%; }
  .ori-app-container .ori-align-top {
    top: 0; }
  .ori-app-container .ori-align-bottom {
    bottom: 0; }
  .ori-app-container .ori-align-left {
    left: 0; }
  .ori-app-container .ori-align-right {
    right: 0; }
  .ori-app-container .ori-align-full {
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0; }
  .ori-app-container .ori-word-break {
    word-break: break-word !important; }
  .ori-app-container .ori-word-wrap {
    word-wrap: break-word; }
  .ori-app-container .ori-img-contain {
    width: 100%;
    height: 100%; }
  .ori-app-container .ori-box-shadow {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.11); }
  .ori-app-container .ori-box-shadow-light {
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.04); }
  .ori-app-container .ori-box-shadow-dark {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); }
  .ori-app-container .ori-border-radius-3 {
    border-radius: 3px; }
  .ori-app-container .ori-border-radius-5 {
    border-radius: 5px; }
  .ori-app-container .ori-border-radius-10 {
    border-radius: 10px; }
  .ori-app-container .ori-border-radius-20 {
    border-radius: 20px; }
  .ori-app-container .ori-tr-border-radius-5 {
    border-top-right-radius: 5px; }
  .ori-app-container .ori-tl-border-radius-5 {
    border-top-left-radius: 5px; }
  .ori-app-container .ori-border-circle {
    border-radius: 50% !important; }
  .ori-app-container .ori-border-light {
    border: 1px solid #ebebeb; }
  .ori-app-container .ori-border-dashed-danger {
    border: 1px solid #ff6d4a; }
  .ori-app-container .ori-border-default {
    border: 1px solid #e6e6e6; }
  .ori-app-container .ori-t-border-light {
    border-top: 1px solid #ebebeb; }
  .ori-app-container .ori-b-border-light {
    border-bottom: 1px solid #ebebeb; }
  .ori-app-container .ori-border-none {
    border: none !important; }
  .ori-app-container .ori-border-primary {
    border: 1px solid #FCC600 !important; }
  .ori-app-container .ori-border-dashed-default {
    border: 1px dashed #666; }
  .ori-app-container .ori-animation-half {
    animation-duration: 0.5s !important; }
  .ori-app-container .ori-font-thin {
    font-weight: 300; }
  .ori-app-container .ori-font-normal {
    font-weight: 400; }
  .ori-app-container .ori-font-medium {
    font-weight: 500; }
  .ori-app-container .ori-font-bold {
    font-weight: 700; }
  .ori-app-container .ori-font-ultra-bold {
    font-weight: 900; }
  .ori-app-container .ori-font-xxs {
    font-size: 10px !important; }
  .ori-app-container .ori-font-xs {
    font-size: 12px !important; }
  .ori-app-container .ori-font-sm {
    font-size: 14px !important; }
  .ori-app-container .ori-font-md {
    font-size: 16px !important; }
  .ori-app-container .ori-font-lg {
    font-size: 20px !important; }
  .ori-app-container .ori-flex {
    display: flex; }
  .ori-app-container .ori-flex-wrap {
    flex-wrap: wrap; }
  .ori-app-container .ori-flex-row {
    display: flex !important;
    flex-direction: row; }
  .ori-app-container .ori-flex-column {
    display: flex !important;
    flex-direction: column; }
  .ori-app-container .ori-flex-center {
    justify-content: center;
    align-items: center; }
  .ori-app-container .ori-flex-ac {
    align-items: center; }
  .ori-app-container .ori-flex-jc {
    justify-content: center; }
  .ori-app-container .ori-flex-jsb {
    justify-content: space-between; }
  .ori-app-container .ori-flex-jsa {
    justify-content: space-around; }
  .ori-app-container .ori-flex-jfe {
    justify-content: flex-end; }
  .ori-app-container .ori-full-flex {
    flex: 1 1; }
  .ori-app-container .ori-no-pad {
    padding: 0px !important; }
  .ori-app-container .ori-pad-3 {
    padding: 3px !important; }
  .ori-app-container .ori-pad-5 {
    padding: 5px !important; }
  .ori-app-container .ori-pad-7 {
    padding: 7px; }
  .ori-app-container .ori-pad-10 {
    padding: 10px !important; }
  .ori-app-container .ori-pad-12 {
    padding: 12px !important; }
  .ori-app-container .ori-pad-15 {
    padding: 15px !important; }
  .ori-app-container .ori-pad-20 {
    padding: 20px !important; }
  .ori-app-container .ori-lr-pad-5 {
    padding-left: 5px !important;
    padding-right: 5px !important; }
  .ori-app-container .ori-lr-pad-8 {
    padding-left: 8px !important;
    padding-right: 8px !important; }
  .ori-app-container .ori-lr-pad-10 {
    padding-left: 10px !important;
    padding-right: 10px !important; }
  .ori-app-container .ori-lr-pad-15 {
    padding-left: 15px !important;
    padding-right: 15px !important; }
  .ori-app-container .ori-lr-pad-20 {
    padding-left: 20px;
    padding-right: 20px; }
  .ori-app-container .ori-lr-pad-30 {
    padding-left: 30px !important;
    padding-right: 30px !important; }
  .ori-app-container .ori-lr-pad-50 {
    padding-left: 50px;
    padding-right: 50px; }
  .ori-app-container .ori-no-tb-pad {
    padding-top: 0px !important;
    padding-bottom: 0px !important; }
  .ori-app-container .ori-tb-pad-3 {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }
  .ori-app-container .ori-tb-pad-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important; }
  .ori-app-container .ori-tb-pad-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important; }
  .ori-app-container .ori-tb-pad-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .ori-app-container .ori-l-pad-5 {
    padding-left: 5px; }
  .ori-app-container .ori-l-pad-10 {
    padding-left: 10px !important; }
  .ori-app-container .ori-l-pad-15 {
    padding-left: 15px !important; }
  .ori-app-container .ori-l-pad-20 {
    padding-left: 20px; }
  .ori-app-container .ori-l-pad-25 {
    padding-left: 25px; }
  .ori-app-container .ori-l-pad-30 {
    padding-left: 30px; }
  .ori-app-container .ori-r-pad-5 {
    padding-right: 5px; }
  .ori-app-container .ori-r-pad-10 {
    padding-right: 10px; }
  .ori-app-container .ori-r-pad-20 {
    padding-right: 20px; }
  .ori-app-container .ori-r-pad-40 {
    padding-right: 40px; }
  .ori-app-container .ori-t-pad-3 {
    padding-top: 3px; }
  .ori-app-container .ori-t-pad-5 {
    padding-top: 5px; }
  .ori-app-container .ori-t-pad-10 {
    padding-top: 10px; }
  .ori-app-container .ori-t-pad-15 {
    padding-top: 15px; }
  .ori-app-container .ori-t-pad-20 {
    padding-top: 20px; }
  .ori-app-container .ori-t-pad-30 {
    padding-top: 30px; }
  .ori-app-container .ori-no-b-pad {
    padding-bottom: 0px !important; }
  .ori-app-container .ori-b-pad-5 {
    padding-bottom: 5px !important; }
  .ori-app-container .ori-b-pad-10 {
    padding-bottom: 10px; }
  .ori-app-container .ori-b-pad-15 {
    padding-bottom: 15px; }
  .ori-app-container .ori-b-pad-25 {
    padding-bottom: 25px; }
  .ori-app-container .ori-b-pad-40 {
    padding-bottom: 40px; }
  .ori-app-container .ori-no-b-mrgn {
    margin-bottom: 0px !important; }
  .ori-app-container .ori-no-t-mrgn {
    margin-top: 0px !important; }
  .ori-app-container .ori-lr-mrgn-3 {
    margin-left: 3px;
    margin-right: 3px; }
  .ori-app-container .ori-lr-mrgn-5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  .ori-app-container .ori-lr-mrgn-10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  .ori-app-container .ori-tb-mrgn-3 {
    margin-top: 3px !important;
    margin-bottom: 3px !important; }
  .ori-app-container .ori-tb-mrgn-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important; }
  .ori-app-container .ori-l-mrgn-5 {
    margin-left: 5px !important; }
  .ori-app-container .ori-l-mrgn-10 {
    margin-left: 10px; }
  .ori-app-container .ori-l-mrgn-15 {
    margin-left: 15px; }
  .ori-app-container .ori-r-mrgn-5 {
    margin-right: 5px; }
  .ori-app-container .ori-r-mrgn-10 {
    margin-right: 10px; }
  .ori-app-container .ori-r-mrgn-15 {
    margin-right: 15px; }
  .ori-app-container .ori-t-mrgn-3 {
    margin-top: 3px; }
  .ori-app-container .ori-t-mrgn-5 {
    margin-top: 5px; }
  .ori-app-container .ori-t-mrgn-10 {
    margin-top: 10px; }
  .ori-app-container .ori-t-mrgn-15 {
    margin-top: 15px; }
  .ori-app-container .ori-b-mrgn-5 {
    margin-bottom: 5px; }
  .ori-app-container .ori-b-mrgn-7 {
    margin-bottom: 7px; }
  .ori-app-container .ori-b-mrgn-10 {
    margin-bottom: 10px; }
  .ori-app-container .ori-mrgn-auto {
    margin: auto; }
  .ori-app-container .ori-mrgn-10 {
    margin: 10px; }
  .ori-app-container .ori-mrgn-20 {
    margin: 20px; }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/

@keyframes ori-card-loading {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes ori-pulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    transform: scale3d(1, 1, 1); } }

@keyframes ori-ripple {
  0% {
    transform: scale(1);
    opacity: 0.1; }
  25% {
    transform: scale(1);
    opacity: 0.5; }
  35% {
    transform: scale(1);
    opacity: 1; }
  50% {
    transform: scale(1.5);
    opacity: 0.6; }
  75% {
    transform: scale(1.7);
    opacity: 0.4; }
  100% {
    opacity: 0.1;
    transform: scale(2); } }

@keyframes ori-rotate {
  0% {
    transform: rotate(0deg); }
  25% {
    transform: rotate(90deg); }
  50% {
    transform: rotate(180deg); }
  75% {
    transform: rotate(270deg); }
  100% {
    transform: rotate(359deg); } }

@keyframes ori-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes ori-fade-in-right {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes ori-fade-in-up {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes ori-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes ori-fade-out-right {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

@keyframes ori-fade-out-down {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

@keyframes ori-zoom-in {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes ori-zoom-out {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

@keyframes ori-translate-down {
  from {
    transform: translate3d(0, -100%, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

@keyframes ori-zoom-in-bottom-left {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 1000px, -1000px);
    animation-timing-function: ease; }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease; } }

@keyframes ori-zoom-out-bottom-left {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease; }
  to {
    opacity: 0;
    transform: scale(0.1) translate(-1000px, 1300px);
    transform-origin: left center;
    animation-timing-function: ease; } }

@keyframes ori-zoom-in-bottom-right {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 1000px, 1000px);
    animation-timing-function: ease; }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease; } }

@keyframes ori-zoom-out-bottom-right {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: ease; }
  to {
    opacity: 0;
    transform: scale(0.1) translate(1000px, 1300px);
    transform-origin: right center;
    animation-timing-function: ease; } }

.ori-card-loading {
  border-radius: 2px;
  background: linear-gradient(90deg, rgba(153, 153, 153, 0.1), rgba(153, 153, 153, 0.3), rgba(153, 153, 153, 0.1));
  background-size: 600% 600%;
  animation: ori-card-loading 1.5s ease infinite; }

.ori-animated {
  animation-fill-mode: both; }

.ori-infinite {
  animation-iteration-count: infinite !important; }

.ori-ripple {
  animation: ori-ripple 1.5s; }

.ori-pulse {
  animation: ori-pulse 1s; }

.ori-rotate {
  animation: ori-rotate 1s; }

.ori-translate-down {
  animation: ori-translate-down 1s; }

.ori-fade-in {
  animation: ori-fade-in 1s; }

.ori-banner-fade-in {
  animation: ori-fade-in 0.75s; }

.ori-fade-in-up {
  animation: ori-fade-in-up 1s; }

.ori-fade-out {
  animation: ori-fade-out 1s; }

.ori-banner-fade-out {
  animation: ori-fade-out 0.25s; }

.ori-fade-out-left {
  animation: ori-fade-out-left 1s; }

.ori-fade-out-down {
  animation: ori-fade-out-down 1s; }

.ori-zoom-in {
  animation: ori-zoom-in 1s; }

.ori-zoom-out {
  animation: ori-zoom-out 1s; }

.ori-zoom-in-bottom-left {
  animation: ori-zoom-in-bottom-left 1s; }

.ori-zoom-out-bottom-left {
  animation: ori-zoom-out-bottom-left 1s; }

.ori-zoom-in-bottom-right {
  animation: ori-zoom-in-bottom-right 1s; }

.ori-zoom-out-bottom-right {
  animation: ori-zoom-out-bottom-right 1s; }

@font-face {
  font-family: "Monospaced Number";
  src: local("Tahoma");
  unicode-range: U+30-39; }

@font-face {
  font-family: "Chinese Quote";
  src: local("PingFang SC"), local("SimSun");
  unicode-range: U+2018, U+2019, U+201c, U+201d; }

@font-face {
  font-family: 'anticon';
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.eot");
  /* IE9*/
  src: url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.woff") format("woff"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.ttf") format("truetype"), url("https://at.alicdn.com/t/font_148784_v4ggb6wrjmkotj4i.svg#iconfont") format("svg"); }

.ori-ant-design-container input::-ms-clear,
.ori-ant-design-container input::-ms-reveal {
  display: none; }

.ori-ant-design-container *,
.ori-ant-design-container *::before,
.ori-ant-design-container *::after {
  box-sizing: border-box; }

.ori-ant-design-container article,
.ori-ant-design-container aside,
.ori-ant-design-container dialog,
.ori-ant-design-container figcaption,
.ori-ant-design-container figure,
.ori-ant-design-container footer,
.ori-ant-design-container header,
.ori-ant-design-container hgroup,
.ori-ant-design-container main,
.ori-ant-design-container nav,
.ori-ant-design-container section {
  display: block; }

.ori-ant-design-container [tabindex="-1"]:focus {
  outline: none !important; }

.ori-ant-design-container hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.ori-ant-design-container h1,
.ori-ant-design-container h2,
.ori-ant-design-container h3,
.ori-ant-design-container h4,
.ori-ant-design-container h5,
.ori-ant-design-container h6 {
  margin-top: 0;
  margin-bottom: .5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500; }

.ori-ant-design-container abbr[title],
.ori-ant-design-container abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

.ori-ant-design-container address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit; }

.ori-ant-design-container input[type="text"],
.ori-ant-design-container input[type="password"],
.ori-ant-design-container input[type="number"],
.ori-ant-design-container textarea {
  -webkit-appearance: none; }

.ori-ant-design-container ol,
.ori-ant-design-container ul,
.ori-ant-design-container dl {
  margin-top: 0;
  margin-bottom: 1em; }

.ori-ant-design-container ol ol,
.ori-ant-design-container ul ul,
.ori-ant-design-container ol ul,
.ori-ant-design-container ul ol {
  margin-bottom: 0; }

.ori-ant-design-container dt {
  font-weight: 500; }

.ori-ant-design-container dd {
  margin-bottom: .5em;
  margin-left: 0; }

.ori-ant-design-container blockquote {
  margin: 0 0 1em; }

.ori-ant-design-container dfn {
  font-style: italic; }

.ori-ant-design-container b,
.ori-ant-design-container strong {
  font-weight: bolder; }

.ori-ant-design-container small {
  font-size: 80%; }

.ori-ant-design-container sub,
.ori-ant-design-container sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

.ori-ant-design-container sub {
  bottom: -0.25em; }

.ori-ant-design-container sup {
  top: -0.5em; }

.ori-ant-design-container a {
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects; }

.ori-ant-design-container a:focus {
  text-decoration: underline;
  -webkit-text-decoration-skip: ink;
  text-decoration-skip: ink; }

.ori-ant-design-container a:hover {
  color: #40a9ff; }

.ori-ant-design-container a:active {
  color: #096dd9; }

.ori-ant-design-container a:active,
.ori-ant-design-container a:hover {
  outline: 0;
  text-decoration: none; }

.ori-ant-design-container a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none; }

.ori-ant-design-container pre,
.ori-ant-design-container code,
.ori-ant-design-container kbd,
.ori-ant-design-container samp {
  font-family: Consolas, Menlo, Courier, monospace;
  font-size: 1em; }

.ori-ant-design-container pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto; }

.ori-ant-design-container figure {
  margin: 0 0 1em; }

.ori-ant-design-container img {
  vertical-align: middle;
  border-style: none; }

.ori-ant-design-container svg:not(:root) {
  overflow: hidden; }

.ori-ant-design-container a,
.ori-ant-design-container area,
.ori-ant-design-container button,
.ori-ant-design-container [role="button"],
.ori-ant-design-container input:not([type=range]),
.ori-ant-design-container label,
.ori-ant-design-container select,
.ori-ant-design-container summary,
.ori-ant-design-container textarea {
  touch-action: manipulation; }

.ori-ant-design-container table {
  border-collapse: collapse; }

.ori-ant-design-container caption {
  padding-top: .75em;
  padding-bottom: .3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom; }

.ori-ant-design-container th {
  text-align: inherit; }

.ori-ant-design-container input,
.ori-ant-design-container button,
.ori-ant-design-container select,
.ori-ant-design-container optgroup,
.ori-ant-design-container textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit; }

.ori-ant-design-container button,
.ori-ant-design-container input {
  overflow: visible; }

.ori-ant-design-container button,
.ori-ant-design-container select {
  text-transform: none; }

.ori-ant-design-container button,
.ori-ant-design-container [type="button"],
.ori-ant-design-container [type="reset"],
.ori-ant-design-container [type="submit"] {
  -webkit-appearance: button; }

.ori-ant-design-container button::-moz-focus-inner,
.ori-ant-design-container [type="button"]::-moz-focus-inner,
.ori-ant-design-container [type="reset"]::-moz-focus-inner,
.ori-ant-design-container [type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

.ori-ant-design-container input[type="radio"],
.ori-ant-design-container input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

.ori-ant-design-container input[type="date"],
.ori-ant-design-container input[type="time"],
.ori-ant-design-container input[type="datetime-local"],
.ori-ant-design-container input[type="month"] {
  -webkit-appearance: listbox; }

.ori-ant-design-container textarea {
  overflow: auto;
  resize: vertical; }

.ori-ant-design-container fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

.ori-ant-design-container legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5em;
  font-size: 1.5em;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

.ori-ant-design-container progress {
  vertical-align: baseline; }

.ori-ant-design-container [type="number"]::-webkit-inner-spin-button,
.ori-ant-design-container [type="number"]::-webkit-outer-spin-button {
  height: auto; }

.ori-ant-design-container [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

.ori-ant-design-container [type="search"]::-webkit-search-cancel-button,
.ori-ant-design-container [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

.ori-ant-design-container ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

.ori-ant-design-container output {
  display: inline-block; }

.ori-ant-design-container summary {
  display: list-item; }

.ori-ant-design-container template {
  display: none; }

.ori-ant-design-container [hidden] {
  display: none !important; }

.ori-ant-design-container mark {
  padding: .2em;
  background-color: #feffe6; }

.ori-ant-design-container ::selection {
  background: #1890ff;
  color: #fff; }

.ori-ant-design-container .clearfix {
  zoom: 1; }

.ori-ant-design-container .clearfix:before,
.ori-ant-design-container .clearfix:after {
  content: " ";
  display: table; }

.ori-ant-design-container .clearfix:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0; }

.ori-ant-design-container .anticon {
  display: inline-block;
  font-style: normal;
  text-align: center;
  text-transform: none;
  line-height: 1;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ori-ant-design-container .anticon:before {
  display: block;
  font-family: "anticon" !important; }

.ori-ant-design-container .anticon-step-forward:before {
  content: "\e600"; }

.ori-ant-design-container .anticon-step-backward:before {
  content: "\e601"; }

.ori-ant-design-container .anticon-forward:before {
  content: "\e602"; }

.ori-ant-design-container .anticon-backward:before {
  content: "\e603"; }

.ori-ant-design-container .anticon-caret-right:before {
  content: "\e604"; }

.ori-ant-design-container .anticon-caret-left:before {
  content: "\e605"; }

.ori-ant-design-container .anticon-caret-down:before {
  content: "\e606"; }

.ori-ant-design-container .anticon-caret-up:before {
  content: "\e607"; }

.ori-ant-design-container .anticon-right-circle:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-circle-right:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-caret-circle-right:before {
  content: "\e608"; }

.ori-ant-design-container .anticon-left-circle:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-circle-left:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-caret-circle-left:before {
  content: "\e609"; }

.ori-ant-design-container .anticon-up-circle:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-circle-up:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-caret-circle-up:before {
  content: "\e60a"; }

.ori-ant-design-container .anticon-down-circle:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-circle-down:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-caret-circle-down:before {
  content: "\e60b"; }

.ori-ant-design-container .anticon-right-circle-o:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-circle-o-right:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-caret-circle-o-right:before {
  content: "\e60c"; }

.ori-ant-design-container .anticon-left-circle-o:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-circle-o-left:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-caret-circle-o-left:before {
  content: "\e60d"; }

.ori-ant-design-container .anticon-up-circle-o:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-circle-o-up:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-caret-circle-o-up:before {
  content: "\e60e"; }

.ori-ant-design-container .anticon-down-circle-o:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-circle-o-down:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-caret-circle-o-down:before {
  content: "\e60f"; }

.ori-ant-design-container .anticon-verticle-left:before {
  content: "\e610"; }

.ori-ant-design-container .anticon-verticle-right:before {
  content: "\e611"; }

.ori-ant-design-container .anticon-rollback:before {
  content: "\e612"; }

.ori-ant-design-container .anticon-retweet:before {
  content: "\e613"; }

.ori-ant-design-container .anticon-shrink:before {
  content: "\e614"; }

.ori-ant-design-container .anticon-arrows-alt:before {
  content: "\e615"; }

.ori-ant-design-container .anticon-arrow-salt:before {
  content: "\e615"; }

.ori-ant-design-container .anticon-reload:before {
  content: "\e616"; }

.ori-ant-design-container .anticon-double-right:before {
  content: "\e617"; }

.ori-ant-design-container .anticon-double-left:before {
  content: "\e618"; }

.ori-ant-design-container .anticon-arrow-down:before {
  content: "\e619"; }

.ori-ant-design-container .anticon-arrow-up:before {
  content: "\e61a"; }

.ori-ant-design-container .anticon-arrow-right:before {
  content: "\e61b"; }

.ori-ant-design-container .anticon-arrow-left:before {
  content: "\e61c"; }

.ori-ant-design-container .anticon-down:before {
  content: "\e61d"; }

.ori-ant-design-container .anticon-up:before {
  content: "\e61e"; }

.ori-ant-design-container .anticon-right:before {
  content: "\e61f"; }

.ori-ant-design-container .anticon-left:before {
  content: "\e620"; }

.ori-ant-design-container .anticon-minus-square-o:before {
  content: "\e621"; }

.ori-ant-design-container .anticon-minus-circle:before {
  content: "\e622"; }

.ori-ant-design-container .anticon-minus-circle-o:before {
  content: "\e623"; }

.ori-ant-design-container .anticon-minus:before {
  content: "\e624"; }

.ori-ant-design-container .anticon-plus-circle-o:before {
  content: "\e625"; }

.ori-ant-design-container .anticon-plus-circle:before {
  content: "\e626"; }

.ori-ant-design-container .anticon-plus:before {
  content: "\e627"; }

.ori-ant-design-container .anticon-info-circle:before {
  content: "\e628"; }

.ori-ant-design-container .anticon-info-circle-o:before {
  content: "\e629"; }

.ori-ant-design-container .anticon-info:before {
  content: "\e62a"; }

.ori-ant-design-container .anticon-exclamation:before {
  content: "\e62b"; }

.ori-ant-design-container .anticon-exclamation-circle:before {
  content: "\e62c"; }

.ori-ant-design-container .anticon-exclamation-circle-o:before {
  content: "\e62d"; }

.ori-ant-design-container .anticon-close-circle:before {
  content: "\e62e"; }

.ori-ant-design-container .anticon-cross-circle:before {
  content: "\e62e"; }

.ori-ant-design-container .anticon-close-circle-o:before {
  content: "\e62f"; }

.ori-ant-design-container .anticon-cross-circle-o:before {
  content: "\e62f"; }

.ori-ant-design-container .anticon-check-circle:before {
  content: "\e630"; }

.ori-ant-design-container .anticon-check-circle-o:before {
  content: "\e631"; }

.ori-ant-design-container .anticon-check:before {
  content: "\e632"; }

.ori-ant-design-container .anticon-close:before {
  content: "\e633"; }

.ori-ant-design-container .anticon-cross:before {
  content: "\e633"; }

.ori-ant-design-container .anticon-customer-service:before {
  content: "\e634"; }

.ori-ant-design-container .anticon-customerservice:before {
  content: "\e634"; }

.ori-ant-design-container .anticon-credit-card:before {
  content: "\e635"; }

.ori-ant-design-container .anticon-code-o:before {
  content: "\e636"; }

.ori-ant-design-container .anticon-book:before {
  content: "\e637"; }

.ori-ant-design-container .anticon-bars:before {
  content: "\e639"; }

.ori-ant-design-container .anticon-question:before {
  content: "\e63a"; }

.ori-ant-design-container .anticon-question-circle:before {
  content: "\e63b"; }

.ori-ant-design-container .anticon-question-circle-o:before {
  content: "\e63c"; }

.ori-ant-design-container .anticon-pause:before {
  content: "\e63d"; }

.ori-ant-design-container .anticon-pause-circle:before {
  content: "\e63e"; }

.ori-ant-design-container .anticon-pause-circle-o:before {
  content: "\e63f"; }

.ori-ant-design-container .anticon-clock-circle:before {
  content: "\e640"; }

.ori-ant-design-container .anticon-clock-circle-o:before {
  content: "\e641"; }

.ori-ant-design-container .anticon-swap:before {
  content: "\e642"; }

.ori-ant-design-container .anticon-swap-left:before {
  content: "\e643"; }

.ori-ant-design-container .anticon-swap-right:before {
  content: "\e644"; }

.ori-ant-design-container .anticon-plus-square-o:before {
  content: "\e645"; }

.ori-ant-design-container .anticon-frown:before {
  content: "\e646"; }

.ori-ant-design-container .anticon-frown-circle:before {
  content: "\e646"; }

.ori-ant-design-container .anticon-ellipsis:before {
  content: "\e647"; }

.ori-ant-design-container .anticon-copy:before {
  content: "\e648"; }

.ori-ant-design-container .anticon-menu-fold:before {
  content: "\e9ac"; }

.ori-ant-design-container .anticon-mail:before {
  content: "\e659"; }

.ori-ant-design-container .anticon-logout:before {
  content: "\e65a"; }

.ori-ant-design-container .anticon-link:before {
  content: "\e65b"; }

.ori-ant-design-container .anticon-area-chart:before {
  content: "\e65c"; }

.ori-ant-design-container .anticon-line-chart:before {
  content: "\e65d"; }

.ori-ant-design-container .anticon-home:before {
  content: "\e65e"; }

.ori-ant-design-container .anticon-laptop:before {
  content: "\e65f"; }

.ori-ant-design-container .anticon-star:before {
  content: "\e660"; }

.ori-ant-design-container .anticon-star-o:before {
  content: "\e661"; }

.ori-ant-design-container .anticon-folder:before {
  content: "\e662"; }

.ori-ant-design-container .anticon-filter:before {
  content: "\e663"; }

.ori-ant-design-container .anticon-file:before {
  content: "\e664"; }

.ori-ant-design-container .anticon-exception:before {
  content: "\e665"; }

.ori-ant-design-container .anticon-meh:before {
  content: "\e666"; }

.ori-ant-design-container .anticon-meh-circle:before {
  content: "\e666"; }

.ori-ant-design-container .anticon-meh-o:before {
  content: "\e667"; }

.ori-ant-design-container .anticon-shopping-cart:before {
  content: "\e668"; }

.ori-ant-design-container .anticon-save:before {
  content: "\e669"; }

.ori-ant-design-container .anticon-user:before {
  content: "\e66a"; }

.ori-ant-design-container .anticon-video-camera:before {
  content: "\e66b"; }

.ori-ant-design-container .anticon-to-top:before {
  content: "\e66c"; }

.ori-ant-design-container .anticon-team:before {
  content: "\e66d"; }

.ori-ant-design-container .anticon-tablet:before {
  content: "\e66e"; }

.ori-ant-design-container .anticon-solution:before {
  content: "\e66f"; }

.ori-ant-design-container .anticon-search:before {
  content: "\e670"; }

.ori-ant-design-container .anticon-share-alt:before {
  content: "\e671"; }

.ori-ant-design-container .anticon-setting:before {
  content: "\e672"; }

.ori-ant-design-container .anticon-poweroff:before {
  content: "\e6d5"; }

.ori-ant-design-container .anticon-picture:before {
  content: "\e674"; }

.ori-ant-design-container .anticon-phone:before {
  content: "\e675"; }

.ori-ant-design-container .anticon-paper-clip:before {
  content: "\e676"; }

.ori-ant-design-container .anticon-notification:before {
  content: "\e677"; }

.ori-ant-design-container .anticon-mobile:before {
  content: "\e678"; }

.ori-ant-design-container .anticon-menu-unfold:before {
  content: "\e9ad"; }

.ori-ant-design-container .anticon-inbox:before {
  content: "\e67a"; }

.ori-ant-design-container .anticon-lock:before {
  content: "\e67b"; }

.ori-ant-design-container .anticon-qrcode:before {
  content: "\e67c"; }

.ori-ant-design-container .anticon-play-circle:before {
  content: "\e6d0"; }

.ori-ant-design-container .anticon-play-circle-o:before {
  content: "\e6d1"; }

.ori-ant-design-container .anticon-tag:before {
  content: "\e6d2"; }

.ori-ant-design-container .anticon-tag-o:before {
  content: "\e6d3"; }

.ori-ant-design-container .anticon-tags:before {
  content: "\e67d"; }

.ori-ant-design-container .anticon-tags-o:before {
  content: "\e67e"; }

.ori-ant-design-container .anticon-cloud-o:before {
  content: "\e67f"; }

.ori-ant-design-container .anticon-cloud:before {
  content: "\e680"; }

.ori-ant-design-container .anticon-cloud-upload:before {
  content: "\e681"; }

.ori-ant-design-container .anticon-cloud-download:before {
  content: "\e682"; }

.ori-ant-design-container .anticon-cloud-download-o:before {
  content: "\e683"; }

.ori-ant-design-container .anticon-cloud-upload-o:before {
  content: "\e684"; }

.ori-ant-design-container .anticon-environment:before {
  content: "\e685"; }

.ori-ant-design-container .anticon-environment-o:before {
  content: "\e686"; }

.ori-ant-design-container .anticon-eye:before {
  content: "\e687"; }

.ori-ant-design-container .anticon-eye-o:before {
  content: "\e688"; }

.ori-ant-design-container .anticon-camera:before {
  content: "\e689"; }

.ori-ant-design-container .anticon-camera-o:before {
  content: "\e68a"; }

.ori-ant-design-container .anticon-windows:before {
  content: "\e68b"; }

.ori-ant-design-container .anticon-apple:before {
  content: "\e68c"; }

.ori-ant-design-container .anticon-apple-o:before {
  content: "\e6d4"; }

.ori-ant-design-container .anticon-android:before {
  content: "\e938"; }

.ori-ant-design-container .anticon-android-o:before {
  content: "\e68d"; }

.ori-ant-design-container .anticon-aliwangwang:before {
  content: "\e68e"; }

.ori-ant-design-container .anticon-aliwangwang-o:before {
  content: "\e68f"; }

.ori-ant-design-container .anticon-export:before {
  content: "\e691"; }

.ori-ant-design-container .anticon-edit:before {
  content: "\e692"; }

.ori-ant-design-container .anticon-circle-down-o:before {
  content: "\e693"; }

.ori-ant-design-container .anticon-circle-down-:before {
  content: "\e694"; }

.ori-ant-design-container .anticon-appstore-o:before {
  content: "\e695"; }

.ori-ant-design-container .anticon-appstore:before {
  content: "\e696"; }

.ori-ant-design-container .anticon-scan:before {
  content: "\e697"; }

.ori-ant-design-container .anticon-file-text:before {
  content: "\e698"; }

.ori-ant-design-container .anticon-folder-open:before {
  content: "\e699"; }

.ori-ant-design-container .anticon-hdd:before {
  content: "\e69a"; }

.ori-ant-design-container .anticon-ie:before {
  content: "\e69b"; }

.ori-ant-design-container .anticon-file-jpg:before {
  content: "\e69c"; }

.ori-ant-design-container .anticon-like:before {
  content: "\e64c"; }

.ori-ant-design-container .anticon-like-o:before {
  content: "\e69d"; }

.ori-ant-design-container .anticon-dislike:before {
  content: "\e64b"; }

.ori-ant-design-container .anticon-dislike-o:before {
  content: "\e69e"; }

.ori-ant-design-container .anticon-delete:before {
  content: "\e69f"; }

.ori-ant-design-container .anticon-enter:before {
  content: "\e6a0"; }

.ori-ant-design-container .anticon-pushpin-o:before {
  content: "\e6a1"; }

.ori-ant-design-container .anticon-pushpin:before {
  content: "\e6a2"; }

.ori-ant-design-container .anticon-heart:before {
  content: "\e6a3"; }

.ori-ant-design-container .anticon-heart-o:before {
  content: "\e6a4"; }

.ori-ant-design-container .anticon-pay-circle:before {
  content: "\e6a5"; }

.ori-ant-design-container .anticon-pay-circle-o:before {
  content: "\e6a6"; }

.ori-ant-design-container .anticon-smile:before {
  content: "\e6a7"; }

.ori-ant-design-container .anticon-smile-circle:before {
  content: "\e6a7"; }

.ori-ant-design-container .anticon-smile-o:before {
  content: "\e6a8"; }

.ori-ant-design-container .anticon-frown-o:before {
  content: "\e6a9"; }

.ori-ant-design-container .anticon-calculator:before {
  content: "\e6aa"; }

.ori-ant-design-container .anticon-message:before {
  content: "\e6ab"; }

.ori-ant-design-container .anticon-chrome:before {
  content: "\e6ac"; }

.ori-ant-design-container .anticon-github:before {
  content: "\e6ad"; }

.ori-ant-design-container .anticon-file-unknown:before {
  content: "\e6af"; }

.ori-ant-design-container .anticon-file-excel:before {
  content: "\e6b0"; }

.ori-ant-design-container .anticon-file-ppt:before {
  content: "\e6b1"; }

.ori-ant-design-container .anticon-file-word:before {
  content: "\e6b2"; }

.ori-ant-design-container .anticon-file-pdf:before {
  content: "\e6b3"; }

.ori-ant-design-container .anticon-desktop:before {
  content: "\e6b4"; }

.ori-ant-design-container .anticon-upload:before {
  content: "\e6b6"; }

.ori-ant-design-container .anticon-download:before {
  content: "\e6b7"; }

.ori-ant-design-container .anticon-pie-chart:before {
  content: "\e6b8"; }

.ori-ant-design-container .anticon-unlock:before {
  content: "\e6ba"; }

.ori-ant-design-container .anticon-calendar:before {
  content: "\e6bb"; }

.ori-ant-design-container .anticon-windows-o:before {
  content: "\e6bc"; }

.ori-ant-design-container .anticon-dot-chart:before {
  content: "\e6bd"; }

.ori-ant-design-container .anticon-bar-chart:before {
  content: "\e6be"; }

.ori-ant-design-container .anticon-code:before {
  content: "\e6bf"; }

.ori-ant-design-container .anticon-api:before {
  content: "\e951"; }

.ori-ant-design-container .anticon-plus-square:before {
  content: "\e6c0"; }

.ori-ant-design-container .anticon-minus-square:before {
  content: "\e6c1"; }

.ori-ant-design-container .anticon-close-square:before {
  content: "\e6c2"; }

.ori-ant-design-container .anticon-close-square-o:before {
  content: "\e6c3"; }

.ori-ant-design-container .anticon-check-square:before {
  content: "\e6c4"; }

.ori-ant-design-container .anticon-check-square-o:before {
  content: "\e6c5"; }

.ori-ant-design-container .anticon-fast-backward:before {
  content: "\e6c6"; }

.ori-ant-design-container .anticon-fast-forward:before {
  content: "\e6c7"; }

.ori-ant-design-container .anticon-up-square:before {
  content: "\e6c8"; }

.ori-ant-design-container .anticon-down-square:before {
  content: "\e6c9"; }

.ori-ant-design-container .anticon-left-square:before {
  content: "\e6ca"; }

.ori-ant-design-container .anticon-right-square:before {
  content: "\e6cb"; }

.ori-ant-design-container .anticon-right-square-o:before {
  content: "\e6cc"; }

.ori-ant-design-container .anticon-left-square-o:before {
  content: "\e6cd"; }

.ori-ant-design-container .anticon-down-square-o:before {
  content: "\e6ce"; }

.ori-ant-design-container .anticon-up-square-o:before {
  content: "\e6cf"; }

.ori-ant-design-container .anticon-loading:before {
  content: "\e64d"; }

.ori-ant-design-container .anticon-loading-3-quarters:before {
  content: "\e6ae"; }

.ori-ant-design-container .anticon-bulb:before {
  content: "\e649"; }

.ori-ant-design-container .anticon-select:before {
  content: "\e64a"; }

.ori-ant-design-container .anticon-addfile:before,
.ori-ant-design-container .anticon-file-add:before {
  content: "\e910"; }

.ori-ant-design-container .anticon-addfolder:before,
.ori-ant-design-container .anticon-folder-add:before {
  content: "\e914"; }

.ori-ant-design-container .anticon-switcher:before {
  content: "\e913"; }

.ori-ant-design-container .anticon-rocket:before {
  content: "\e90f"; }

.ori-ant-design-container .anticon-dingding:before {
  content: "\e923"; }

.ori-ant-design-container .anticon-dingding-o:before {
  content: "\e925"; }

.ori-ant-design-container .anticon-bell:before {
  content: "\e64e"; }

.ori-ant-design-container .anticon-disconnect:before {
  content: "\e64f"; }

.ori-ant-design-container .anticon-database:before {
  content: "\e650"; }

.ori-ant-design-container .anticon-compass:before {
  content: "\e6db"; }

.ori-ant-design-container .anticon-barcode:before {
  content: "\e652"; }

.ori-ant-design-container .anticon-hourglass:before {
  content: "\e653"; }

.ori-ant-design-container .anticon-key:before {
  content: "\e654"; }

.ori-ant-design-container .anticon-flag:before {
  content: "\e655"; }

.ori-ant-design-container .anticon-layout:before {
  content: "\e656"; }

.ori-ant-design-container .anticon-login:before {
  content: "\e657"; }

.ori-ant-design-container .anticon-printer:before {
  content: "\e673"; }

.ori-ant-design-container .anticon-sound:before {
  content: "\e6e9"; }

.ori-ant-design-container .anticon-usb:before {
  content: "\e6d7"; }

.ori-ant-design-container .anticon-skin:before {
  content: "\e6d8"; }

.ori-ant-design-container .anticon-tool:before {
  content: "\e6d9"; }

.ori-ant-design-container .anticon-sync:before {
  content: "\e6da"; }

.ori-ant-design-container .anticon-wifi:before {
  content: "\e6d6"; }

.ori-ant-design-container .anticon-car:before {
  content: "\e6dc"; }

.ori-ant-design-container .anticon-copyright:before {
  content: "\e6de"; }

.ori-ant-design-container .anticon-schedule:before {
  content: "\e6df"; }

.ori-ant-design-container .anticon-user-add:before {
  content: "\e6ed"; }

.ori-ant-design-container .anticon-user-delete:before {
  content: "\e6e0"; }

.ori-ant-design-container .anticon-usergroup-add:before {
  content: "\e6dd"; }

.ori-ant-design-container .anticon-usergroup-delete:before {
  content: "\e6e1"; }

.ori-ant-design-container .anticon-man:before {
  content: "\e6e2"; }

.ori-ant-design-container .anticon-woman:before {
  content: "\e6ec"; }

.ori-ant-design-container .anticon-shop:before {
  content: "\e6e3"; }

.ori-ant-design-container .anticon-gift:before {
  content: "\e6e4"; }

.ori-ant-design-container .anticon-idcard:before {
  content: "\e6e5"; }

.ori-ant-design-container .anticon-medicine-box:before {
  content: "\e6e6"; }

.ori-ant-design-container .anticon-red-envelope:before {
  content: "\e6e7"; }

.ori-ant-design-container .anticon-coffee:before {
  content: "\e6e8"; }

.ori-ant-design-container .anticon-trademark:before {
  content: "\e651"; }

.ori-ant-design-container .anticon-safety:before {
  content: "\e6ea"; }

.ori-ant-design-container .anticon-wallet:before {
  content: "\e6eb"; }

.ori-ant-design-container .anticon-bank:before {
  content: "\e6ee"; }

.ori-ant-design-container .anticon-trophy:before {
  content: "\e6ef"; }

.ori-ant-design-container .anticon-contacts:before {
  content: "\e6f0"; }

.ori-ant-design-container .anticon-global:before {
  content: "\e6f1"; }

.ori-ant-design-container .anticon-shake:before {
  content: "\e94f"; }

.ori-ant-design-container .anticon-fork:before {
  content: "\e6f2"; }

.ori-ant-design-container .anticon-dashboard:before {
  content: "\e99a"; }

.ori-ant-design-container .anticon-profile:before {
  content: "\e999"; }

.ori-ant-design-container .anticon-table:before {
  content: "\e998"; }

.ori-ant-design-container .anticon-warning:before {
  content: "\e997"; }

.ori-ant-design-container .anticon-form:before {
  content: "\e996"; }

.ori-ant-design-container .anticon-spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear; }

.ori-ant-design-container .anticon-weibo-square:before {
  content: "\e6f5"; }

.ori-ant-design-container .anticon-weibo-circle:before {
  content: "\e6f4"; }

.ori-ant-design-container .anticon-taobao-circle:before {
  content: "\e6f3"; }

.ori-ant-design-container .anticon-html5:before {
  content: "\e9c7"; }

.ori-ant-design-container .anticon-weibo:before {
  content: "\e9c6"; }

.ori-ant-design-container .anticon-twitter:before {
  content: "\e9c5"; }

.ori-ant-design-container .anticon-wechat:before {
  content: "\e9c4"; }

.ori-ant-design-container .anticon-youtube:before {
  content: "\e9c3"; }

.ori-ant-design-container .anticon-alipay-circle:before {
  content: "\e9c2"; }

.ori-ant-design-container .anticon-taobao:before {
  content: "\e9c1"; }

.ori-ant-design-container .anticon-skype:before {
  content: "\e9c0"; }

.ori-ant-design-container .anticon-qq:before {
  content: "\e9bf"; }

.ori-ant-design-container .anticon-medium-workmark:before {
  content: "\e9be"; }

.ori-ant-design-container .anticon-gitlab:before {
  content: "\e9bd"; }

.ori-ant-design-container .anticon-medium:before {
  content: "\e9bc"; }

.ori-ant-design-container .anticon-linkedin:before {
  content: "\e9bb"; }

.ori-ant-design-container .anticon-google-plus:before {
  content: "\e9ba"; }

.ori-ant-design-container .anticon-dropbox:before {
  content: "\e9b9"; }

.ori-ant-design-container .anticon-facebook:before {
  content: "\e9b8"; }

.ori-ant-design-container .anticon-codepen:before {
  content: "\e9b7"; }

.ori-ant-design-container .anticon-amazon:before {
  content: "\e9b6"; }

.ori-ant-design-container .anticon-google:before {
  content: "\e9b5"; }

.ori-ant-design-container .anticon-codepen-circle:before {
  content: "\e9b4"; }

.ori-ant-design-container .anticon-alipay:before {
  content: "\e9b3"; }

.ori-ant-design-container .anticon-ant-design:before {
  content: "\e9b2"; }

.ori-ant-design-container .anticon-aliyun:before {
  content: "\e9f4"; }

.ori-ant-design-container .anticon-zhihu:before {
  content: "\e703"; }

.ori-ant-design-container .anticon-file-markdown:before {
  content: "\e704"; }

.ori-ant-design-container .anticon-slack:before {
  content: "\e705"; }

.ori-ant-design-container .anticon-slack-square:before {
  content: "\e706"; }

.ori-ant-design-container .anticon-behance:before {
  content: "\e707"; }

.ori-ant-design-container .anticon-behance-square:before {
  content: "\e708"; }

.ori-ant-design-container .anticon-dribbble:before {
  content: "\e709"; }

.ori-ant-design-container .anticon-dribbble-square:before {
  content: "\e70a"; }

.ori-ant-design-container .anticon-instagram:before {
  content: "\e70b"; }

.ori-ant-design-container .anticon-yuque:before {
  content: "\e70c"; }

.ori-ant-design-container .fade-enter,
.ori-ant-design-container .fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .fade-enter.fade-enter-active,
.ori-ant-design-container .fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running; }

.ori-ant-design-container .fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .fade-enter,
.ori-ant-design-container .fade-appear {
  opacity: 0;
  animation-timing-function: linear; }

.ori-ant-design-container .fade-leave {
  animation-timing-function: linear; }

@keyframes antFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes antFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ori-ant-design-container .move-up-enter,
.ori-ant-design-container .move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-up-enter.move-up-enter-active,
.ori-ant-design-container .move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running; }

.ori-ant-design-container .move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-up-enter,
.ori-ant-design-container .move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-down-enter,
.ori-ant-design-container .move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-down-enter.move-down-enter-active,
.ori-ant-design-container .move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running; }

.ori-ant-design-container .move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-down-enter,
.ori-ant-design-container .move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-left-enter,
.ori-ant-design-container .move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-left-enter.move-left-enter-active,
.ori-ant-design-container .move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running; }

.ori-ant-design-container .move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-left-enter,
.ori-ant-design-container .move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

.ori-ant-design-container .move-right-enter,
.ori-ant-design-container .move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .move-right-enter.move-right-enter-active,
.ori-ant-design-container .move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running; }

.ori-ant-design-container .move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .move-right-enter,
.ori-ant-design-container .move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

@keyframes antMoveDownIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1; } }

@keyframes antMoveDownOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1; }
  100% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0; } }

@keyframes antMoveLeftIn {
  0% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1; } }

@keyframes antMoveLeftOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1; }
  100% {
    transform-origin: 0 0;
    transform: translateX(-100%);
    opacity: 0; } }

@keyframes antMoveRightIn {
  0% {
    opacity: 0;
    transform-origin: 0 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform-origin: 0 0;
    transform: translateX(0%); } }

@keyframes antMoveRightOut {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1; }
  100% {
    transform-origin: 0 0;
    transform: translateX(100%);
    opacity: 0; } }

@keyframes antMoveUpIn {
  0% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1; } }

@keyframes antMoveUpOut {
  0% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1; }
  100% {
    transform-origin: 0 0;
    transform: translateY(-100%);
    opacity: 0; } }

@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg); }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg); } }

.ori-ant-design-container .slide-up-enter,
.ori-ant-design-container .slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-up-enter.slide-up-enter-active,
.ori-ant-design-container .slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running; }

.ori-ant-design-container .slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-up-enter,
.ori-ant-design-container .slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-down-enter,
.ori-ant-design-container .slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-down-enter.slide-down-enter-active,
.ori-ant-design-container .slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running; }

.ori-ant-design-container .slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-down-enter,
.ori-ant-design-container .slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-left-enter,
.ori-ant-design-container .slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-left-enter.slide-left-enter-active,
.ori-ant-design-container .slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running; }

.ori-ant-design-container .slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-left-enter,
.ori-ant-design-container .slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

.ori-ant-design-container .slide-right-enter,
.ori-ant-design-container .slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .slide-right-enter.slide-right-enter-active,
.ori-ant-design-container .slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running; }

.ori-ant-design-container .slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .slide-right-enter,
.ori-ant-design-container .slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }

.ori-ant-design-container .slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@keyframes antSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1); } }

@keyframes antSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1); }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8); } }

@keyframes antSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8); }
  100% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1); } }

@keyframes antSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 100% 100%;
    transform: scaleY(1); }
  100% {
    opacity: 0;
    transform-origin: 100% 100%;
    transform: scaleY(0.8); } }

@keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1); } }

@keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleX(1); }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleX(0.8); } }

@keyframes antSlideRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8); }
  100% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1); } }

@keyframes antSlideRightOut {
  0% {
    opacity: 1;
    transform-origin: 100% 0%;
    transform: scaleX(1); }
  100% {
    opacity: 0;
    transform-origin: 100% 0%;
    transform: scaleX(0.8); } }

.ori-ant-design-container .swing-enter,
.ori-ant-design-container .swing-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .swing-enter.swing-enter-active,
.ori-ant-design-container .swing-appear.swing-appear-active {
  animation-name: antSwingIn;
  animation-play-state: running; }

@keyframes antSwingIn {
  0%,
  100% {
    transform: translateX(0); }
  20% {
    transform: translateX(-10px); }
  40% {
    transform: translateX(10px); }
  60% {
    transform: translateX(-5px); }
  80% {
    transform: translateX(5px); } }

.ori-ant-design-container .zoom-enter,
.ori-ant-design-container .zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-enter.zoom-enter-active,
.ori-ant-design-container .zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-enter,
.ori-ant-design-container .zoom-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-big-enter,
.ori-ant-design-container .zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-enter.zoom-big-enter-active,
.ori-ant-design-container .zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-big-enter,
.ori-ant-design-container .zoom-big-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-big-fast-enter,
.ori-ant-design-container .zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-big-fast-enter.zoom-big-fast-enter-active,
.ori-ant-design-container .zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-big-fast-enter,
.ori-ant-design-container .zoom-big-fast-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-up-enter,
.ori-ant-design-container .zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-up-enter.zoom-up-enter-active,
.ori-ant-design-container .zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-up-enter,
.ori-ant-design-container .zoom-up-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-down-enter,
.ori-ant-design-container .zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-down-enter.zoom-down-enter-active,
.ori-ant-design-container .zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-down-enter,
.ori-ant-design-container .zoom-down-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-left-enter,
.ori-ant-design-container .zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-left-enter.zoom-left-enter-active,
.ori-ant-design-container .zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-left-enter,
.ori-ant-design-container .zoom-left-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

.ori-ant-design-container .zoom-right-enter,
.ori-ant-design-container .zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.ori-ant-design-container .zoom-right-enter.zoom-right-enter-active,
.ori-ant-design-container .zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running; }

.ori-ant-design-container .zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none; }

.ori-ant-design-container .zoom-right-enter,
.ori-ant-design-container .zoom-right-appear {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }

.ori-ant-design-container .zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

@keyframes antZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.2); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes antZoomOut {
  0% {
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.2); } }

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(0.8); }
  100% {
    transform: scale(1); } }

@keyframes antZoomBigOut {
  0% {
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.8); } }

@keyframes antZoomUpIn {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8); }
  100% {
    transform-origin: 50% 0%;
    transform: scale(1); } }

@keyframes antZoomUpOut {
  0% {
    transform-origin: 50% 0%;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(0.8); } }

@keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8); }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1); } }

@keyframes antZoomLeftOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(0.8); } }

@keyframes antZoomRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8); }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1); } }

@keyframes antZoomRightOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(0.8); } }

@keyframes antZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8); }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1); } }

@keyframes antZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0.8); } }

.ori-ant-design-container .ant-motion-collapse {
  overflow: hidden; }

.ori-ant-design-container .ant-motion-collapse-active {
  transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-single .ant-select-selector {
  display: flex; }

.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px; }

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%; }

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s; }

@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px; } }

.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  -webkit-user-select: none;
  user-select: none; }

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none; }

.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0'; }

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px; }

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px; }

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px; }

.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px; }

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px; }

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px; }

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px; }

/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%; }

.ant-select-selection-overflow-item {
  flex: none;
  align-self: center;
  max-width: 100%; }

.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px; }

.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text; }

.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0'; }

.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px; }

.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  -webkit-user-select: none;
  user-select: none;
  margin-inline-end: 4px;
  padding-inline-start: 8px;
  padding-inline-end: 4px; }

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis; }

.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer; }

.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1; }

.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block; }

.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none; }

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block; }

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em; }

.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75); }

.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0; }

.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-inline-start: 7px; }

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s; }

.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px; }

.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden; }

.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s; }

.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 32px;
  line-height: 32px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px; }

.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 16px;
  line-height: 16px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px; }

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  margin-inline-start: 3px; }

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px; }

.ant-select-disabled .ant-select-selection-item-remove {
  display: none; }

/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer; }

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer; }

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text; }

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto; }

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed; }

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: #f5f5f5; }

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed; }

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  -webkit-appearance: none;
  appearance: none; }

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none; }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-select-selection-item {
  flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto; } }

.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto; } }

.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 50%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none; }

.ant-select-arrow > * {
  line-height: 1; }

.ant-select-arrow svg {
  display: inline-block; }

.ant-select-arrow::before {
  display: none; }

.ant-select-arrow .ant-select-arrow-icon {
  display: block; }

.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s; }

.ant-select-arrow .anticon > svg {
  vertical-align: top; }

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto; }

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed; }

.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto; }

.ant-select-clear::before {
  display: block; }

.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-select:hover .ant-select-clear {
  opacity: 1; }

.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", ;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); }

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn; }

.ant-select-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn; }

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut; }

.ant-select-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut; }

.ant-select-dropdown-hidden {
  display: none; }

.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25); }

.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25); }

.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease; }

.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default; }

.ant-select-item-option {
  display: flex; }

.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ant-select-item-option-state {
  flex: none; }

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e6f7ff; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #1890ff; }

.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-select-item-option-disabled.ant-select-item-option-selected {
  background-color: #f5f5f5; }

.ant-select-item-option-grouped {
  padding-left: 24px; }

.ant-select-lg {
  font-size: 16px; }

.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important; }

.ant-select-rtl {
  direction: rtl; }

.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px; }

.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px; }

.ant-select-dropdown-rtl {
  direction: rtl; }

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px; }

.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto; }

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto; }

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px; }

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right; }

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px; }

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px; }

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 6px; }

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px; }

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s; }

.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed; }

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25); }

.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important; }

.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%; }

.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0; }

.ant-picker-input > input::placeholder {
  color: #bfbfbf; }

.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis; }

.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important; }

.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }

.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1; }

.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important; }

.ant-picker-input > input.ant-picker-input > input-borderless,
.ant-picker-input > input.ant-picker-input > input-borderless:hover,
.ant-picker-input > input.ant-picker-input > input-borderless:focus,
.ant-picker-input > input.ant-picker-input > input-borderless-focused,
.ant-picker-input > input.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none; }

textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s; }

.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px; }

.ant-picker-input > input-sm {
  padding: 0px 7px; }

.ant-picker-input > input:focus {
  box-shadow: none; }

.ant-picker-input > input[disabled] {
  background: transparent; }

.ant-picker-input:hover .ant-picker-clear {
  opacity: 1; }

.ant-picker-input-placeholder > input {
  color: #bfbfbf; }

.ant-picker-large {
  padding: 6.5px 11px 6.5px; }

.ant-picker-large .ant-picker-input > input {
  font-size: 16px; }

.ant-picker-small {
  padding: 0px 7px 0px; }

.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none; }

.ant-picker-suffix > * {
  vertical-align: top; }

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s; }

.ant-picker-clear > * {
  vertical-align: top; }

.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45); }

.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default; }

.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45); }

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed; }

.ant-picker-range {
  position: relative;
  display: inline-flex; }

.ant-picker-range .ant-picker-clear {
  right: 11px; }

.ant-picker-range:hover .ant-picker-clear {
  opacity: 1; }

.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none; }

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1; }

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1; }

.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px; }

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px; }

.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: absolute;
  z-index: 1050; }

.ant-picker-dropdown-hidden {
  display: none; }

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  transform: rotate(-45deg); }

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  transform: rotate(135deg); }

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn; }

.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn; }

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut; }

.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut; }

.ant-picker-dropdown-range {
  padding: 6.66666667px 0; }

.ant-picker-dropdown-range-hidden {
  display: none; }

.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px; }

.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none; }

.ant-picker-ranges > li {
  display: inline-block; }

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer; }

.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px; }

.ant-picker-range-wrapper {
  display: flex; }

.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out; }

.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: ''; }

.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s; }

.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr; }

.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0; }

.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center; }

.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0; }

.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none; }

.ant-picker-panel-focused {
  border-color: #1890ff; }

.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px; }

.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0; }

.ant-picker-header > * {
  flex: none; }

.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s; }

.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px; }

.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85); }

.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px; }

.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit; }

.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px; }

.ant-picker-header-view button:hover {
  color: #1890ff; }

.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px; }

.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: ''; }

.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: ''; }

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg); }

.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg); }

.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse; }

.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400; }

.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px; }

.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer; }

.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85); }

.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: ''; }

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s; }

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5; }

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: ''; }

.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative; }

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff; }

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%; }

.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%; }

.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: ''; }

.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px; }

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff; }

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px; }

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  transition: all 0.3s;
  content: ''; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0; }

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px; }

.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%; }

.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%; }

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none; }

.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent; }

.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04); }

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25); }

.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px; }

.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px; }

.ant-picker-quarter-panel .ant-picker-content {
  height: 56px; }

.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent; }

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0; }

.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left; }

.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0; }

.ant-picker-now {
  text-align: left; }

.ant-picker-today-btn {
  color: #1890ff; }

.ant-picker-today-btn:hover {
  color: #40a9ff; }

.ant-picker-today-btn:active {
  color: #096dd9; }

.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed; }

.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px; }

.ant-picker-decade-panel .ant-picker-cell::before {
  display: none; }

.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px; }

.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px; }

.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px; }

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0; }

.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0; }

.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px; }

.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px; }

.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important; }

.ant-picker-week-panel-row td {
  transition: background 0.3s; }

.ant-picker-week-panel-row:hover td {
  background: #f5f5f5; }

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff; }

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5); }

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff; }

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff; }

.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px; }

.ant-picker-date-panel .ant-picker-content {
  width: 252px; }

.ant-picker-date-panel .ant-picker-content th {
  width: 36px; }

.ant-picker-datetime-panel {
  display: flex; }

.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0; }

.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s; }

.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3; }

.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1; }

.ant-picker-time-panel {
  width: auto;
  min-width: auto; }

.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px; }

.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s; }

.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: ''; }

.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px; }

.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0; }

.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2); }

.ant-picker-time-panel-column:hover {
  overflow-y: auto; }

.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff; }

.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed; }

/* stylelint-disable-next-line */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0; }

.ant-picker-rtl {
  direction: rtl; }

.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0; }

.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0; }

.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg); }

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px; }

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0; }

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px; }

.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right; }

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0; }

.ant-picker-panel-rtl {
  direction: rtl; }

.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg); }

.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg); }

.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px; }

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%; }

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0; }

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px; }

.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #7ec1ff;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px; }

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right; }

.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr; }

.ori-ant-design-container {
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /*
    * import ant-design component css here, when ever you use any ant design component in chatbot or chatbot-@oriserve-chatbot/message-types library.
    * also check antd folder in node_modules. if required component is dependent on other component then add other component css
    * eq: i have used table in chatbot-@oriserve-chatbot/message-types so i have used table component css. but table component is using pagination component and tooltip component so i have also added css for both pagination and tooltip even i am not using pagination any where seperately.
    */
  /*
      below class is added to fix double icons in showMessage function. (when we are using antd@3.13.1 and message is using getContainer config. we are getting double icon in message popup)

      message.config({
        duration: 2,
        maxCount: 1,
        top: 75,
        getContainer: () => node
      });
      message[type](msg);

      To fix this issue we need to either update antd or override css.
      now i am overriding css because if i will update antd, i need to update @oriserve-chatbot/message-types antd peer dependency. also i need to test pagination in Checkbox type message (checkbox was not working properly for lower version of antd)

      TODO: remove the below css whenever you will update the antd version
    */ }
  .ori-ant-design-container .ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff; }
  .ori-ant-design-container .ant-btn > .anticon {
    line-height: 1; }
  .ori-ant-design-container .ant-btn,
  .ori-ant-design-container .ant-btn:active,
  .ori-ant-design-container .ant-btn:focus {
    outline: 0; }
  .ori-ant-design-container .ant-btn:not([disabled]):hover {
    text-decoration: none; }
  .ori-ant-design-container .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn[disabled] {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-btn[disabled] > * {
    pointer-events: none; }
  .ori-ant-design-container .ant-btn-lg {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-sm {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn:hover,
  .ori-ant-design-container .ant-btn:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff; }
  .ori-ant-design-container .ant-btn:hover > a:only-child,
  .ori-ant-design-container .ant-btn:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff; }
  .ori-ant-design-container .ant-btn:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn[disabled],
  .ori-ant-design-container .ant-btn[disabled]:hover,
  .ori-ant-design-container .ant-btn[disabled]:focus,
  .ori-ant-design-container .ant-btn[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn:hover,
  .ori-ant-design-container .ant-btn:focus,
  .ori-ant-design-container .ant-btn:active {
    text-decoration: none;
    background: #fff; }
  .ori-ant-design-container .ant-btn > span {
    display: inline-block; }
  .ori-ant-design-container .ant-btn-primary {
    color: #fff;
    border-color: #1890ff;
    background: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
  .ori-ant-design-container .ant-btn-primary > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-primary:hover,
  .ori-ant-design-container .ant-btn-primary:focus {
    color: #fff;
    border-color: #40a9ff;
    background: #40a9ff; }
  .ori-ant-design-container .ant-btn-primary:hover > a:only-child,
  .ori-ant-design-container .ant-btn-primary:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-primary:active {
    color: #fff;
    border-color: #096dd9;
    background: #096dd9; }
  .ori-ant-design-container .ant-btn-primary:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-primary[disabled],
  .ori-ant-design-container .ant-btn-primary[disabled]:hover,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus,
  .ori-ant-design-container .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-primary[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-primary[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ori-ant-design-container .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-ghost {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-ghost:hover,
  .ori-ant-design-container .ant-btn-ghost:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost:hover > a:only-child,
  .ori-ant-design-container .ant-btn-ghost:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-ghost:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-ghost:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-ghost[disabled],
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-ghost[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-ghost[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dashed {
    color: rgba(0, 0, 0, 0.85);
    border-color: #d9d9d9;
    background: #fff;
    border-style: dashed; }
  .ori-ant-design-container .ant-btn-dashed > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dashed:hover,
  .ori-ant-design-container .ant-btn-dashed:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: #fff; }
  .ori-ant-design-container .ant-btn-dashed:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dashed:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dashed:active {
    color: #096dd9;
    border-color: #096dd9;
    background: #fff; }
  .ori-ant-design-container .ant-btn-dashed:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dashed[disabled],
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dashed[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dashed[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-danger {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
  .ori-ant-design-container .ant-btn-danger > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-danger:hover,
  .ori-ant-design-container .ant-btn-danger:focus {
    color: #fff;
    border-color: #ff7875;
    background: #ff7875; }
  .ori-ant-design-container .ant-btn-danger:hover > a:only-child,
  .ori-ant-design-container .ant-btn-danger:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-danger:active {
    color: #fff;
    border-color: #d9363e;
    background: #d9363e; }
  .ori-ant-design-container .ant-btn-danger:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-danger[disabled],
  .ori-ant-design-container .ant-btn-danger[disabled]:hover,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus,
  .ori-ant-design-container .ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-danger[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-danger[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-link {
    color: #1890ff;
    border-color: transparent;
    background: transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-link > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-link:hover,
  .ori-ant-design-container .ant-btn-link:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent; }
  .ori-ant-design-container .ant-btn-link:hover > a:only-child,
  .ori-ant-design-container .ant-btn-link:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-link:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-link:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-link:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-link[disabled],
  .ori-ant-design-container .ant-btn-link[disabled]:hover,
  .ori-ant-design-container .ant-btn-link[disabled]:focus,
  .ori-ant-design-container .ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-link[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-link[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-link:hover {
    background: transparent; }
  .ori-ant-design-container .ant-btn-link:hover,
  .ori-ant-design-container .ant-btn-link:focus,
  .ori-ant-design-container .ant-btn-link:active {
    border-color: transparent; }
  .ori-ant-design-container .ant-btn-link[disabled],
  .ori-ant-design-container .ant-btn-link[disabled]:hover,
  .ori-ant-design-container .ant-btn-link[disabled]:focus,
  .ori-ant-design-container .ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-link[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-link[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-text {
    color: rgba(0, 0, 0, 0.85);
    border-color: transparent;
    background: transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-text > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-text:hover,
  .ori-ant-design-container .ant-btn-text:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent; }
  .ori-ant-design-container .ant-btn-text:hover > a:only-child,
  .ori-ant-design-container .ant-btn-text:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-text:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-text:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-text:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-text[disabled],
  .ori-ant-design-container .ant-btn-text[disabled]:hover,
  .ori-ant-design-container .ant-btn-text[disabled]:focus,
  .ori-ant-design-container .ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-text[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-text[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-text:hover,
  .ori-ant-design-container .ant-btn-text:focus {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.018);
    border-color: transparent; }
  .ori-ant-design-container .ant-btn-text:active {
    color: rgba(0, 0, 0, 0.85);
    background: rgba(0, 0, 0, 0.028);
    border-color: transparent; }
  .ori-ant-design-container .ant-btn-text[disabled],
  .ori-ant-design-container .ant-btn-text[disabled]:hover,
  .ori-ant-design-container .ant-btn-text[disabled]:focus,
  .ori-ant-design-container .ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-text[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-text[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    background: #fff; }
  .ori-ant-design-container .ant-btn-dangerous > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous:hover,
  .ori-ant-design-container .ant-btn-dangerous:focus {
    color: #ff7875;
    border-color: #ff7875;
    background: #fff; }
  .ori-ant-design-container .ant-btn-dangerous:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous:active {
    color: #d9363e;
    border-color: #d9363e;
    background: #fff; }
  .ori-ant-design-container .ant-btn-dangerous:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous[disabled],
  .ori-ant-design-container .ant-btn-dangerous[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    border-color: #ff4d4f;
    background: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    border-color: #ff7875;
    background: #ff7875; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    border-color: #d9363e;
    background: #d9363e; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled],
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled],
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    border-color: transparent;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    border-color: transparent;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled],
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text {
    color: #ff4d4f;
    border-color: transparent;
    background: transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus {
    color: #40a9ff;
    border-color: #40a9ff;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active {
    color: #096dd9;
    border-color: #096dd9;
    background: transparent; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled],
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus {
    color: #ff7875;
    border-color: transparent;
    background: rgba(0, 0, 0, 0.018); }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active {
    color: #d9363e;
    border-color: transparent;
    background: rgba(0, 0, 0, 0.028); }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled],
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: transparent;
    background: transparent;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-icon-only {
    width: 32px;
    height: 32px;
    padding: 2.4px 0;
    font-size: 16px;
    border-radius: 2px;
    vertical-align: -1px; }
  .ori-ant-design-container .ant-btn-icon-only > * {
    font-size: 16px; }
  .ori-ant-design-container .ant-btn-icon-only.ant-btn-lg {
    width: 40px;
    height: 40px;
    padding: 4.9px 0;
    font-size: 18px;
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px; }
  .ori-ant-design-container .ant-btn-icon-only.ant-btn-sm {
    width: 24px;
    height: 24px;
    padding: 0px 0;
    font-size: 14px;
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px; }
  .ori-ant-design-container .ant-btn-round {
    height: 32px;
    padding: 4px 16px;
    font-size: 14px;
    border-radius: 32px; }
  .ori-ant-design-container .ant-btn-round.ant-btn-lg {
    height: 40px;
    padding: 6.4px 20px;
    font-size: 16px;
    border-radius: 40px; }
  .ori-ant-design-container .ant-btn-round.ant-btn-sm {
    height: 24px;
    padding: 0px 12px;
    font-size: 14px;
    border-radius: 24px; }
  .ori-ant-design-container .ant-btn-round.ant-btn-icon-only {
    width: auto; }
  .ori-ant-design-container .ant-btn-circle {
    min-width: 32px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%; }
  .ori-ant-design-container .ant-btn-circle.ant-btn-lg {
    min-width: 40px;
    border-radius: 50%; }
  .ori-ant-design-container .ant-btn-circle.ant-btn-sm {
    min-width: 24px;
    border-radius: 50%; }
  .ori-ant-design-container .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.35;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none; }
  .ori-ant-design-container .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-btn .anticon.anticon-plus > svg,
  .ori-ant-design-container .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed; }
  .ori-ant-design-container .ant-btn.ant-btn-loading {
    position: relative; }
  .ori-ant-design-container .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none; }
  .ori-ant-design-container .ant-btn.ant-btn-loading::before {
    display: block; }
  .ori-ant-design-container .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px;
    animation: none; }
  .ori-ant-design-container .ant-btn > .ant-btn-loading-icon .anticon svg {
    animation: loadingCircle 1s infinite linear; }
  .ori-ant-design-container .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0; }
  .ori-ant-design-container .ant-btn-group {
    position: relative;
    display: inline-flex; }
  .ori-ant-design-container .ant-btn-group > .ant-btn,
  .ori-ant-design-container .ant-btn-group > span > .ant-btn {
    position: relative; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:hover,
  .ori-ant-design-container .ant-btn-group > span > .ant-btn:hover,
  .ori-ant-design-container .ant-btn-group > .ant-btn:focus,
  .ori-ant-design-container .ant-btn-group > span > .ant-btn:focus,
  .ori-ant-design-container .ant-btn-group > .ant-btn:active,
  .ori-ant-design-container .ant-btn-group > span > .ant-btn:active {
    z-index: 2; }
  .ori-ant-design-container .ant-btn-group > .ant-btn[disabled],
  .ori-ant-design-container .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0; }
  .ori-ant-design-container .ant-btn-group .ant-btn-icon-only {
    font-size: 14px; }
  .ori-ant-design-container .ant-btn-group-lg > .ant-btn,
  .ori-ant-design-container .ant-btn-group-lg > span > .ant-btn {
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    width: 40px;
    height: 40px;
    padding-right: 0;
    padding-left: 0; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn,
  .ori-ant-design-container .ant-btn-group-sm > span > .ant-btn {
    height: 24px;
    padding: 0px 7px;
    font-size: 14px;
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn > .anticon,
  .ori-ant-design-container .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px; }
  .ori-ant-design-container .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    width: 24px;
    height: 24px;
    padding-right: 0;
    padding-left: 0; }
  .ori-ant-design-container .ant-btn-group .ant-btn + .ant-btn,
  .ori-ant-design-container .ant-btn + .ant-btn-group,
  .ori-ant-design-container .ant-btn-group span + .ant-btn,
  .ori-ant-design-container .ant-btn-group .ant-btn + span,
  .ori-ant-design-container .ant-btn-group > span + span,
  .ori-ant-design-container .ant-btn-group + .ant-btn,
  .ori-ant-design-container .ant-btn-group + .ant-btn-group {
    margin-left: -1px; }
  .ori-ant-design-container .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent; }
  .ori-ant-design-container .ant-btn-group .ant-btn {
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:first-child,
  .ori-ant-design-container .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:only-child {
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ori-ant-design-container .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 2px; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ori-ant-design-container .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group {
    float: left; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ori-ant-design-container .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group span + .ant-btn,
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group > span + span,
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ori-ant-design-container .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group > span + span,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
    margin-right: -1px;
    margin-left: auto; }
  .ori-ant-design-container .ant-btn-group.ant-btn-group-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-btn:focus > span,
  .ori-ant-design-container .ant-btn:active > span {
    position: relative; }
  .ori-ant-design-container .ant-btn > .anticon + span,
  .ori-ant-design-container .ant-btn > span + .anticon {
    margin-left: 8px; }
  .ori-ant-design-container .ant-btn-background-ghost {
    color: #fff;
    border-color: #fff; }
  .ori-ant-design-container .ant-btn-background-ghost,
  .ori-ant-design-container .ant-btn-background-ghost:hover,
  .ori-ant-design-container .ant-btn-background-ghost:active,
  .ori-ant-design-container .ant-btn-background-ghost:focus {
    background: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary {
    color: #1890ff;
    border-color: #1890ff;
    text-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #40a9ff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active {
    color: #096dd9;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #ff7875;
    border-color: #ff7875; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active {
    color: #d9363e;
    border-color: #d9363e; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous {
    color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #ff7875;
    border-color: #ff7875; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #d9363e;
    border-color: #d9363e; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #ff4d4f;
    border-color: transparent;
    text-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #ff7875;
    border-color: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #d9363e;
    border-color: transparent; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor; }
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ori-ant-design-container .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: ''; }
  .ori-ant-design-container .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em; }
  .ori-ant-design-container .ant-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em; }
  .ori-ant-design-container .ant-btn-block {
    width: 100%; }
  .ori-ant-design-container .ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0'; }
  .ori-ant-design-container a.ant-btn {
    padding-top: 0.01px !important;
    line-height: 30px; }
  .ori-ant-design-container a.ant-btn-lg {
    line-height: 38px; }
  .ori-ant-design-container a.ant-btn-sm {
    line-height: 22px; }
  .ori-ant-design-container .ant-btn-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: #40a9ff;
    border-left-color: #d9d9d9; }
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ori-ant-design-container .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #d9d9d9;
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 8px; }
  .ori-ant-design-container .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
    padding-left: 0; }
  .ori-ant-design-container .ant-btn-rtl.ant-btn > .anticon + span,
  .ori-ant-design-container .ant-btn-rtl.ant-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0; }
  .ori-ant-design-container .ant-badge {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    line-height: 1; }
  .ori-ant-design-container .ant-badge-count {
    z-index: auto;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;
    color: #fff;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
    background: #ff4d4f;
    border-radius: 10px;
    box-shadow: 0 0 0 1px #fff; }
  .ori-ant-design-container .ant-badge-count a,
  .ori-ant-design-container .ant-badge-count a:hover {
    color: #fff; }
  .ori-ant-design-container .ant-badge-count-sm {
    min-width: 14px;
    height: 14px;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
    border-radius: 7px; }
  .ori-ant-design-container .ant-badge-multiple-words {
    padding: 0 8px; }
  .ori-ant-design-container .ant-badge-dot {
    z-index: auto;
    width: 6px;
    min-width: 6px;
    height: 6px;
    background: #ff4d4f;
    border-radius: 100%;
    box-shadow: 0 0 0 1px #fff; }
  .ori-ant-design-container .ant-badge-dot.ant-scroll-number {
    transition: background 1.5s; }
  .ori-ant-design-container .ant-badge-count,
  .ori-ant-design-container .ant-badge-dot,
  .ori-ant-design-container .ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%; }
  .ori-ant-design-container .ant-badge-count.anticon-spin,
  .ori-ant-design-container .ant-badge-dot.anticon-spin,
  .ori-ant-design-container .ant-badge .ant-scroll-number-custom-component.anticon-spin {
    animation: antBadgeLoadingCircle 1s infinite linear; }
  .ori-ant-design-container .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline; }
  .ori-ant-design-container .ant-badge-status-dot {
    position: relative;
    top: -1px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%; }
  .ori-ant-design-container .ant-badge-status-success {
    background-color: #52c41a; }
  .ori-ant-design-container .ant-badge-status-processing {
    position: relative;
    background-color: #1890ff; }
  .ori-ant-design-container .ant-badge-status-processing::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    animation: antStatusProcessing 1.2s infinite ease-in-out;
    content: ''; }
  .ori-ant-design-container .ant-badge-status-default {
    background-color: #d9d9d9; }
  .ori-ant-design-container .ant-badge-status-error {
    background-color: #ff4d4f; }
  .ori-ant-design-container .ant-badge-status-warning {
    background-color: #faad14; }
  .ori-ant-design-container .ant-badge-status-pink {
    background: #eb2f96; }
  .ori-ant-design-container .ant-badge-status-magenta {
    background: #eb2f96; }
  .ori-ant-design-container .ant-badge-status-red {
    background: #f5222d; }
  .ori-ant-design-container .ant-badge-status-volcano {
    background: #fa541c; }
  .ori-ant-design-container .ant-badge-status-orange {
    background: #fa8c16; }
  .ori-ant-design-container .ant-badge-status-yellow {
    background: #fadb14; }
  .ori-ant-design-container .ant-badge-status-gold {
    background: #faad14; }
  .ori-ant-design-container .ant-badge-status-cyan {
    background: #13c2c2; }
  .ori-ant-design-container .ant-badge-status-lime {
    background: #a0d911; }
  .ori-ant-design-container .ant-badge-status-green {
    background: #52c41a; }
  .ori-ant-design-container .ant-badge-status-blue {
    background: #1890ff; }
  .ori-ant-design-container .ant-badge-status-geekblue {
    background: #2f54eb; }
  .ori-ant-design-container .ant-badge-status-purple {
    background: #722ed1; }
  .ori-ant-design-container .ant-badge-status-text {
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px; }
  .ori-ant-design-container .ant-badge-zoom-appear,
  .ori-ant-design-container .ant-badge-zoom-enter {
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation-fill-mode: both; }
  .ori-ant-design-container .ant-badge-zoom-leave {
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation-fill-mode: both; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-badge-zoom-appear,
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-badge-zoom-enter {
    animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46); }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-badge-zoom-leave {
    animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6); }
  .ori-ant-design-container .ant-badge-not-a-wrapper:not(.ant-badge-status) {
    vertical-align: middle; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
    transform: none; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-scroll-number-custom-component,
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-scroll-number {
    position: relative;
    top: auto;
    display: block;
    transform-origin: 50% 50%; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-badge-count {
    transform: none; }

@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5; }
  100% {
    transform: scale(2.4);
    opacity: 0; } }
  .ori-ant-design-container .ant-scroll-number {
    overflow: hidden; }
  .ori-ant-design-container .ant-scroll-number-only {
    position: relative;
    display: inline-block;
    height: 20px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; }
  .ori-ant-design-container .ant-scroll-number-only > p.ant-scroll-number-only-unit {
    height: 20px;
    margin: 0;
    -webkit-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden; }
  .ori-ant-design-container .ant-scroll-number-symbol {
    vertical-align: top; }

@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0; }
  100% {
    transform: scale(1) translate(50%, -50%); } }

@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%); }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0; } }

@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1); } }

@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0);
    opacity: 0; } }

@keyframes antBadgeLoadingCircle {
  0% {
    transform-origin: 50%; }
  100% {
    transform: translate(50%, -50%) rotate(360deg);
    transform-origin: 50%; } }
  .ori-ant-design-container .ant-ribbon-wrapper {
    position: relative; }
  .ori-ant-design-container .ant-ribbon {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 8px;
    height: 22px;
    padding: 0 8px;
    color: #fff;
    line-height: 22px;
    white-space: nowrap;
    background-color: #1890ff;
    border-radius: 2px; }
  .ori-ant-design-container .ant-ribbon-text {
    color: #fff; }
  .ori-ant-design-container .ant-ribbon-corner {
    position: absolute;
    top: 100%;
    width: 8px;
    height: 8px;
    color: currentColor;
    border: 4px solid;
    transform: scaleY(0.75);
    transform-origin: top; }
  .ori-ant-design-container .ant-ribbon-corner::after {
    position: absolute;
    top: -4px;
    left: -4px;
    width: inherit;
    height: inherit;
    color: rgba(0, 0, 0, 0.25);
    border: inherit;
    content: ''; }
  .ori-ant-design-container .ant-ribbon-color-pink {
    color: #eb2f96;
    background: #eb2f96; }
  .ori-ant-design-container .ant-ribbon-color-magenta {
    color: #eb2f96;
    background: #eb2f96; }
  .ori-ant-design-container .ant-ribbon-color-red {
    color: #f5222d;
    background: #f5222d; }
  .ori-ant-design-container .ant-ribbon-color-volcano {
    color: #fa541c;
    background: #fa541c; }
  .ori-ant-design-container .ant-ribbon-color-orange {
    color: #fa8c16;
    background: #fa8c16; }
  .ori-ant-design-container .ant-ribbon-color-yellow {
    color: #fadb14;
    background: #fadb14; }
  .ori-ant-design-container .ant-ribbon-color-gold {
    color: #faad14;
    background: #faad14; }
  .ori-ant-design-container .ant-ribbon-color-cyan {
    color: #13c2c2;
    background: #13c2c2; }
  .ori-ant-design-container .ant-ribbon-color-lime {
    color: #a0d911;
    background: #a0d911; }
  .ori-ant-design-container .ant-ribbon-color-green {
    color: #52c41a;
    background: #52c41a; }
  .ori-ant-design-container .ant-ribbon-color-blue {
    color: #1890ff;
    background: #1890ff; }
  .ori-ant-design-container .ant-ribbon-color-geekblue {
    color: #2f54eb;
    background: #2f54eb; }
  .ori-ant-design-container .ant-ribbon-color-purple {
    color: #722ed1;
    background: #722ed1; }
  .ori-ant-design-container .ant-ribbon.ant-ribbon-placement-end {
    right: -8px;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
    right: 0;
    border-color: currentColor transparent transparent currentColor; }
  .ori-ant-design-container .ant-ribbon.ant-ribbon-placement-start {
    left: -8px;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
    left: 0;
    border-color: currentColor currentColor transparent transparent; }
  .ori-ant-design-container .ant-badge-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-badge-rtl .ant-badge-count,
  .ori-ant-design-container .ant-badge-rtl .ant-badge-dot,
  .ori-ant-design-container .ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
    right: auto;
    left: 0;
    direction: ltr;
    transform: translate(-50%, -50%);
    transform-origin: 0% 0%; }
  .ori-ant-design-container .ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
    right: auto;
    left: 0;
    transform: translate(-50%, -50%);
    transform-origin: 0% 0%; }
  .ori-ant-design-container .ant-badge-rtl .ant-badge-status-text {
    margin-right: 8px;
    margin-left: 0; }
  .ori-ant-design-container .ant-badge-rtl .ant-badge-zoom-appear,
  .ori-ant-design-container .ant-badge-rtl .ant-badge-zoom-enter {
    animation-name: antZoomBadgeInRtl; }
  .ori-ant-design-container .ant-badge-rtl .ant-badge-zoom-leave {
    animation-name: antZoomBadgeOutRtl; }
  .ori-ant-design-container .ant-badge-not-a-wrapper .ant-badge-count {
    transform: none; }
  .ori-ant-design-container .ant-ribbon-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-end {
    right: unset;
    left: -8px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
    right: unset;
    left: 0;
    border-color: currentColor currentColor transparent transparent; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
    border-color: currentColor currentColor transparent transparent; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-start {
    right: -8px;
    left: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
    right: 0;
    left: unset;
    border-color: currentColor transparent transparent currentColor; }
  .ori-ant-design-container .ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
    border-color: currentColor transparent transparent currentColor; }

@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; }
  100% {
    transform: scale(1) translate(-50%, -50%); } }

@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%); }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0; } }
  .ori-ant-design-container .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
    display: inline-flex; }
  .ori-ant-design-container .ant-input-affix-wrapper::placeholder {
    color: #bfbfbf; }
  .ori-ant-design-container .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-input-affix-wrapper:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important; }
  .ori-ant-design-container .ant-input-affix-wrapper:focus,
  .ori-ant-design-container .ant-input-affix-wrapper-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-input-rtl .ant-input-affix-wrapper:focus,
  .ori-ant-design-container .ant-input-rtl .ant-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important; }
  .ori-ant-design-container .ant-input-affix-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1; }
  .ori-ant-design-container .ant-input-affix-wrapper-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input-affix-wrapper[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1; }
  .ori-ant-design-container .ant-input-affix-wrapper[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless,
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless:hover,
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless:focus,
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless-focused,
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless-disabled,
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none; }
  .ori-ant-design-container textarea.ant-input-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s; }
  .ori-ant-design-container .ant-input-affix-wrapper-lg {
    padding: 6.5px 11px;
    font-size: 16px; }
  .ori-ant-design-container .ant-input-affix-wrapper-sm {
    padding: 0px 7px; }
  .ori-ant-design-container .ant-input-affix-wrapper-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    z-index: 1; }
  .ori-ant-design-container .ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important; }
  .ori-ant-design-container .ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0; }
  .ori-ant-design-container .ant-input-affix-wrapper-focused,
  .ori-ant-design-container .ant-input-affix-wrapper:focus {
    z-index: 1; }
  .ori-ant-design-container .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent; }
  .ori-ant-design-container .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    border: none;
    outline: none; }
  .ori-ant-design-container .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none !important; }
  .ori-ant-design-container .ant-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0'; }
  .ori-ant-design-container .ant-input-prefix,
  .ori-ant-design-container .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center; }
  .ori-ant-design-container .ant-input-prefix {
    margin-right: 4px; }
  .ori-ant-design-container .ant-input-suffix {
    margin-left: 4px; }
  .ori-ant-design-container .anticon.ant-input-clear-icon {
    margin: 0 4px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    vertical-align: -1px;
    cursor: pointer;
    transition: color 0.3s; }
  .ori-ant-design-container .anticon.ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .anticon.ant-input-clear-icon:active {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .anticon.ant-input-clear-icon-hidden {
    visibility: hidden; }
  .ori-ant-design-container .anticon.ant-input-clear-icon:last-child {
    margin-right: 0; }
  .ori-ant-design-container .ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important; }
  .ori-ant-design-container .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1; }
  .ori-ant-design-container .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-input::placeholder {
    color: #bfbfbf; }
  .ori-ant-design-container .ant-input:placeholder-shown {
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important; }
  .ori-ant-design-container .ant-input:focus,
  .ori-ant-design-container .ant-input-focused {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-input-rtl .ant-input:focus,
  .ori-ant-design-container .ant-input-rtl .ant-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important; }
  .ori-ant-design-container .ant-input-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1; }
  .ori-ant-design-container .ant-input-disabled:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1; }
  .ori-ant-design-container .ant-input[disabled]:hover {
    border-color: #d9d9d9;
    border-right-width: 1px !important; }
  .ori-ant-design-container .ant-input.ant-input-borderless,
  .ori-ant-design-container .ant-input.ant-input-borderless:hover,
  .ori-ant-design-container .ant-input.ant-input-borderless:focus,
  .ori-ant-design-container .ant-input.ant-input-borderless-focused,
  .ori-ant-design-container .ant-input.ant-input-borderless-disabled,
  .ori-ant-design-container .ant-input.ant-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none; }
  .ori-ant-design-container textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s; }
  .ori-ant-design-container .ant-input-lg {
    padding: 6.5px 11px;
    font-size: 16px; }
  .ori-ant-design-container .ant-input-sm {
    padding: 0px 7px; }
  .ori-ant-design-container .ant-input-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0; }
  .ori-ant-design-container .ant-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0; }
  .ori-ant-design-container .ant-input-group > [class*='col-'] {
    padding-right: 8px; }
  .ori-ant-design-container .ant-input-group > [class*='col-']:last-child {
    padding-right: 0; }
  .ori-ant-design-container .ant-input-group-addon,
  .ori-ant-design-container .ant-input-group-wrap,
  .ori-ant-design-container .ant-input-group > .ant-input {
    display: table-cell; }
  .ori-ant-design-container .ant-input-group-addon:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group-addon,
  .ori-ant-design-container .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle; }
  .ori-ant-design-container .ant-input-group-wrap > * {
    display: block !important; }
  .ori-ant-design-container .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit; }
  .ori-ant-design-container .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px; }
  .ori-ant-design-container .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px; }
  .ori-ant-design-container .ant-input-search-with-button .ant-input-group .ant-input:hover {
    z-index: 0; }
  .ori-ant-design-container .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-input-group-addon .ant-select {
    margin: -5px -11px; }
  .ori-ant-design-container .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none; }
  .ori-ant-design-container .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ori-ant-design-container .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #1890ff; }
  .ori-ant-design-container .ant-input-group-addon .ant-cascader-picker {
    margin: -9px -12px;
    background-color: transparent; }
  .ori-ant-design-container .ant-input-group-addon .ant-cascader-picker .ant-cascader-input {
    text-align: left;
    border: 0;
    box-shadow: none; }
  .ori-ant-design-container .ant-input-group > .ant-input:first-child,
  .ori-ant-design-container .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
  .ori-ant-design-container .ant-input-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-input-group-addon:first-child {
    border-right: 0; }
  .ori-ant-design-container .ant-input-group-addon:last-child {
    border-left: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:last-child,
  .ori-ant-design-container .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
  .ori-ant-design-container .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-input-group-lg .ant-input,
  .ori-ant-design-container .ant-input-group-lg > .ant-input-group-addon {
    padding: 6.5px 11px;
    font-size: 16px; }
  .ori-ant-design-container .ant-input-group-sm .ant-input,
  .ori-ant-design-container .ant-input-group-sm > .ant-input-group-addon {
    padding: 0px 7px; }
  .ori-ant-design-container .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 40px; }
  .ori-ant-design-container .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 24px; }
  .ori-ant-design-container .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .ori-ant-design-container .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ori-ant-design-container .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact {
    display: block; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: ''; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: ''; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
    display: inline-flex; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: inline-flex; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input {
    float: none; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-focused {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
    z-index: 1; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > *:first-child,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > *:last-child,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
    margin-left: -1px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
    border-radius: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-input-group > .ant-input-rtl:first-child,
  .ori-ant-design-container .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #d9d9d9;
    border-left: 0; }
  .ori-ant-design-container .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #d9d9d9; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group > .ant-input:last-child,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group-addon:last-child {
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ori-ant-design-container .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-left-width: 1px;
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
    margin-right: -1px;
    margin-left: 0; }
  .ori-ant-design-container .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top; }
  .ori-ant-design-container .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-input-password-icon:hover {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-input[type='color'] {
    height: 32px; }
  .ori-ant-design-container .ant-input[type='color'].ant-input-lg {
    height: 40px; }
  .ori-ant-design-container .ant-input[type='color'].ant-input-sm {
    height: 24px;
    padding-top: 3px;
    padding-bottom: 3px; }
  .ori-ant-design-container .ant-input-textarea-show-count::after {
    float: right;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none; }
  .ori-ant-design-container .ant-input-search .ant-input:hover,
  .ori-ant-design-container .ant-input-search .ant-input:focus {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ori-ant-design-container .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #40a9ff; }
  .ori-ant-design-container .ant-input-search .ant-input-affix-wrapper {
    border-radius: 0; }
  .ori-ant-design-container .ant-input-search .ant-input-lg {
    line-height: 1.5713; }
  .ori-ant-design-container .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -1px;
    padding: 0;
    border: 0; }
  .ori-ant-design-container .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .ori-ant-design-container .ant-input-search-button {
    height: 32px; }
  .ori-ant-design-container .ant-input-search-button:hover,
  .ori-ant-design-container .ant-input-search-button:focus {
    z-index: 1; }
  .ori-ant-design-container .ant-input-search-large .ant-input-search-button {
    height: 40px; }
  .ori-ant-design-container .ant-input-search-small .ant-input-search-button {
    height: 24px; }
  .ori-ant-design-container .ant-input-group-wrapper-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-group-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
    outline: none; }
  .ori-ant-design-container .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 4px; }
  .ori-ant-design-container .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 4px 0 0; }
  .ori-ant-design-container .ant-input-textarea-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-textarea-rtl.ant-input-textarea-show-count::after {
    text-align: left; }
  .ori-ant-design-container .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-right: 4px;
    margin-left: 0; }
  .ori-ant-design-container .ant-input-affix-wrapper-rtl .ant-input-clear-icon {
    right: auto;
    left: 8px; }
  .ori-ant-design-container .ant-input-search-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ori-ant-design-container .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-right-color: #40a9ff;
    border-left-color: #d9d9d9; }
  .ori-ant-design-container .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
  .ori-ant-design-container .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
    border-right-color: #40a9ff; }
  .ori-ant-design-container .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
    right: -1px;
    left: auto; }
  .ori-ant-design-container .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
    border-radius: 2px 0 0 2px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ori-ant-design-container .ant-input {
      height: 32px; }
    .ori-ant-design-container .ant-input-lg {
      height: 40px; }
    .ori-ant-design-container .ant-input-sm {
      height: 24px; }
    .ori-ant-design-container .ant-input-affix-wrapper > input.ant-input {
      height: auto; } }
  .ori-ant-design-container .ant-carousel {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum"; }
  .ori-ant-design-container .ant-carousel .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    touch-action: pan-y;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
  .ori-ant-design-container .ant-carousel .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden; }
  .ori-ant-design-container .ant-carousel .slick-list:focus {
    outline: none; }
  .ori-ant-design-container .ant-carousel .slick-list.dragging {
    cursor: pointer; }
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide {
    pointer-events: none; }
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide input.ant-radio-input,
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
    visibility: hidden; }
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto; }
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
    visibility: visible; }
  .ori-ant-design-container .ant-carousel .slick-list .slick-slide > div > div {
    vertical-align: bottom; }
  .ori-ant-design-container .ant-carousel .slick-slider .slick-track,
  .ori-ant-design-container .ant-carousel .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
    touch-action: pan-y; }
  .ori-ant-design-container .ant-carousel .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-track::before,
  .ori-ant-design-container .ant-carousel .slick-track::after {
    display: table;
    content: ''; }
  .ori-ant-design-container .ant-carousel .slick-track::after {
    clear: both; }
  .ori-ant-design-container .slick-loading .ant-carousel .slick-track {
    visibility: hidden; }
  .ori-ant-design-container .ant-carousel .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px; }
  .ori-ant-design-container .ant-carousel .slick-slide img {
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-slide.slick-loading img {
    display: none; }
  .ori-ant-design-container .ant-carousel .slick-slide.dragging img {
    pointer-events: none; }
  .ori-ant-design-container .ant-carousel .slick-initialized .slick-slide {
    display: block; }
  .ori-ant-design-container .ant-carousel .slick-loading .slick-slide {
    visibility: hidden; }
  .ori-ant-design-container .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto; }
  .ori-ant-design-container .ant-carousel .slick-arrow.slick-hidden {
    display: none; }
  .ori-ant-design-container .ant-carousel .slick-prev,
  .ori-ant-design-container .ant-carousel .slick-next {
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer; }
  .ori-ant-design-container .ant-carousel .slick-prev:hover,
  .ori-ant-design-container .ant-carousel .slick-next:hover,
  .ori-ant-design-container .ant-carousel .slick-prev:focus,
  .ori-ant-design-container .ant-carousel .slick-next:focus {
    color: transparent;
    background: transparent;
    outline: none; }
  .ori-ant-design-container .ant-carousel .slick-prev:hover::before,
  .ori-ant-design-container .ant-carousel .slick-next:hover::before,
  .ori-ant-design-container .ant-carousel .slick-prev:focus::before,
  .ori-ant-design-container .ant-carousel .slick-next:focus::before {
    opacity: 1; }
  .ori-ant-design-container .ant-carousel .slick-prev.slick-disabled::before,
  .ori-ant-design-container .ant-carousel .slick-next.slick-disabled::before {
    opacity: 0.25; }
  .ori-ant-design-container .ant-carousel .slick-prev {
    left: -25px; }
  .ori-ant-design-container .ant-carousel .slick-prev::before {
    content: '←'; }
  .ori-ant-design-container .ant-carousel .slick-next {
    right: -25px; }
  .ori-ant-design-container .ant-carousel .slick-next::before {
    content: '→'; }
  .ori-ant-design-container .ant-carousel .slick-dots {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex !important;
    justify-content: center;
    margin-right: 15%;
    margin-left: 15%;
    padding-left: 0;
    list-style: none; }
  .ori-ant-design-container .ant-carousel .slick-dots-bottom {
    bottom: 12px; }
  .ori-ant-design-container .ant-carousel .slick-dots-top {
    top: 12px;
    bottom: auto; }
  .ori-ant-design-container .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 16px;
    height: 3px;
    margin: 0 2px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    text-align: center;
    text-indent: -999px;
    vertical-align: top;
    transition: all 0.5s; }
  .ori-ant-design-container .ant-carousel .slick-dots li button {
    display: block;
    width: 100%;
    height: 3px;
    padding: 0;
    color: transparent;
    font-size: 0;
    background: #fff;
    border: 0;
    border-radius: 1px;
    outline: none;
    cursor: pointer;
    opacity: 0.3;
    transition: all 0.5s; }
  .ori-ant-design-container .ant-carousel .slick-dots li button:hover,
  .ori-ant-design-container .ant-carousel .slick-dots li button:focus {
    opacity: 0.75; }
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active {
    width: 24px; }
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1; }
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active:hover,
  .ori-ant-design-container .ant-carousel .slick-dots li.slick-active:focus {
    opacity: 1; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots {
    top: 50%;
    bottom: auto;
    flex-direction: column;
    width: 3px;
    height: auto;
    margin: 0;
    transform: translateY(-50%); }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots-left {
    right: auto;
    left: 12px; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots-right {
    right: 12px;
    left: auto; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li {
    width: 3px;
    height: 16px;
    margin: 4px 2px;
    vertical-align: baseline; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li.slick-active {
    width: 3px;
    height: 24px; }
  .ori-ant-design-container .ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px; }
  .ori-ant-design-container .ant-carousel-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-carousel-rtl .ant-carousel .slick-track {
    right: 0;
    left: auto; }
  .ori-ant-design-container .ant-carousel-rtl .ant-carousel .slick-prev {
    right: -25px;
    left: auto; }
  .ori-ant-design-container .ant-carousel-rtl .ant-carousel .slick-prev::before {
    content: '→'; }
  .ori-ant-design-container .ant-carousel-rtl .ant-carousel .slick-next {
    right: auto;
    left: -25px; }
  .ori-ant-design-container .ant-carousel-rtl .ant-carousel .slick-next::before {
    content: '←'; }
  .ori-ant-design-container .ant-carousel-rtl.ant-carousel .slick-dots {
    flex-direction: row-reverse; }
  .ori-ant-design-container .ant-carousel-rtl.ant-carousel-vertical .slick-dots {
    flex-direction: column; }
  .ori-ant-design-container .ant-upload {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    outline: 0; }
  .ori-ant-design-container .ant-upload p {
    margin: 0; }
  .ori-ant-design-container .ant-upload-btn {
    display: block;
    width: 100%;
    outline: none; }
  .ori-ant-design-container .ant-upload input[type='file'] {
    cursor: pointer; }
  .ori-ant-design-container .ant-upload.ant-upload-select {
    display: inline-block; }
  .ori-ant-design-container .ant-upload.ant-upload-disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card > .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center; }
  .ori-ant-design-container .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff; }
  .ori-ant-design-container .ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0; }
  .ori-ant-design-container .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border-color: #096dd9; }
  .ori-ant-design-container .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle; }
  .ori-ant-design-container .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #40a9ff;
    font-size: 48px; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px; }
  .ori-ant-design-container .ant-upload.ant-upload-drag p.ant-upload-hint {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .anticon-plus {
    color: rgba(0, 0, 0, 0.25);
    font-size: 30px;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-upload.ant-upload-drag .anticon-plus:hover {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 100%; }
  .ori-ant-design-container .ant-upload-picture-card-wrapper::before {
    display: table;
    content: ''; }
  .ori-ant-design-container .ant-upload-picture-card-wrapper::after {
    display: table;
    clear: both;
    content: ''; }
  .ori-ant-design-container .ant-upload-list {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    line-height: 1.5715; }
  .ori-ant-design-container .ant-upload-list::before {
    display: table;
    content: ''; }
  .ori-ant-design-container .ant-upload-list::after {
    display: table;
    clear: both;
    content: ''; }
  .ori-ant-design-container .ant-upload-list-item {
    position: relative;
    height: 22.001px;
    margin-top: 8px;
    font-size: 14px; }
  .ori-ant-design-container .ant-upload-list-item-name {
    display: inline-block;
    width: 100%;
    padding-left: 22px;
    overflow: hidden;
    line-height: 1.5715;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-upload-list-item-card-actions {
    position: absolute;
    right: 0; }
  .ori-ant-design-container .ant-upload-list-item-card-actions-btn {
    opacity: 0; }
  .ori-ant-design-container .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    height: 20px;
    line-height: 1; }
  .ori-ant-design-container .ant-upload-list-item-card-actions.picture {
    top: 22px;
    line-height: 0; }
  .ori-ant-design-container .ant-upload-list-item-card-actions-btn:focus,
  .ori-ant-design-container .ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-item-card-actions .anticon {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-upload-list-item-info {
    height: 100%;
    padding: 0 4px;
    transition: background-color 0.3s; }
  .ori-ant-design-container .ant-upload-list-item-info > span {
    display: block;
    width: 100%;
    height: 100%; }
  .ori-ant-design-container .ant-upload-list-item-info .anticon-loading .anticon,
  .ori-ant-design-container .ant-upload-list-item-info .ant-upload-text-icon .anticon {
    position: absolute;
    top: 5px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px; }
  .ori-ant-design-container .ant-upload-list-item .anticon-close {
    position: absolute;
    top: 6px;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    line-height: 0;
    cursor: pointer;
    opacity: 0;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-upload-list-item .anticon-close:hover {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #f5f5f5; }
  .ori-ant-design-container .ant-upload-list-item:hover .anticon-close {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-item-error,
  .ori-ant-design-container .ant-upload-list-item-error .ant-upload-text-icon > .anticon,
  .ori-ant-design-container .ant-upload-list-item-error .ant-upload-list-item-name {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-item-progress {
    position: absolute;
    bottom: -12px;
    width: 100%;
    padding-left: 26px;
    font-size: 14px;
    line-height: 0; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item {
    position: relative;
    height: 66px;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item:hover,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover {
    background: transparent; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-error,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #ff4d4f; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-info,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
    background: transparent; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-uploading,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading {
    border-style: dashed; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    line-height: 54px;
    text-align: center;
    opacity: 0.8; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    font-size: 26px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #fff2f0; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
    fill: #ff4d4f; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-icon,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 26px;
    transform: translate(-50%, -50%); }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-icon .anticon,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
    font-size: 26px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-image,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-image {
    max-width: 100%; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-thumbnail img,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 48px;
    height: 48px;
    overflow: hidden; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: inline-block;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0 0 0 8px;
    padding-right: 8px;
    padding-left: 48px;
    overflow: hidden;
    line-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
    line-height: 28px; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-progress,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 14px;
    width: calc(100% - 24px);
    margin-top: 0;
    padding-left: 56px; }
  .ori-ant-design-container .ant-upload-list-picture .anticon-close,
  .ori-ant-design-container .ant-upload-list-picture-card .anticon-close {
    position: absolute;
    top: 8px;
    right: 8px;
    line-height: 1;
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card-container {
    display: inline-block;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0;
    vertical-align: top; }
  .ori-ant-design-container .ant-upload-list-picture-card.ant-upload-list::after {
    display: none; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item {
    height: 100%;
    margin: 0; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info {
    position: relative;
    height: 100%;
    overflow: hidden; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: all 0.3s;
    content: ' '; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    white-space: nowrap;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    z-index: 10;
    width: 16px;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.85);
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
    color: #fff; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
    opacity: 1; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    position: static;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: none;
    margin: 8px 0 0;
    padding: 0;
    line-height: 1.5715;
    text-align: center; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    position: absolute;
    bottom: 10px;
    display: block; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
    height: auto; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
    display: none; }
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-progress {
    bottom: 32px;
    width: calc(100% - 14px);
    padding-left: 0; }
  .ori-ant-design-container .ant-upload-list-text-container,
  .ori-ant-design-container .ant-upload-list-picture-container {
    transition: opacity 0.3s, height 0.3s; }
  .ori-ant-design-container .ant-upload-list-text-container::before,
  .ori-ant-design-container .ant-upload-list-picture-container::before {
    display: table;
    width: 0;
    height: 0;
    content: ''; }
  .ori-ant-design-container .ant-upload-list-text-container .ant-upload-span,
  .ori-ant-design-container .ant-upload-list-picture-container .ant-upload-span {
    display: block;
    flex: auto; }
  .ori-ant-design-container .ant-upload-list-text .ant-upload-span,
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-span {
    display: flex;
    align-items: center; }
  .ori-ant-design-container .ant-upload-list-text .ant-upload-span > *,
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-span > * {
    flex: none; }
  .ori-ant-design-container .ant-upload-list-text .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-name {
    flex: auto;
    padding: 0 8px; }
  .ori-ant-design-container .ant-upload-list-text .ant-upload-list-item-card-actions,
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-card-actions {
    position: static; }
  .ori-ant-design-container .ant-upload-list-text .ant-upload-text-icon .anticon {
    position: static; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-appear,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-enter,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-leave {
    animation-duration: 0.3s;
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-appear,
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-enter {
    animation-name: uploadAnimateInlineIn; }
  .ori-ant-design-container .ant-upload-list .ant-upload-animate-inline-leave {
    animation-name: uploadAnimateInlineOut; }

@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }
  .ori-ant-design-container .ant-upload-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
    margin-right: auto;
    margin-left: 8px; }
  .ori-ant-design-container .ant-upload-list-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
    padding-right: 22px;
    padding-left: 14px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
    padding-right: 22px;
    padding-left: 28px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-name {
    padding-right: 22px;
    padding-left: 0; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
    padding-left: 14px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-card-actions {
    right: auto;
    left: 0; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-info {
    padding: 0 4px 0 12px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item .anticon-close {
    right: auto;
    left: 4px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
    padding-right: 0;
    padding-left: 5px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-item-progress {
    padding-right: 26px;
    padding-left: 0; }
  .ori-ant-design-container .ant-upload-list-picture .ant-upload-list-item-info,
  .ori-ant-design-container .ant-upload-list-picture-card .ant-upload-list-item-info {
    padding: 0; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    right: 8px;
    left: auto; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%); }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 0 8px 0 0;
    padding-right: 48px;
    padding-left: 8px; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
    padding-right: 48px;
    padding-left: 18px; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
    padding-right: 48px;
    padding-left: 36px; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-right: 0;
    padding-left: 0; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
    right: auto;
    left: 8px; }
  .ori-ant-design-container .ant-upload-list-rtl .ant-upload-list-picture-card-container {
    margin: 0 0 8px 8px; }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
    right: 50%;
    left: auto;
    transform: translate(50%, -50%); }
  .ori-ant-design-container .ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0; }
  .ori-ant-design-container .ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%; }
  .ori-ant-design-container .ant-avatar-image {
    background: transparent; }
  .ori-ant-design-container .ant-avatar .ant-image-img {
    display: block; }
  .ori-ant-design-container .ant-avatar-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center; }
  .ori-ant-design-container .ant-avatar.ant-avatar-icon {
    font-size: 18px; }
  .ori-ant-design-container .ant-avatar.ant-avatar-icon > .anticon {
    margin: 0; }
  .ori-ant-design-container .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%; }
  .ori-ant-design-container .ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center; }
  .ori-ant-design-container .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px; }
  .ori-ant-design-container .ant-avatar-lg.ant-avatar-icon > .anticon {
    margin: 0; }
  .ori-ant-design-container .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%; }
  .ori-ant-design-container .ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    transform-origin: 0 center; }
  .ori-ant-design-container .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px; }
  .ori-ant-design-container .ant-avatar-sm.ant-avatar-icon > .anticon {
    margin: 0; }
  .ori-ant-design-container .ant-avatar-square {
    border-radius: 2px; }
  .ori-ant-design-container .ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .ori-ant-design-container .ant-avatar-group {
    display: inline-flex; }
  .ori-ant-design-container .ant-avatar-group .ant-avatar {
    border: 1px solid #fff; }
  .ori-ant-design-container .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -8px; }
  .ori-ant-design-container .ant-avatar-group-popover .ant-avatar + .ant-avatar {
    margin-left: 3px; }
  .ori-ant-design-container .ant-avatar-group-rtl .ant-avatar:not(:first-child) {
    margin-right: -8px;
    margin-left: 0; }
  .ori-ant-design-container .ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
    margin-right: 3px;
    margin-left: 0; }
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item-active {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item:active {
    background: #fff1f0; }
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item-selected {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item-selected > a,
  .ori-ant-design-container .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #fff1f0; }
  .ori-ant-design-container .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
    border-right-color: #ff4d4f; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #ff4d4f; }
  .ori-ant-design-container .ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum', "tnum";
    margin-bottom: 0;
    padding-left: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s; }
  .ori-ant-design-container .ant-menu::before {
    display: table;
    content: ''; }
  .ori-ant-design-container .ant-menu::after {
    display: table;
    clear: both;
    content: ''; }
  .ori-ant-design-container .ant-menu.ant-menu-root:focus-visible {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-menu ul,
  .ori-ant-design-container .ant-menu ol {
    margin: 0;
    padding: 0;
    list-style: none; }
  .ori-ant-design-container .ant-menu-overflow {
    display: flex; }
  .ori-ant-design-container .ant-menu-overflow-item {
    flex: none; }
  .ori-ant-design-container .ant-menu-hidden,
  .ori-ant-design-container .ant-menu-submenu-hidden {
    display: none; }
  .ori-ant-design-container .ant-menu-item-group-title {
    height: 1.5715;
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5715;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item:active,
  .ori-ant-design-container .ant-menu-submenu-title:active {
    background: #e6f7ff; }
  .ori-ant-design-container .ant-menu-submenu .ant-menu-sub {
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-title-content {
    transition: color 0.3s; }
  .ori-ant-design-container .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-menu-item a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: ''; }
  .ori-ant-design-container .ant-menu-item > .ant-badge a {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-menu-item > .ant-badge a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0; }
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent; }
  .ori-ant-design-container .ant-menu-item-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-item-selected a,
  .ori-ant-design-container .ant-menu-item-selected a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff; }
  .ori-ant-design-container .ant-menu-inline,
  .ori-ant-design-container .ant-menu-vertical,
  .ori-ant-design-container .ant-menu-vertical-left {
    border-right: 1px solid #f0f0f0; }
  .ori-ant-design-container .ant-menu-vertical-right {
    border-left: 1px solid #f0f0f0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub:not([class*='-active']),
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
    overflow-x: hidden;
    overflow-y: auto; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    transform-origin: 0 0; }
  .ori-ant-design-container .ant-menu-horizontal.ant-menu-sub {
    min-width: 114px; }
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal .ant-menu-submenu-title {
    transition: border-color 0.3s, background 0.3s; }
  .ori-ant-design-container .ant-menu-item,
  .ori-ant-design-container .ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: border-color 0.3s, background 0.3s, padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-item .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-submenu-title .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu-submenu-title .anticon {
    min-width: 14px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s; }
  .ori-ant-design-container .ant-menu-item .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu-submenu-title .anticon + span {
    margin-left: 10px;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s, color 0.3s; }
  .ori-ant-design-container .ant-menu-item .ant-menu-item-icon.svg,
  .ori-ant-design-container .ant-menu-submenu-title .ant-menu-item-icon.svg {
    vertical-align: -0.125em; }
  .ori-ant-design-container .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ori-ant-design-container .ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
  .ori-ant-design-container .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
    margin-right: 0; }
  .ori-ant-design-container .ant-menu-item:focus-visible,
  .ori-ant-design-container .ant-menu-submenu-title:focus-visible {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .ori-ant-design-container .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    background-color: #f0f0f0; }
  .ori-ant-design-container .ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    border-radius: 2px;
    box-shadow: none;
    transform-origin: 0 0; }
  .ori-ant-design-container .ant-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.0001;
    content: ' '; }
  .ori-ant-design-container .ant-menu-submenu-placement-rightTop::before {
    top: 0;
    left: -7px; }
  .ori-ant-design-container .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 2px; }
  .ori-ant-design-container .ant-menu-submenu > .ant-menu-submenu-title::after {
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu-popup > .ant-menu {
    background-color: #fff; }
  .ori-ant-design-container .ant-menu-submenu-expand-icon,
  .ori-ant-design-container .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    color: rgba(0, 0, 0, 0.85);
    transform: translateY(-50%);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-submenu-arrow::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentColor;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: ''; }
  .ori-ant-design-container .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateY(-2.5px); }
  .ori-ant-design-container .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateY(2.5px); }
  .ori-ant-design-container .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  .ori-ant-design-container .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-inline-collapsed .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(2.5px); }
  .ori-ant-design-container .ant-menu-inline-collapsed .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-2.5px); }
  .ori-ant-design-container .ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
    display: none; }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    transform: translateY(-2px); }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateX(-2.5px); }
  .ori-ant-design-container .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateX(2.5px); }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal {
    line-height: 46px;
    border: 0;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: none; }
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px; }
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ori-ant-design-container .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu::after {
    position: absolute;
    right: 20px;
    bottom: 0;
    left: 20px;
    border-bottom: 2px solid transparent;
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: ''; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item a {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item a:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item a::before {
    bottom: -2px; }
  .ori-ant-design-container .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu-horizontal::after {
    display: block;
    clear: both;
    height: 0;
    content: '\20'; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item {
    position: relative; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: ''; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 0 16px;
    overflow: hidden;
    line-height: 40px;
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu {
    padding-bottom: 0.02px; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ori-ant-design-container .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 8px; }
  .ori-ant-design-container .ant-menu-vertical > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-left > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical-right > .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline > .ant-menu-item,
  .ori-ant-design-container .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px; }
  .ori-ant-design-container .ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 34px; }
  .ori-ant-design-container .ant-menu-inline {
    width: 100%; }
  .ori-ant-design-container .ant-menu-inline .ant-menu-selected::after,
  .ori-ant-design-container .ant-menu-inline .ant-menu-item-selected::after {
    transform: scaleY(1);
    opacity: 1;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .ori-ant-design-container .ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px); }
  .ori-ant-design-container .ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px; }
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-item,
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    display: flex;
    align-items: center;
    transition: border-color 0.3s, background 0.3s, padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
    flex: auto;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-item > *,
  .ori-ant-design-container .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > * {
    flex: none; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed {
    width: 80px; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 calc(50% - 16px / 2);
    text-overflow: clip; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 40px; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: inline-block;
    opacity: 0; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed .anticon {
    display: inline-block; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed-tooltip {
    pointer-events: none; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed-tooltip .anticon {
    display: none; }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85); }
  .ori-ant-design-container .ant-menu.ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
  .ori-ant-design-container .ant-menu-item-group-list {
    margin: 0;
    padding: 0; }
  .ori-ant-design-container .ant-menu-item-group-list .ant-menu-item,
  .ori-ant-design-container .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px; }
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical,
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical-left,
  .ori-ant-design-container .ant-menu-root.ant-menu-vertical-right,
  .ori-ant-design-container .ant-menu-root.ant-menu-inline {
    box-shadow: none; }
  .ori-ant-design-container .ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
  .ori-ant-design-container .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: #fafafa;
    border: 0;
    border-radius: 0;
    box-shadow: none; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 40px;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px; }
  .ori-ant-design-container .ant-menu-item-disabled,
  .ori-ant-design-container .ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background: none;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-menu-item-disabled::after,
  .ori-ant-design-container .ant-menu-submenu-disabled::after {
    border-color: transparent !important; }
  .ori-ant-design-container .ant-menu-item-disabled a,
  .ori-ant-design-container .ant-menu-submenu-disabled a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none; }
  .ori-ant-design-container .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(0, 0, 0, 0.25) !important; }
  .ori-ant-design-container .ant-layout-header .ant-menu {
    line-height: inherit; }
  .ori-ant-design-container .ant-menu-light .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-light .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-light .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-light .ant-menu-submenu-title:hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-menu.ant-menu-dark,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub,
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529; }
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #001529;
    border-bottom: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
    bottom: 0; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-group-title,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.65); }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    opacity: 1; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    background: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item:hover {
    background-color: transparent; }
  .ori-ant-design-container .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected::after {
    border-right: 0; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff; }
  .ori-ant-design-container .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ori-ant-design-container .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 0.8; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important; }
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.35) !important; }
  .ori-ant-design-container .ant-menu.ant-menu-rtl {
    direction: rtl;
    text-align: right; }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item-group-title {
    text-align: right; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical {
    border-right: none;
    border-left: 1px solid #f0f0f0; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
    border-left: none; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    transform-origin: top right; }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item .anticon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-title .anticon {
    margin-right: auto;
    margin-left: 10px; }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-left: 0; }
  .ori-ant-design-container .ant-menu-submenu-rtl.ant-menu-submenu-popup {
    transform-origin: 100% 0; }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: auto;
    left: 16px; }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateY(-2px); }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateY(2px); }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    text-align: right; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 0;
    padding-left: 34px; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 16px;
    padding-left: 34px; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 calc(50% - 16px / 2); }
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
  .ori-ant-design-container .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 28px 0 16px; }
  .ori-ant-design-container .ant-menu-sub.ant-menu-inline {
    border: 0; }
  .ori-ant-design-container .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-right: 32px;
    padding-left: 0; }
  .ori-ant-design-container .ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none; }
  .ori-ant-design-container .ant-message-notice {
    padding: 8px;
    text-align: center; }
  .ori-ant-design-container .ant-message-notice-content {
    display: inline-block;
    padding: 10px 16px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: all; }
  .ori-ant-design-container .ant-message-success .anticon {
    color: #52c41a; }
  .ori-ant-design-container .ant-message-error .anticon {
    color: #ff4d4f; }
  .ori-ant-design-container .ant-message-warning .anticon {
    color: #faad14; }
  .ori-ant-design-container .ant-message-info .anticon,
  .ori-ant-design-container .ant-message-loading .anticon {
    color: #1890ff; }
  .ori-ant-design-container .ant-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px; }
  .ori-ant-design-container .ant-message-notice.ant-move-up-leave.ant-move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s; }

@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1; }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0; } }
  .ori-ant-design-container .ant-message-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-message-rtl span {
    direction: rtl; }
  .ori-ant-design-container .ant-message-rtl .anticon {
    margin-right: 0;
    margin-left: 8px; }
  .ori-ant-design-container .ant-radio-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    font-size: 0; }
  .ori-ant-design-container .ant-radio-group .ant-badge-count {
    z-index: 1; }
  .ori-ant-design-container .ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
    border-left: none; }
  .ori-ant-design-container .ant-radio-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-flex;
    align-items: baseline;
    margin-right: 8px;
    cursor: pointer; }
  .ori-ant-design-container .ant-radio-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0'; }
  .ori-ant-design-container .ant-radio {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    top: 0.2em;
    display: inline-block;
    outline: none;
    cursor: pointer; }
  .ori-ant-design-container .ant-radio-wrapper:hover .ant-radio,
  .ori-ant-design-container .ant-radio:hover .ant-radio-inner,
  .ori-ant-design-container .ant-radio-input:focus + .ant-radio-inner {
    border-color: #1890ff; }
  .ori-ant-design-container .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }
  .ori-ant-design-container .ant-radio-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #1890ff;
    border-radius: 50%;
    visibility: hidden;
    animation: antRadioEffect 0.36s ease-in-out;
    animation-fill-mode: both;
    content: ''; }
  .ori-ant-design-container .ant-radio:hover::after,
  .ori-ant-design-container .ant-radio-wrapper:hover .ant-radio::after {
    visibility: visible; }
  .ori-ant-design-container .ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-radio-inner::after {
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    border-top: 0;
    border-left: 0;
    border-radius: 8px;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    content: ' '; }
  .ori-ant-design-container .ant-radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0; }
  .ori-ant-design-container .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff; }
  .ori-ant-design-container .ant-radio-checked .ant-radio-inner::after {
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .ori-ant-design-container .ant-radio-disabled {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio-disabled .ant-radio-inner {
    background-color: #f5f5f5;
    border-color: #d9d9d9 !important;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio-disabled .ant-radio-inner::after {
    background-color: rgba(0, 0, 0, 0.2); }
  .ori-ant-design-container .ant-radio-disabled .ant-radio-input {
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .ori-ant-design-container span.ant-radio + * {
    padding-right: 8px;
    padding-left: 8px; }
  .ori-ant-design-container .ant-radio-button-wrapper {
    position: relative;
    display: inline-block;
    height: 32px;
    margin: 0;
    padding: 0 15px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 30px;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-top-width: 1.02px;
    border-left-width: 0;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s; }
  .ori-ant-design-container .ant-radio-button-wrapper a {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-radio-button-wrapper > .ant-radio-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%; }
  .ori-ant-design-container .ant-radio-group-large .ant-radio-button-wrapper {
    height: 40px;
    font-size: 16px;
    line-height: 38px; }
  .ori-ant-design-container .ant-radio-group-small .ant-radio-button-wrapper {
    height: 24px;
    padding: 0 7px;
    line-height: 22px; }
  .ori-ant-design-container .ant-radio-button-wrapper:not(:first-child)::before {
    position: absolute;
    top: -1px;
    left: -1px;
    display: block;
    box-sizing: content-box;
    width: 1px;
    height: 100%;
    padding: 1px 0;
    background-color: #d9d9d9;
    transition: background-color 0.3s;
    content: ''; }
  .ori-ant-design-container .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-radio-button-wrapper:last-child {
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 2px; }
  .ori-ant-design-container .ant-radio-button-wrapper:hover {
    position: relative;
    color: #1890ff; }
  .ori-ant-design-container .ant-radio-button-wrapper:focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }
  .ori-ant-design-container .ant-radio-button-wrapper .ant-radio-inner,
  .ori-ant-design-container .ant-radio-button-wrapper input[type='checkbox'],
  .ori-ant-design-container .ant-radio-button-wrapper input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #1890ff;
    background: #fff;
    border-color: #1890ff; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #1890ff; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #40a9ff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: #40a9ff; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #096dd9;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
    background-color: #096dd9; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }
  .ori-ant-design-container .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff; }
  .ori-ant-design-container .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #40a9ff;
    border-color: #40a9ff; }
  .ori-ant-design-container .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    color: #fff;
    background: #096dd9;
    border-color: #096dd9; }
  .ori-ant-design-container .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }
  .ori-ant-design-container .ant-radio-button-wrapper-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    cursor: not-allowed; }
  .ori-ant-design-container .ant-radio-button-wrapper-disabled:first-child,
  .ori-ant-design-container .ant-radio-button-wrapper-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .ori-ant-design-container .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9; }
  .ori-ant-design-container .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: rgba(0, 0, 0, 0.25);
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none; }

@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5; }
  100% {
    transform: scale(1.6);
    opacity: 0; } }
  .ori-ant-design-container .ant-radio-group.ant-radio-group-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-radio-wrapper.ant-radio-wrapper-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl; }
  .ori-ant-design-container .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
    border-right-width: 0;
    border-left-width: 1px; }
  .ori-ant-design-container .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
    right: -1px;
    left: 0; }
  .ori-ant-design-container .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
    border-right: 1px solid #d9d9d9;
    border-radius: 0 2px 2px 0; }
  .ori-ant-design-container .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #40a9ff; }
  .ori-ant-design-container .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
    border-radius: 2px 0 0 2px; }
  .ori-ant-design-container .ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
    border-right-color: #d9d9d9; }
  .ori-ant-design-container .ant-rate {
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    margin: 0;
    padding: 0;
    color: #fadb14;
    font-size: 20px;
    line-height: unset;
    list-style: none;
    outline: none; }
  .ori-ant-design-container .ant-rate-disabled .ant-rate-star {
    cursor: default; }
  .ori-ant-design-container .ant-rate-disabled .ant-rate-star:hover {
    transform: scale(1); }
  .ori-ant-design-container .ant-rate-star {
    position: relative;
    display: inline-block;
    color: inherit;
    cursor: pointer; }
  .ori-ant-design-container .ant-rate-star:not(:last-child) {
    margin-right: 8px; }
  .ori-ant-design-container .ant-rate-star > div {
    transition: all 0.3s, outline 0s; }
  .ori-ant-design-container .ant-rate-star > div:hover,
  .ori-ant-design-container .ant-rate-star > div:focus-visible {
    transform: scale(1.1); }
  .ori-ant-design-container .ant-rate-star > div:focus {
    outline: 0; }
  .ori-ant-design-container .ant-rate-star > div:focus-visible {
    outline: 1px dashed #fadb14; }
  .ori-ant-design-container .ant-rate-star-first,
  .ori-ant-design-container .ant-rate-star-second {
    color: #f0f0f0;
    transition: all 0.3s;
    -webkit-user-select: none;
    user-select: none; }
  .ori-ant-design-container .ant-rate-star-first .anticon,
  .ori-ant-design-container .ant-rate-star-second .anticon {
    vertical-align: middle; }
  .ori-ant-design-container .ant-rate-star-first {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0; }
  .ori-ant-design-container .ant-rate-star-half .ant-rate-star-first,
  .ori-ant-design-container .ant-rate-star-half .ant-rate-star-second {
    opacity: 1; }
  .ori-ant-design-container .ant-rate-star-half .ant-rate-star-first,
  .ori-ant-design-container .ant-rate-star-full .ant-rate-star-second {
    color: inherit; }
  .ori-ant-design-container .ant-rate-text {
    display: inline-block;
    margin: 0 8px;
    font-size: 14px; }
  .ori-ant-design-container .ant-rate-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-rate-rtl .ant-rate-star:not(:last-child) {
    margin-right: 0;
    margin-left: 8px; }
  .ori-ant-design-container .ant-rate-rtl .ant-rate-star-first {
    right: 0;
    left: auto; }
  .ori-ant-design-container .ant-tag {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-tag,
  .ori-ant-design-container .ant-tag a,
  .ori-ant-design-container .ant-tag a:hover {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-tag > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px; }
  .ori-ant-design-container .ant-tag-close-icon {
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    cursor: pointer;
    transition: all 0.3s; }
  .ori-ant-design-container .ant-tag-close-icon:hover {
    color: rgba(0, 0, 0, 0.85); }
  .ori-ant-design-container .ant-tag-has-color {
    border-color: transparent; }
  .ori-ant-design-container .ant-tag-has-color,
  .ori-ant-design-container .ant-tag-has-color a,
  .ori-ant-design-container .ant-tag-has-color a:hover,
  .ori-ant-design-container .ant-tag-has-color .anticon-close,
  .ori-ant-design-container .ant-tag-has-color .anticon-close:hover {
    color: #fff; }
  .ori-ant-design-container .ant-tag-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer; }
  .ori-ant-design-container .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #1890ff; }
  .ori-ant-design-container .ant-tag-checkable:active,
  .ori-ant-design-container .ant-tag-checkable-checked {
    color: #fff; }
  .ori-ant-design-container .ant-tag-checkable-checked {
    background-color: #1890ff; }
  .ori-ant-design-container .ant-tag-checkable:active {
    background-color: #096dd9; }
  .ori-ant-design-container .ant-tag-hidden {
    display: none; }
  .ori-ant-design-container .ant-tag-pink {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2; }
  .ori-ant-design-container .ant-tag-pink-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96; }
  .ori-ant-design-container .ant-tag-magenta {
    color: #c41d7f;
    background: #fff0f6;
    border-color: #ffadd2; }
  .ori-ant-design-container .ant-tag-magenta-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96; }
  .ori-ant-design-container .ant-tag-red {
    color: #cf1322;
    background: #fff1f0;
    border-color: #ffa39e; }
  .ori-ant-design-container .ant-tag-red-inverse {
    color: #fff;
    background: #f5222d;
    border-color: #f5222d; }
  .ori-ant-design-container .ant-tag-volcano {
    color: #d4380d;
    background: #fff2e8;
    border-color: #ffbb96; }
  .ori-ant-design-container .ant-tag-volcano-inverse {
    color: #fff;
    background: #fa541c;
    border-color: #fa541c; }
  .ori-ant-design-container .ant-tag-orange {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591; }
  .ori-ant-design-container .ant-tag-orange-inverse {
    color: #fff;
    background: #fa8c16;
    border-color: #fa8c16; }
  .ori-ant-design-container .ant-tag-yellow {
    color: #d4b106;
    background: #feffe6;
    border-color: #fffb8f; }
  .ori-ant-design-container .ant-tag-yellow-inverse {
    color: #fff;
    background: #fadb14;
    border-color: #fadb14; }
  .ori-ant-design-container .ant-tag-gold {
    color: #d48806;
    background: #fffbe6;
    border-color: #ffe58f; }
  .ori-ant-design-container .ant-tag-gold-inverse {
    color: #fff;
    background: #faad14;
    border-color: #faad14; }
  .ori-ant-design-container .ant-tag-cyan {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de; }
  .ori-ant-design-container .ant-tag-cyan-inverse {
    color: #fff;
    background: #13c2c2;
    border-color: #13c2c2; }
  .ori-ant-design-container .ant-tag-lime {
    color: #7cb305;
    background: #fcffe6;
    border-color: #eaff8f; }
  .ori-ant-design-container .ant-tag-lime-inverse {
    color: #fff;
    background: #a0d911;
    border-color: #a0d911; }
  .ori-ant-design-container .ant-tag-green {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f; }
  .ori-ant-design-container .ant-tag-green-inverse {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a; }
  .ori-ant-design-container .ant-tag-blue {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff; }
  .ori-ant-design-container .ant-tag-blue-inverse {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff; }
  .ori-ant-design-container .ant-tag-geekblue {
    color: #1d39c4;
    background: #f0f5ff;
    border-color: #adc6ff; }
  .ori-ant-design-container .ant-tag-geekblue-inverse {
    color: #fff;
    background: #2f54eb;
    border-color: #2f54eb; }
  .ori-ant-design-container .ant-tag-purple {
    color: #531dab;
    background: #f9f0ff;
    border-color: #d3adf7; }
  .ori-ant-design-container .ant-tag-purple-inverse {
    color: #fff;
    background: #722ed1;
    border-color: #722ed1; }
  .ori-ant-design-container .ant-tag-success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f; }
  .ori-ant-design-container .ant-tag-processing {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff; }
  .ori-ant-design-container .ant-tag-error {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e; }
  .ori-ant-design-container .ant-tag-warning {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591; }
  .ori-ant-design-container .ant-tag > .anticon + span,
  .ori-ant-design-container .ant-tag > span + .anticon {
    margin-left: 7px; }
  .ori-ant-design-container .ant-tag.ant-tag-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
    text-align: right; }
  .ori-ant-design-container .ant-tag-rtl .ant-tag-close-icon {
    margin-right: 3px;
    margin-left: 0; }
  .ori-ant-design-container .ant-tag-rtl.ant-tag > .anticon + span,
  .ori-ant-design-container .ant-tag-rtl.ant-tag > span + .anticon {
    margin-right: 7px;
    margin-left: 0; }
  .ori-ant-design-container .ant-spin {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    display: none;
    color: #1890ff;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .ori-ant-design-container .ant-spin-spinning {
    position: static;
    display: inline-block;
    opacity: 1; }
  .ori-ant-design-container .ant-spin-nested-loading {
    position: relative; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 400px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #fff; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
    margin: -7px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
    padding-top: 2px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
    margin-top: -17px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    padding-top: 11px; }
  .ori-ant-design-container .ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
    margin-top: -26px; }
  .ori-ant-design-container .ant-spin-container {
    position: relative;
    transition: opacity 0.3s; }
  .ori-ant-design-container .ant-spin-container::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: none \9;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0;
    transition: all 0.3s;
    content: '';
    pointer-events: none; }
  .ori-ant-design-container .ant-spin-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.5;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none; }
  .ori-ant-design-container .ant-spin-blur::after {
    opacity: 0.4;
    pointer-events: auto; }
  .ori-ant-design-container .ant-spin-tip {
    color: rgba(0, 0, 0, 0.45); }
  .ori-ant-design-container .ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 1em;
    height: 1em; }
  .ori-ant-design-container .ant-spin-dot-item {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: #1890ff;
    border-radius: 100%;
    transform: scale(0.75);
    transform-origin: 50% 50%;
    opacity: 0.3;
    animation: antSpinMove 1s infinite linear alternate; }
  .ori-ant-design-container .ant-spin-dot-item:nth-child(1) {
    top: 0;
    left: 0; }
  .ori-ant-design-container .ant-spin-dot-item:nth-child(2) {
    top: 0;
    right: 0;
    animation-delay: 0.4s; }
  .ori-ant-design-container .ant-spin-dot-item:nth-child(3) {
    right: 0;
    bottom: 0;
    animation-delay: 0.8s; }
  .ori-ant-design-container .ant-spin-dot-item:nth-child(4) {
    bottom: 0;
    left: 0;
    animation-delay: 1.2s; }
  .ori-ant-design-container .ant-spin-dot-spin {
    transform: rotate(45deg);
    animation: antRotate 1.2s infinite linear; }
  .ori-ant-design-container .ant-spin-sm .ant-spin-dot {
    font-size: 14px; }
  .ori-ant-design-container .ant-spin-sm .ant-spin-dot i {
    width: 6px;
    height: 6px; }
  .ori-ant-design-container .ant-spin-lg .ant-spin-dot {
    font-size: 32px; }
  .ori-ant-design-container .ant-spin-lg .ant-spin-dot i {
    width: 14px;
    height: 14px; }
  .ori-ant-design-container .ant-spin.ant-spin-show-text .ant-spin-text {
    display: block; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .ori-ant-design-container {
      /* IE10+ */ }
      .ori-ant-design-container .ant-spin-blur {
        background: #fff;
        opacity: 0.5; } }

@keyframes antSpinMove {
  to {
    opacity: 1; } }

@keyframes antRotate {
  to {
    transform: rotate(405deg); } }
  .ori-ant-design-container .ant-spin-rtl {
    direction: rtl; }
  .ori-ant-design-container .ant-spin-rtl .ant-spin-dot-spin {
    transform: rotate(-45deg);
    animation-name: antRotateRtl; }

@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg); } }
  .ori-ant-design-container .anticon-info-circle:before,
  .ori-ant-design-container .anticon-check-circle:before,
  .ori-ant-design-container .anticon-exclamation-circle:before,
  .ori-ant-design-container .anticon-close-circle:before,
  .ori-ant-design-container .anticon-right:before,
  .ori-ant-design-container .anticon-left:before,
  .ori-ant-design-container .anticon-calendar:before,
  .ori-ant-design-container .anticon-down:before,
  .ori-ant-design-container .anticon-loading:before,
  .ori-ant-design-container .anticon-close:before,
  .ori-ant-design-container .anticon-star:before,
  .ori-ant-design-container .anticon-search:before,
  .ori-ant-design-container .anticon-check:before,
  .ori-ant-design-container .anticon-swap-right:before {
    content: "" !important; }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.ori-ant-design-container ::selection {
  background: #FCC600 !important; }

.ant-message {
  position: absolute !important;
  z-index: 999999 !important; }

.ant-select-item {
  min-height: auto !important; }

.ant-alert {
  padding: 8px 12px;
  line-height: 1.5;
  list-style: none;
  position: relative;
  display: flex;
  align-items: center;
  word-wrap: break-word; }

.ant-alert-info {
  background-color: #e6f4ff; }

.ant-alert-error {
  background-color: #fff2f0; }

.ant-alert-warning {
  background-color: #fffbe6; }

.ant-alert-success {
  background-color: #d9e3d5; }

.ant-alert-icon {
  margin-inline-end: 8px;
  line-height: 0;
  color: #f5a623; }

.ant-alert-success .ant-alert-icon {
  color: #90b900; }

.ant-alert-error .ant-alert-icon {
  color: #ff6d4a; }

.ant-alert-info .ant-alert-icon {
  color: #4da1ff; }

.ant-alert-content {
  width: 100%; }

/*
-- if you are not using FormMessage component then you should below css
-- we are using radio group and datePicker in FormMessage Component
*/
.ant-picker-dropdown,
.ant-select-dropdown {
  z-index: 99994 !important;
  font-family: "Roboto", sans-serif !important; }

.ant-tooltip {
  z-index: 99999;
  font-family: "Roboto", sans-serif; }

.ori-tag-primary {
  color: #FCC600 !important;
  background-color: #fff9e6 !important;
  border: 1px solid transparent !important;
  cursor: default !important;
  height: auto !important;
  line-height: unset !important; }

.ori-btn-fill-primary,
.ori-btn-bubble-inner,
.ori-btn-ghost-primary,
.ori-btn-edit,
.ori-btn-submit {
  margin: 2px !important; }

.ori-btn-bubble-outer,
.ori-btn-submit,
.ori-btn-clear {
  color: #ffffff !important;
  background-color: #FCC600 !important;
  border-color: #FCC600 !important; }
  .ori-btn-bubble-outer:hover, .ori-btn-bubble-outer:focus, .ori-btn-bubble-outer:active,
  .ori-btn-submit:hover,
  .ori-btn-submit:focus,
  .ori-btn-submit:active,
  .ori-btn-clear:hover,
  .ori-btn-clear:focus,
  .ori-btn-clear:active {
    background-color: #fdd74d !important;
    border-color: #fdd74d !important; }

.ori-btn-bubble-inner {
  text-align: left !important; }

.ori-btn-fill-primary,
.ori-btn-bubble-inner,
.ori-btn-quick-reply-icon,
.ori-image-preview-btn {
  color: #2f3043 !important;
  background-color: #FCC600 !important;
  border-color: #FCC600 !important;
  border-radius: 15px !important;
  padding: 3px 12px 3px 12px !important;
  height: auto !important; }
  .ori-btn-fill-primary:hover, .ori-btn-fill-primary:focus, .ori-btn-fill-primary:active, .ori-btn-fill-primary:disabled,
  .ori-btn-bubble-inner:hover,
  .ori-btn-bubble-inner:focus,
  .ori-btn-bubble-inner:active,
  .ori-btn-bubble-inner:disabled,
  .ori-btn-quick-reply-icon:hover,
  .ori-btn-quick-reply-icon:focus,
  .ori-btn-quick-reply-icon:active,
  .ori-btn-quick-reply-icon:disabled,
  .ori-image-preview-btn:hover,
  .ori-image-preview-btn:focus,
  .ori-image-preview-btn:active,
  .ori-image-preview-btn:disabled {
    background-color: #fddd66 !important;
    border-color: #fddd66 !important; }

.ori-image-preview-icon {
  color: #000000 !important;
  background-color: #f7f7f9 !important;
  border-color: #f7f7f9 !important; }
  .ori-image-preview-icon:hover, .ori-image-preview-icon:focus, .ori-image-preview-icon:active, .ori-image-preview-icon:disabled {
    background-color: #acacb4 !important;
    border-color: #acacb4 !important; }

.ori-btn-ghost-primary,
.ori-btn-quick-reply {
  color: #FCC600 !important;
  border: 1px solid #FCC600 !important;
  height: auto !important;
  line-height: unset !important;
  border-radius: 15px !important; }
  .ori-btn-ghost-primary:hover, .ori-btn-ghost-primary:focus, .ori-btn-ghost-primary:active,
  .ori-btn-quick-reply:hover,
  .ori-btn-quick-reply:focus,
  .ori-btn-quick-reply:active {
    box-shadow: 0 0 6px 0 #FCC600; }
  .ori-btn-ghost-primary:disabled,
  .ori-btn-quick-reply:disabled {
    border-color: #fddd66 !important;
    color: #fdd74d !important; }

.ori-btn-carousel-item:hover, .ori-btn-carousel-item:focus, .ori-btn-carousel-item:active {
  color: #FCC600 !important;
  border: 1px solid #FCC600 !important; }

.ori-btn-carousel-item,
.ori-btn-carousel-item-selected,
.ori-carousel-btn-submit {
  width: 100%;
  margin: 5px 0px !important; }

.ori-btn-paragraph {
  margin-bottom: 5px !important;
  font-size: 13px !important;
  text-align: left !important; }
  .ori-btn-paragraph:hover, .ori-btn-paragraph:focus, .ori-btn-paragraph:active {
    color: #2f3043 !important;
    border-color: #e6e6e6 !important;
    background-color: #f7f7f9 !important; }

.ori-btn-secondary,
.ori-btn-carousel-item-selected,
.ori-carousel-btn-submit {
  color: #ffffff !important;
  background-color: #22a233 !important;
  border-color: #22a233 !important;
  border-radius: 15px !important;
  padding: 3px 12px 3px 12px !important;
  height: auto !important; }
  .ori-btn-secondary:hover, .ori-btn-secondary:focus, .ori-btn-secondary:active, .ori-btn-secondary:disabled,
  .ori-btn-carousel-item-selected:hover,
  .ori-btn-carousel-item-selected:focus,
  .ori-btn-carousel-item-selected:active,
  .ori-btn-carousel-item-selected:disabled,
  .ori-carousel-btn-submit:hover,
  .ori-carousel-btn-submit:focus,
  .ori-carousel-btn-submit:active,
  .ori-carousel-btn-submit:disabled {
    background-color: #64be70 !important;
    border-color: #64be70 !important; }

.ori-mt-CarouselContainer .slick-next::before {
  color: #666 !important;
  font-size: 14px;
  content: ">>" !important; }

.ori-mt-CarouselContainer .slick-prev::before {
  color: #666 !important;
  font-size: 14px;
  content: "<<" !important; }

.ori-mt-CarouselContainer .slick-slide {
  margin: 0 !important; }

.ori-mt-CarouselContainer .slick-arrow {
  background: none !important;
  font-size: 12px !important;
  color: #666 !important;
  top: inherit !important;
  bottom: -28px !important;
  z-index: 1 !important; }

.ori-mt-CarouselContainer .slick-next {
  right: 10px !important;
  color: transparent !important;
  background-color: transparent !important;
  outline: none; }
  .ori-mt-CarouselContainer .slick-next::before {
    content: ">" !important;
    color: #666 !important;
    font-size: 16px;
    background: none !important; }

.ori-mt-CarouselContainer .slick-prev {
  left: 10px !important;
  color: transparent !important;
  background-color: transparent !important;
  outline: none; }
  .ori-mt-CarouselContainer .slick-prev::before {
    content: "<" !important;
    color: #666 !important;
    font-size: 16px;
    background: none !important; }

.ori-mt-CarouselContainer .slick-dots {
  bottom: -20px !important;
  overflow-x: hidden; }
  .ori-mt-CarouselContainer .slick-dots li button,
  .ori-mt-CarouselContainer .slick-dots .slick-active button {
    background-color: #666 !important; }
  .ori-mt-CarouselContainer .slick-dots li::before,
  .ori-mt-CarouselContainer .slick-dots .slick-active::before {
    content: none !important; }

.ori-mt-CarouselContainer .ori-carousel-img-container {
  max-height: 250px;
  overflow-y: auto; }

.ori-mt-fileUploaderContainer .ant-upload {
  width: 100%; }

.ori-seat-selected {
  background-color: #22a233; }

.ori-seat-default {
  background-color: #acacb4; }

.textAreaField::placeholder {
  text-transform: none !important;
  font-size: 16px !important; }

.ori-app-container { }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.oriAppContainer {
  z-index: 99990;
  position: fixed;
  right: 0;
  bottom: 0; }
  .oriAppContainer .chatbotContainer {
    color: #2f3043 !important; }
  .oriAppContainer .notificationBadge {
    top: -18px;
    right: 24px; }
    .oriAppContainer .notificationBadge .ant-badge-count {
      z-index: 99993;
      background-color: #22a233;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.11); }

.scroll-visibility {
  --scroll-display: none; }
  .scroll-visibility ::-webkit-scrollbar {
    opacity: 0;
    width: 6px;
    height: 6px; }
  .scroll-visibility ::-webkit-scrollbar-track {
    opacity: 0; }
  .scroll-visibility ::-webkit-scrollbar-thumb {
    display: var(--scroll-display);
    background-color: #8f8f8f;
    border-radius: 6px; }
  .scroll-visibility ::-webkit-scrollbar-button {
    display: none; }

.prevent-inheritance > * {
  --scroll-display: none; }

/*
animation mixin: 
eg: @include keyframes(slide-down) { 0% { opacity: 1; } 90% { opacity: 0; } } 
@include animation('slide-down 5s 3');
*/
.oriAppContainerRTL .headerContainer {
  padding: 15px 15px 15px 45px !important; }

.oriAppContainerRTL .oriHeaderTagContainer {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 10px; }

.oriAppContainerRTL .oriHeaderActionContainer {
  top: 22px;
  left: 10px;
  right: unset !important; }

.oriAppContainerRTL .senderBubble,
.oriAppContainerRTL .notificationSenderBubble {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 10px !important; }

.oriAppContainerRTL .receiverBubble,
.oriAppContainerRTL .notificationReceiverBubble {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 10px !important; }

.oriAppContainerRTL .receiverAvatar {
  top: 5px !important;
  right: -40px !important;
  left: unset; }

.oriAppContainerRTL .senderAvatar {
  top: 5px !important;
  left: -40px !important;
  right: unset; }

.oriAppContainerRTL .footerContainer {
  padding-left: 65px !important; }

.oriAppContainerRTL .menuVisible {
  padding-right: 30px; }

.oriAppContainerRTL .menuHidden {
  padding-right: 10px; }

.oriAppContainerRTL .oriMenuContainer {
  right: 52px;
  left: unset; }

.oriAppContainerRTL .chatbotInputComposer .alignRightIcons {
  left: -60px;
  right: unset; }
  .oriAppContainerRTL .chatbotInputComposer .alignRightIcons .sendIcon {
    transform: rotate(180deg); }

.oriAppContainerRTL .chatbotInputComposer .alignMenuIcon {
  right: -24px !important;
  left: unset; }

.oriAppContainerRTL .notificationBotInputComposer {
  padding: 12px 25px 11px 35px !important; }
  .oriAppContainerRTL .notificationBotInputComposer .alignRightIcons {
    right: unset;
    left: 5px; }

.oriAppContainerRTL .oriInfoContentCloseIcon {
  left: 7px;
  right: unset; }

.oriFullScreenAppContainer {
  top: 0;
  left: 0;
  display: flex;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .oriFullScreenAppContainer .containerMobile {
    width: 100%;
    height: 100%; }
  .oriFullScreenAppContainer .containerWeb {
    height: calc(100% - 100px);
    width: 600px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin: auto; }

.oriAppContainer {
  /*
  * comment containerMobile and containerWeb in case FULLSCREEN chatbot
  */ }
  .oriAppContainer .containerMobile {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .oriAppContainer .containerWeb {
    right: 20px;
    bottom: 110px;
    height: calc(100% - 130px);
    max-height: 600px;
    width: 375px;
    box-shadow: 0 5px 40px 0 rgba(0, 0, 0, 0.15);
    border-radius: 25px; }
  .oriAppContainer .stackViewCloseIcon {
    display: flex;
    cursor: pointer;
    color: #ffffff;
    background-color: #8a8989;
    top: 22px;
    right: 10px;
    border-radius: 50%;
    padding: 2px; }
    .oriAppContainer .stackViewCloseIcon:hover {
      background-color: #4d4d4d; }
  .oriAppContainer .closeTrigger {
    color: #ffffff;
    box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.24);
    right: 20px;
    bottom: 20px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #FCC600; }
  .oriAppContainer .defaultTrigger {
    right: 20px;
    bottom: 20px;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    height: 70px; }
  .oriAppContainer .lottieTrigger {
    right: 20px;
    bottom: 20px;
    height: 55px;
    width: 91px; }
  .oriAppContainer .floatingTrigger {
    right: 20px;
    bottom: 20px;
    height: 70px; }
  .oriAppContainer .floatingTriggerMenuClose {
    color: #ffffff;
    right: 20px;
    bottom: 20px;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #FCC600; }
  .oriAppContainer .floatingList {
    bottom: 100px;
    right: 30px; }
    .oriAppContainer .floatingList .floatingItem {
      padding: 3px;
      margin-top: 5px;
      border: 1px solid transparent; }
      .oriAppContainer .floatingList .floatingItem:hover {
        border-radius: 30px;
        border: 1px solid #FCC600; }
        .oriAppContainer .floatingList .floatingItem:hover .floatingItemTitle {
          display: block; }
      .oriAppContainer .floatingList .floatingItem .floatingItemTitle {
        display: none;
        font-size: 16px;
        padding: 10px;
        align-self: center; }
      .oriAppContainer .floatingList .floatingItem .floatingItemIcon {
        height: 50px;
        width: 50px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FCC600;
        border-radius: 50%;
        color: #ffffff; }
  .oriAppContainer .floatingCircularItem {
    border: 1px solid transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 3px; }
    .oriAppContainer .floatingCircularItem:hover {
      border-radius: 30px;
      border: 1px solid #FCC600;
      z-index: 1;
      background-color: #fff; }
      .oriAppContainer .floatingCircularItem:hover .floatingCircularItemTitle {
        display: block;
        font-size: 16px;
        align-self: center;
        color: #FCC600;
        white-space: nowrap;
        padding: 5px; }
    .oriAppContainer .floatingCircularItem .floatingCircularItemTitle {
      display: none; }
    .oriAppContainer .floatingCircularItem .floatingCircularItemIcon {
      height: 50px;
      width: 50px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FCC600;
      border-radius: 50%;
      color: #ffffff; }
  .oriAppContainer .floatingCircularItem:nth-child(1) {
    right: 5px;
    bottom: 80px; }
  .oriAppContainer .floatingCircularItem:nth-child(2) {
    right: 64px;
    bottom: 52px; }
  .oriAppContainer .floatingCircularItem:nth-child(3) {
    right: 79px;
    bottom: -12px; }
  .oriAppContainer .floatingCircularItem:nth-child(4) {
    right: 38px;
    bottom: -62px; }
  .oriAppContainer .agentPseudoName {
    margin-right: 5px;
    bottom: 100%;
    left: -40px;
    width: 40px; }
  .oriAppContainer .stackViewNotificationBubble {
    display: block;
    margin-left: auto;
    width: -moz-fit-content;
    width: fit-content;
    padding: 10px; }
  .oriAppContainer .senderBubble {
    border-bottom-left-radius: 0; }
  .oriAppContainer .receiverBubble {
    border-bottom-left-radius: 0; }
  .oriAppContainer .notificationSenderBubble {
    border-top-right-radius: 0; }
  .oriAppContainer .notificationReceiverBubble {
    border-bottom-left-radius: 0; }
  .oriAppContainer .receiverAvatar {
    top: 5px;
    left: -40px; }
  .oriAppContainer .senderAvatar {
    top: 5px;
    right: -40px; }
  .oriAppContainer .quickReplyWrapper {
    left: 0;
    right: 0; }
  .oriAppContainer .headerIcon {
    color: #acacb4; }
    .oriAppContainer .headerIcon:hover {
      color: #2f3043; }
  .oriAppContainer .clearChatIcon {
    color: #acacb4; }
    .oriAppContainer .clearChatIcon:hover {
      color: #2f3043; }
  .oriAppContainer .endChatIcon {
    color: #ffffff;
    background-color: #ea3a52;
    border-radius: 15px;
    padding: 5px; }
    .oriAppContainer .endChatIcon:hover {
      border-color: #2f3043; }
  .oriAppContainer .headerContainer {
    height: 70px;
    padding: 15px 45px 15px 20px;
    border-radius: 25px; }
  .oriAppContainer .footerContainer {
    padding-right: 65px;
    padding-top: 10px;
    padding-bottom: 13px;
    background-color: #ffffff; }
  .oriAppContainer .disclaimerText {
    padding-top: 6px;
    padding-bottom: 20px;
    background-color: #ffffff;
    padding-left: 14px;
    padding-right: 10px;
    border-top: 1px solid #ebebeb;
    line-height: 12px;
    font-size: 9px; }
  .oriAppContainer .oriAlignEndChatBtn {
    top: 22px;
    right: 10px; }
  .oriAppContainer .oriInfoCloseIconAlign {
    top: 7px;
    right: 7px; }
  .oriAppContainer .oriHeaderTagContainer {
    border-bottom-right-radius: 10px; }
  .oriAppContainer .oriHeaderActionContainer {
    top: 22px;
    right: 10px; }
  .oriAppContainer .oriInfoContentCloseIcon {
    top: 7px;
    right: 7px; }
  .oriAppContainer .ori-selected-seat {
    background-color: #22a233 !important; }
  .oriAppContainer .ori-upload-preview {
    width: 65% !important; }
  @media only screen and (max-width: 481px) {
    .oriAppContainer .ori-upload-preview {
      width: 100% !important; } }
  .oriAppContainer .ori-sender-reply-container {
    background-color: rgba(11, 20, 26, 0.2);
    border-bottom: 2px solid #fdd74d; }
  .oriAppContainer .ori-server-reply-container {
    background-color: rgba(11, 20, 26, 0.04);
    border-bottom: 2px solid #fff9e6; }
  .oriAppContainer .ori-selected-emoji-rating {
    opacity: 1 !important; }
  .oriAppContainer .ori-feedback-lowest-font {
    color: #ff6d4a; }
  .oriAppContainer .ori-feedback-middle-font {
    color: #acacb4; }
  .oriAppContainer .ori-feedback-highest-font {
    color: #22a233; }
  .oriAppContainer .ori-end-chat-feedback-rating {
    color: #f5a623; }
  .oriAppContainer .endChatheader {
    align-items: center;
    flex-direction: row;
    padding-top: 20px; }
  .oriAppContainer .ori-image-brand-name {
    height: 30px;
    display: block;
    margin: auto; }
  .oriAppContainer .ori-end-chat-brand-name {
    font-size: 16px; }
  .oriAppContainer .ori-end-chat-feedback-form {
    margin: 80px 15px 30px 15px; }
  .oriAppContainer .ori-form-title {
    font-weight: 700;
    font-size: 14px; }
  .oriAppContainer .ori-form-subtitle {
    font-size: 10px; }
  .oriAppContainer .ori-end-chat-display {
    justify-content: space-around;
    margin-left: 10px; }

